
import { useEffect, useState, useRef } from 'react'
import TextField from '@mui/material/TextField';
import { useLoaderData } from "react-router-dom";
import '../styles/utils.css'
import UploadIcon from '@mui/icons-material/Upload';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import main, { get_file_size, check_phone_number } from '../resources/code/utils'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import workshopPlaceholder from '../resources/systemIcons/workshop_placeholder.png'




export function loader({params}){
    console.log(params)

    return fetch(main.server + `/view_workshop_info?workshop=${params.id}`).then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  


export default function ModifyWorkshop(){
    
    const [data, ] = useState(useLoaderData())
    const [start, setStart] = useState(false)

    const [workshop_name, setWorkShopName] = useState('')

    const [ filled, setFilled] = useState(false)

    const [phone_number1, setPhoneNumber1] = useState('')
    const [phone_number2, setPhoneNumber2] = useState('')
    const [description, setDescription ] = useState('')

    const [geo_coords, setGeoCoords] = useState('')

    const [ street, setStreet ] = useState('')
    const [ region, setRegion ] = useState('')

    const [ workShopImage, setWorkShopImage ] = useState( false )
    const [ StoreImageFile, setStoreImageFile ] = useState(false)
    const [ image_name, setImageName ] = useState('image name')
    const [ image_size, setImageSize ] = useState('0 B')


    const [ setSnack, setAlert, username , user_uid ] = useOutletContext()

    const [ basic, setBasic ] = useState({regions:[], id_types: []})

    const phone_number = 'phone_number'
    const text = 'text'
    const number = 'number'

    const profile_image_input = useRef()

 
    const handleRegionChange =  (event: SelectChangeEvent) => {
        setRegion( event.target.value )
    }

    
    function Update(elm, updater, type){
        const value = elm.target.value
        
        if(type === phone_number){
            if((Number(value) || value === '' || value === '0') && value.length <= 10)  {
                updater(value)
            }    
        }if(type === number){
            if(Number(value) || value === '' || value === '0'){
                updater(Number(value))
            }    
        }
        else if (type === text){
            updater(value)
        }
    }

   

    const required_client_inputs = [
    
        {input:workshop_name},
        {input: phone_number1},
        {input: street},
        {input: region},
      
      
    ]
    
    function validate_new_shopForm(){
        let incomplete = false
        for(const input of required_client_inputs){
            if(!input.input){
                incomplete = true
            }
        }
        if(incomplete){
            setSnack({
                message:'incomplete form',
                type:'error'
            })
        }
        return incomplete
    }

    
    function modify_workshop(){

        if(!validate_new_shopForm()){
            const form = new FormData()
            const f = {
                UID:data.UID,
                created_by: username,
            }

            if(workshop_name !== data.name){
                f['name'] = workshop_name
            }
            if(phone_number1 !== data.phone_number1){
                f['phone_number1'] = phone_number1
                
            }
            if(phone_number2 !== data.phone_number2){
                f['phone_number2'] = phone_number2
            }
            if(description !== data.description){
                f['description'] = description
            }
            if(geo_coords !== data.geo_coords){
                f['geo_coords'] = geo_coords
            }
            if(street !== data.street){
                f['street'] = street   
            }
            if(region !== data.region){
                f['region']=region 
            }
            


            form.append('form', JSON.stringify(f))
            form.append('workshop_image', StoreImageFile)


            fetch(main.server + '/modify_workshop', {
                method: 'POST',
                body: form
            }).then(response => response.json()).then(result => {
                console.log(result)
                if(result.request_status){
                    setAlert({
                        message:'workshop info modified',
                        type:'success'
                    })
                    setTimeout(() => window.location.assign('/workshops'),5000)
                }
                
            })
        }
        
    }
    

    function fetchMain(){
        console.log('fetch main')
        fetch(main.server + '/basic').then(resp => resp.json()).then(result =>{
            setBasic(result.data)
        })
    }

    function openImageFile(){
        console.log(profile_image_input)
        profile_image_input.current.click()
    }
    function HandleImageUpload(elm){
        const file = elm.target.files[0]
        setImageSize(get_file_size(Number(file.size)))
        setStoreImageFile(file)
        setImageName(file.name)
        const reader = new FileReader()
        reader.onload = function() {
            setWorkShopImage(reader.result);
        }
        reader.readAsDataURL(file)
    }


    useEffect(() => {
        if(!start){
            fetchMain()
            setSnack({
                message: 'page loaded',
            })

            setStart(true)
        }
        if(!filled){
            setWorkShopName(data.name)
            setPhoneNumber1(data.phone_number1)
            setPhoneNumber2(data.phone_number2)
            setDescription(data.description)
            setGeoCoords(data.geo_coords)
            setStreet(data.street)
            setRegion(data.region)
            if(data.workshop_image){
                setImageName(data.workshop_image)
                setWorkShopImage(main.server + `/media/${data.workshop_image}/`)
            }
            
            //const [ StoreImageFile, setStoreImageFile ] = useState(false)
            
            setFilled(true)
        }
    })
    
    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>New workshop form</div>
                </div>
                <div className="form_main form_centered">
                   
                    <div className="ModStoreImage">
                        <div className="form_section_title">workshop image</div>
                        <div className="form_section_main">
                            <div className="ProfileImage">
                                <div className="clientIdImageContainer">
                                    <img src={workShopImage ? workShopImage : workshopPlaceholder} alt="workshop image"/>
                                </div>
                                <div className="profileImageName">{image_name}</div>
                                <div className="profileImageSize">{image_size}</div>
                            
                            </div>
                            <div className="btn outlined" onClick={openImageFile}>
                                <div>
                                    <UploadIcon />
                                </div>
                                <div>
                                    upload workshop image
                                </div>
                            </div>
                         
                            <input ref={profile_image_input} onChange={HandleImageUpload} className="hidden_file_input" type="file"/>
                            
                        </div>
                    </div>
                    <div className="form_section">
                        <div className="form_section_title">basic information</div>
                        <div className="form_section_main">
                            <input ref={profile_image_input} onChange={HandleImageUpload} className="hidden_file_input" type="file"/>
                            <div><TextField value={workshop_name} onChange={(elm) => Update(elm, setWorkShopName, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Workshop name" variant="standard" /></div>
                            <div><TextField error={!check_phone_number(phone_number1)} helperText={!check_phone_number(phone_number1) ? 'invalid phone number':''} value={phone_number1} onChange={(elm) => Update(elm, setPhoneNumber1, phone_number)} sx={{width: 250}} className="text_field" id="standard-basic" label="Phone number 1" variant="standard" /></div>
                            <div><TextField error={!check_phone_number(phone_number2)} helperText={!check_phone_number(phone_number2) ? 'invalid phone number':''} value={phone_number2} onChange={(elm) => Update(elm, setPhoneNumber2, phone_number)} sx={{width: 250}} className="text_field" id="standard-basic" label="Phone number 2" variant="standard" /></div>
                        </div>
                    </div>
                    <div className="form_section">
                        <div className="form_section_main">
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={region}
                                    label="ID type"
                                    onChange={handleRegionChange}
                                    variant="standard"
                                >   
                                    {basic.regions.map((elm, ind) => (
                                        <MenuItem value={elm.name} key={ind}>{elm.name}</MenuItem>
                                    ))}
                                    
                                
                                </Select>
                            </FormControl>
                            
                          
                            <div><TextField value={street} onChange={(elm) => Update(elm, setStreet, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Street" variant="standard" /></div>
                            <div><TextField value={geo_coords} onChange={(elm) => Update(elm, setGeoCoords, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Geo coordinates" variant="standard" /></div>
                            
                            <div>
                                <TextField
                                    sx={{width: 250}}
                                    className="text_field"
                                    id="standard-multiline-static"
                                    label="workshop description"
                                    multiline
                                    rows={4}
                                
                                    value={description}
                                    variant="standard"
                                    onChange={(elm) => Update(elm, setDescription, text)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="submit_form">
                
                    <div className="btn outlined" onClick={modify_workshop}>
                        <div>
                            modify workshop
                        </div>
                    </div>
                    
                </div>
            </div>
        </Fade>
    )
}