import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton'

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'
import main  from '../resources/code/utils'


export default function DownloadDocument(props) {
   
    
    const [ type, setType ]  = useState(true)
   
    function handleDownloadType(type){
      if(type == 'all'){
        setType(true)
      }else{
        setType(false)
      }
    }
    function closeDownloader(){
        props.setFilterByStartDate(false)
        props.setFilterByEndDate(false)
        props.setFilterByTransactor(false)
        props.setDownloaderState(false)
    }

    function download_document(){
    
      props.setDownloaderState(false)
      props.download(type)
      
    } 




    const [ transactorSearchRes, setTransactorSearchRes ] = useState([])
   
   

    function HandleFilterByTransactor(){
        props.setFilterByTransactor(prev => !prev)
        props.setTransactor(['', ''])
    }
    
    function searchForTransactor(event){
        if(props.filter_by_transactor){
            console.log(event.target.value)
            
            if(event.target.value === ''){
                setTransactorSearchRes([])
                return
            }

            fetch(main.server + `/users?user=${event.target.value}`).then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                    setTransactorSearchRes(res.data.user_list)
                    props.setTransactor(['',''])
                }
            })
            
        }
    }


    function selectTransactor(event){
        const uid = event.target.dataset.uid
        const name = event.target.innerHTML
        props.setTransactor([name, uid])
        setTransactorSearchRes([])
    }
   

   

    


    



    return (
        
        <Dialog
            open={props.state}
            onClose={closeDownloader}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">
          Download Table
        </DialogTitle>
        <DialogContent>
          

            <div>
              <div>
                <Checkbox checked={type} onChange={() => handleDownloadType('all')}/> Download all records
              </div>
              <div className="padv10">
                <Checkbox checked={!type} onChange={() => handleDownloadType('filter')}/> Use filter settings
              </div>
            </div>

            {!type ?
                <div class="pad10">
                    <div className="dateTimeFInput">
                        <div>
                            <Checkbox checked={props.filter_by_startDate} onChange={() => props.setFilterByStartDate(prev => !prev)}/> start date
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="start date"
                                disabled={!props.filter_by_startDate}
                                value={props.StartDate}
                                variant="standard"
                                onChange={(newstartDate) => {
                                    props.setStartDate(newstartDate)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>

                    <div className="dateTimeFInput">
                        <div>
                            <Checkbox checked={props.filter_by_endDate} onChange={() => props.setFilterByEndDate(prev => !prev)}/> end date
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                variant="standard"
                                label="end date"
                                disabled={!props.filter_by_endDate}
                                value={props.EndDate}
                                onChange={(newEndDate) => {
                                    props.setEndDate(newEndDate);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>

                    <div className="filterInput">
                        <div>
                            <Checkbox checked={props.filter_by_transactor} onChange={HandleFilterByTransactor}/> filter by transactor
                        </div>
                    
                        <div id="searchBFilter">
                            <div className="searchInFilter">
                                <InputBase
                                    variant="standard"
                                    sx={{ ml: 1, flex: 1, fontSize:'.9em' }}
                                    placeholder="Search user list"
                                    inputProps={{ 'aria-label': 'search user list' }}
                                    disabled={!props.filter_by_transactor}
                                    onChange={searchForTransactor}
                                />
                                <IconButton type="button" sx={{ padding: '10px 10px ', borderRadius: 0 }} aria-label="search" >
                                    <SearchIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className="filterSearchRes">
                            {transactorSearchRes.length > 0 && props.filter_by_transactor ?
                                <Paper elevation={1} className="searchRBody">
                                    {transactorSearchRes.map((elm,ind) => (
                                        <div className="sResult" data-uid={elm.UID} onClick={selectTransactor} key={ind}>
                                            { elm.user }
                                        </div>
                                    ))}
                                
                                </Paper>
                            :''}
                            {props.Transactor && props.filter_by_transactor ?
                                <div className="selectedSearchItem">
                                    
                                    <span>selected system user: </span>
                                    <span>{ props.Transactor[0] }</span>
                                </div>
                            :''}
                        
                        </div>
                        
                    </div>

                </div>
          : ''}

          
          
        </DialogContent>
        <DialogActions>
            <Button onClick={closeDownloader}>
                Cancel
            </Button>
            <Button onClick={download_document}>
                Download document
            </Button>
            
        </DialogActions>
    </Dialog>

  );
}