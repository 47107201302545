import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import TextField from '@mui/material/TextField';

import Checkbox from '@mui/material/Checkbox'


export default function FilterDialog(props) {
   
    const [ stove_price, setStovePrice ] = useState(props.data.stove_price)
    const [ pellet_price, setPelletPrice ] = useState(props.data.pellet_price)
    const [ store_commission, setStoreCommision ] = useState(props.data.store_commission)
    const [ stove_split, setStoveSplit ] = useState(props.data.stove_split)
    const [ pellet_split, setPelletSplit ] = useState(props.data.pellet_split)



    const [ edit_stove_price, setEditStovePrice ] = useState(false)
    const [ edit_pellet_price, setEditPelletPrice ] = useState(false)
    const [ edit_store_commission, setEditStoreCommission ] = useState(false)
    const [ edit_stove_split, setEditStoveSplit ] = useState(false)
    const [ edit_pellet_split, setEditPelletSplit ] = useState(false)


    function closeFilter(){
        props.setDialogState(false)
    }
    function editPrices(){

    }
    function updateVal(event, updater){
        const val = event.target.value
        if(Number(val) || val === ''){
            updater(val)
        }
    }

    return (
        
        <Dialog
            open={props.state}
            onClose={closeFilter}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">
            Edit Pricing & commission
        </DialogTitle>
        <DialogContent>
            
            

            <div className="filterInput">
                <div>
                    <Checkbox checked={edit_stove_price} onChange={() => setEditStovePrice(prev => !prev)}/> Edit stove price
                </div>
                <div className='pad10'><TextField disabled={!edit_stove_price} value={stove_price} onChange={ event => updateVal(event, setStovePrice)} sx={{width: 250}} className="text_field" id="standard-basic" label="stove price" variant="standard" /></div>
                
            </div>
            <div className="filterInput">
                <div>
                    <Checkbox checked={edit_pellet_price} onChange={() => setEditPelletPrice(prev => !prev)}/> Edit pellet price per kg
                </div>
                <div className='pad10'><TextField disabled={!edit_pellet_price} value={pellet_price} onChange={event => updateVal(event, setPelletPrice)} sx={{width: 250}} className="text_field" id="standard-basic" label="pellet price" variant="standard" /></div>
                
            </div>
            <div className="filterInput">
                <div>
                    <Checkbox checked={edit_pellet_split} onChange={() => setEditPelletSplit(prev => !prev)}/> Edit pellet split
                </div>
                <div className='pad10'><TextField disabled={!edit_pellet_split} value={pellet_split} onChange={event => updateVal(event, setPelletSplit)} sx={{width: 250}} className="text_field" id="standard-basic" label="pellet split" variant="standard" /></div>
                
            </div>
            <div className="filterInput">
                <div>
                    <Checkbox checked={edit_stove_split} onChange={() => setEditStoveSplit(prev => !prev)}/> Edit stove split
                </div>
                <div className='pad10'><TextField disabled={!edit_stove_split} value={stove_split} onChange={event => updateVal(event, setStoveSplit)} sx={{width: 250}} className="text_field" id="standard-basic" label="stove split" variant="standard" /></div>
                
            </div>
            <div className="filterInput">
                <div>
                    <Checkbox checked={edit_store_commission} onChange={() => setEditStoreCommission(prev => !prev)}/> Edit store commission
                </div>
                <div className='pad10'><TextField disabled={!edit_store_commission} value={store_commission} onChange={event => updateVal(event, setStoreCommision)} sx={{width: 250}} className="text_field" id="standard-basic" label="store commision" variant="standard" /></div>
                
            </div>
            
        </DialogContent>
        <DialogActions>
            
            <Button onClick={editPrices} autoFocus>
                edit 
            </Button>
        </DialogActions>
    </Dialog>

  );
}