import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'
import main  from '../resources/code/utils'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';


export default function FilterDialog(props) {
   
    const [ transactorSearchRes, setTransactorSearchRes ] = React.useState([])
   

    function filterRecords(){
     
        props.getBankTransferRecords()
        props.setPage(0)
        props.close_cash_deposit_filter()
    }
    function ResetRecords(){
        props.reset()
    }


    function HandleFilterByTransactor(){
        props.setFilterBankTransfersByTransactor(prev => !prev)
        props.setBankTransfersByTransactor(['',''])
    }
    function HandleFilterBySource(){

        props.setFilterBankTransfersBySource(prev => !prev)
        props.setBankTransfersBySource('')
    }
   
    function searchForTransactor(event){
        if(props.filter_bank_transfer_by_transactor){
            console.log(event.target.value)
            
            if(event.target.value === ''){
                setTransactorSearchRes([])
                return
            }

            fetch(main.server + `/users?user=${event.target.value}`).then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                    setTransactorSearchRes(res.data.user_list)
                    props.setBankTransfersByTransactor(['',''])
                }
            })
            
        }
    }

 
    function selectTransactor(event){
        console.log(event.target.dataset.uid, event.target.innerHTML)
        props.setBankTransfersByTransactor([event.target.dataset.uid, event.target.innerHTML])
        setTransactorSearchRes([])
    }
   
    function handleTransferSource(event){
        //const uid = event.target.value
        props.setBankTransfersBySource(event.target.value)
    }
    function closeFilter(){
        props.setFilterBankTransfersByStartDate(false)
        props.setFilterBankTransfersByEndDate(false)
        props.setFilterBankTransfersByTransactor(false)
   

        props.close_cash_deposit_filter()
    }

    
    React.useEffect(()=>{
       

       
    })


    return (
        
        <Dialog
            open={props.state}
            onClose={closeFilter}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">
            Filter Records
        </DialogTitle>
        <DialogContent>
            
            <div className="dateTimeFInput">
                <div>
                    <Checkbox checked={props.filter_bank_transfer_by_startdate} onChange={() => props.setFilterBankTransfersByStartDate(prev => !prev)}/> start date
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="start date"
                        disabled={!props.filter_bank_transfer_by_startdate}
                        value={props.bankTransferStartDate}
                        variant="standard"
                        onChange={(newstartDate) => {
                            props.setBankTransfersStartDate(newstartDate)
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>

            <div className="dateTimeFInput">
                <div>
                    <Checkbox checked={props.filter_bank_transfer_by_endDate} onChange={() => props.setFilterBankTransfersByEndDate(prev => !prev)}/> end date
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        variant="standard"
                        label="end date"
                        disabled={!props.filter_bank_transfer_by_endDate}
                        value={props.bankTransferEndDate}
                        onChange={(newEndDate) => {
                            props.setBankTransfersEndDate(newEndDate);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>

            <div className="filterInput">
                <div>
                    <Checkbox checked={props.filter_bank_transfer_by_transactor} onChange={HandleFilterByTransactor}/> filter by transactor
                </div>
               
                <div id="searchBFilter">
                    <div className="searchInFilter">
                        <InputBase
                            variant="standard"
                            sx={{ ml: 1, flex: 1, fontSize:'.9em' }}
                            placeholder="Search user list"
                            inputProps={{ 'aria-label': 'search user list' }}
                            disabled={!props.filter_bank_transfer_by_transactor}
                            onChange={searchForTransactor}
                        />
                        <IconButton type="button" sx={{ padding: '10px 10px ', borderRadius: 0 }} aria-label="search" >
                            <SearchIcon />
                        </IconButton>
                    </div>
                </div>

                <div className="filterSearchRes">
                    {transactorSearchRes.length > 0 && props.filter_bank_transfer_by_transactor ?
                        <Paper elevation={1} className="searchRBody">
                            {transactorSearchRes.map((elm,ind) => (
                                <div className="sResult" data-uid={elm.UID} onClick={selectTransactor} key={ind}>
                                    { elm.user }
                                </div>
                            ))}
                           
                        </Paper>
                    :''}
                    {props.bankTransferTransactor[1] && props.filter_bank_transfer_by_transactor ?
                        <div className="selectedSearchItem">
                             
                            <span>selected system user: </span>
                            <span>{ props.bankTransferTransactor[1] }</span>
                        </div>
                    :''}
                   
                </div>
                
            </div>


            <div className="filterInput">
                <div>
                    <Checkbox checked={props.filter_bank_transfers_by_source} onChange={HandleFilterBySource}/> filter by transfer source
                </div>
               
                <div>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Transfer source</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.bankTransfersfilterBySource}
                            label="Payment plan"
                            onChange={handleTransferSource}
                            variant="standard"
                            disabled={!props.filter_bank_transfers_by_source}
                            sx={{width: 250}}
                        >   
                            {props.transfer_sources.map((elm, ind) => (
                                <MenuItem value={elm.name} key={ind} >{elm.name}</MenuItem>
                            ))}
                            
                        
                        </Select>
                    </FormControl>
                </div>

                
            </div>

            

           
            
        </DialogContent>
        <DialogActions>
            <Button onClick={ResetRecords} >
                Reset
            </Button>
            <Button onClick={filterRecords} >
                Filter
            </Button>
        </DialogActions>
    </Dialog>

  );
}