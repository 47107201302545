import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { _DateTime, formatCash } from '../resources/code/utils'
import main from '../resources/code/utils'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'


import TextField from '@mui/material/TextField';


export default function ViewStovePaymentRecord(props) {

  


    const [record_id, setRecordID ] = useState('')
    const [start, setStart] = useState(false)
    const [ mod_record, setModRecord ] = useState(false)
    const [customer_list, setCustomerList] = useState([])
    const [customer, setCustomer] = useState('')
    const [ amount, setAmount ] = useState('')

    function closeViewer(){

      setCustomer('')
      setAmount('')
      setRecordID('')
      setModRecord(false)
      props.close(false)
    }



    function delete_record(){
      props.delete_messenger()      
    }


    
   

   



    function modify_stove_payment(){
      const form = new FormData()
  
      const f = {
        amount: amount,
        customer: customer,
        UID: record_id,
      }
      
  
      form.append('form', JSON.stringify(f))

      props.modify_messenger(form)

    }

    function serchForClients(elm){
      setCustomer(elm.target.value)
    
      setCustomerList([])

      if(elm.target.value){
        fetch(main.server + `/clients?client=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
              setCustomerList(res.data.client_list)
            }
        })
      }
  }

    function selectClient(name, uid){
      setCustomer(name)
    
    }

    
   
    function back(){
      setModRecord(false)
      setCustomer('')
      setAmount('')
      setRecordID('')
    }


    function handleAmount(event){
      const a = event.target.value
      if(Number(a) || a === ''){
        setAmount(a)
      }
    }
 
    useEffect(() => {
      if(!start){
        
        setStart(true)
      }
    })
  

    return (
        
        <Dialog
            open={props.state}
            onClose={closeViewer}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">
            Stove Payment Info
        </DialogTitle>
        <DialogContent>
          {!mod_record?
          <div>
            <div>
              <div className="padv20">
                <div><Chip label={ "Payment Type: " + props.data.source } color="primary" variant="outlined" /></div>
              </div>
              <div className="info_value_pair">
                <div>Date time recorded: </div>
                <div>{_DateTime(props.data.date_created)}</div>
              </div>
              <div className="info_value_pair padv10">
                <div>Client: </div>
                <div>{props.data.client}</div>
              </div>
              <div className="info_value_pair">
                <div>Amount: </div>
                <div>{formatCash(props.data.amount)}</div>
              </div>
            
              <div className="info_value_pair">
                <div>Recorded by: </div>
                <div>{props.data.recorded_by}</div>
              </div>
             

             

            </div>


            

            <div className="padv20 viewRecordActions">
              
              <div className='btn btn_red' onClick={delete_record}>
                <div>delete record</div>
              </div>
            </div>


          </div>
          :

          <div>
            <div className="form_main">
                        
              <div className="form_section">
              
                <div className="form_section_main">

                  <div>
                    <TextField value={customer} onChange={serchForClients} sx={{width: 250}} className="text_field" id="standard-basic" label="Client" variant="standard" />

                    <div className="filterSearchRes">
                      {customer_list.length > 0 ? 
                        <Paper elevation={1} className="searchRBody">
                          {customer_list.map((elm,ind) => (
                            <div className="sResult" key={ind} onClick={() => selectClient(`${elm.first_name} ${elm.middle_name} ${elm.surname}`,elm.UID)}>
                              {elm.first_name} {elm.middle_name} {elm.surname}
                            </div>
                          ))}
                        </Paper>
                        
                      :''}
                    </div>
                       
             
                  </div>


                  <div><TextField value={amount} onChange={handleAmount} sx={{width: 250}} className="text_field" id="standard-basic" label="Amount" variant="standard" /></div>
                  
                 
                  
                </div>

              </div>  
              
              

            </div>
            <div className="padv20 viewRecordActions">
              <div className='btn ' onClick={back}>
                <div>back</div>
              </div>
              <div className='btn btn-success' onClick={modify_stove_payment}>
                <div>modify record</div>
              </div>
                
            </div>
          </div>
        }
        </DialogContent>
        <DialogActions>
            <Button onClick={closeViewer}>
              Close
            </Button>
            
        </DialogActions>
    </Dialog>

  );
}