import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { _DateTime, formatCash, _Date } from '../resources/code/utils'


export default function ViewPelletSale(props) {

   
    const [start, setStart] = useState(false)


    function closeViewer(){

      props.close(false)
    }







   
    useEffect(() => {
      if(!start){
        
        setStart(true)
      }
    })
  

    return (
        
      <Dialog
        open={props.state}
        onClose={closeViewer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
            Pellet Sale Info
        </DialogTitle>
        <DialogContent>
       
          <div>
            <div>
              <div className="info_value_pair">
                <div>Date: </div>
                <div>{_Date(props.data.date)}</div>
              </div>
              
              <div className="info_value_pair padv10">
                <div>Shop: </div>
                <div>{props.data.shop}</div>
              </div>
              <div className="info_value_pair">
                <div>Amount: </div>
                <div>{props.data.amount} kg</div>
              </div>
              <div className="info_value_pair">
                <div>Pellet payment: </div>
                <div>{formatCash(props.data.pellet_payment)}</div>
              </div>
              <div className="info_value_pair">
                <div>Stove payment: </div>
                <div>{formatCash(props.data.stove_payment)}</div>
              </div>
              <div className="info_value_pair">
                <div>Store commission: </div>
                <div>{formatCash(props.data.store_commission)}</div>
              </div>
              <div className="info_value_pair">
                <div>Date time recorded: </div>
                <div>{_DateTime(props.data.date_created)}</div>
              </div>
              <div className="info_value_pair">
                <div>Recorded by: </div>
                <div>{props.data.recorded_by}</div>
              </div>
             

             

            </div>


        
          </div>

        </DialogContent>
        <DialogActions>
            <Button onClick={closeViewer}>
              Close
            </Button>
            
        </DialogActions>
    </Dialog>

  );
}