import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton'

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'
import main  from '../resources/code/utils'
import LinearProgress from '@mui/material/LinearProgress';

export default function DownloadDocument(props) {
   
    
    const [ type, setType ]  = useState(true)
   
    function handleDownloadType(type){
      if(type == 'all'){
        setType(true)
      }else{
        setType(false)
      }
    }
    function closeDownloader(){
        props.setFilterPelletSalesByStartDate(false)
        props.setFilterPelletSalesByEndDate(false)
        props.setFilterPelletSalesByClient(false)
        props.setFilterPelletSalesByRecorder(false)
        
        props.setDownloaderState(false)
    }

    function download_document(){
    
      props.setDownloaderState(false)
      props.download(type)
      
    } 




    const [ clientSearchRes, setClientSearchRes ] = useState([])
    const [ userSearchRes, setUserSearchres ] = useState([])


    function filterRecords(){
     
        props.getPelletSalesRecords()
        props.setPage(0)
        props.close_pellet_records_filter()
    }
    function ResetRecords(){
        props.reset()
    }

   
    function HandleFilterByClient(){
        props.setFilterPelletSalesByClient(prev => !prev)
        props.setFilterPelletsByClient(null)
    }
    function HandleFilterByRecorder(){
        props.setFilterPelletSalesByRecorder(prev => !prev)
        props.setFilterPelletsByRecorder(null)
    }
    function searchForClient(event){
        if(props.filter_pellet_sales_by_client){
            console.log(event.target.value)
            
            if(event.target.value === ''){
                setClientSearchRes([])
                return
            }

            fetch(main.server + `/clients?client=${event.target.value}`).then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                    setClientSearchRes(res.data.client_list)
                    props.setFilterPelletsByClient(null)
                }
            })
            
        }
    }
    function searchSysetmUsers(event){
        if(props.filter_pellet_sales_by_recorder){
            console.log(event.target.value)
            
            if(event.target.value === ''){
                setClientSearchRes([])
                return
            }

            fetch(main.server + `/users?user=${event.target.value}`).then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                    setUserSearchres(res.data.user_list)
                    props.setFilterPelletsByRecorder(null)
                }
            })
            
        }
    }

    function selectClient(event){
        const uid = event.target.dataset.uid
        const name = event.target.innerHTML
        props.setFilterPelletsByClient([name, uid])
        setClientSearchRes([])
    }
    function selectRecorder(event){
       
        const name = event.target.innerHTML
        props.setFilterPelletsByRecorder(name)
        setUserSearchres([])
    }

  




    return (
        
        <Dialog
            open={props.state}
            onClose={closeDownloader}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">
          Download Table
        </DialogTitle>
        <DialogContent>
          

            <div>
              <div>
                <Checkbox checked={type} onChange={() => handleDownloadType('all')}/> Download all records
              </div>
              <div className="padv10">
                <Checkbox checked={!type} onChange={() => handleDownloadType('filter')}/> Use filter settings
              </div>
            </div>

            {!type ?
            <div class="pad10">
                <div className="dateTimeFInput">
                    <div>
                        <Checkbox checked={props.filter_pellet_sales_by_startDate} onChange={() => props.setFilterPelletSalesByStartDate(prev => !prev)}/> start date
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="start date"
                            disabled={!props.filter_pellet_sales_by_startDate}
                            value={props.pelletSales_startDate}
                            variant="standard"
                            onChange={(newstartDate) => {
                                props.setPelletSalesStartDate(newstartDate)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>

                <div className="dateTimeFInput">
                    <div>
                        <Checkbox checked={props.filter_pellet_sales_by_endDate} onChange={() => props.setFilterPelletSalesByEndDate(prev => !prev)}/> end date
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            variant="standard"
                            label="end date"
                            disabled={!props.filter_pellet_sales_by_endDate}
                            value={props.pelletSales_endDate}
                            onChange={(newEndDate) => {
                                props.setPelletSalesEndDate(newEndDate);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>

                <div className="filterInput">
                    <div>
                        <Checkbox checked={props.filter_pellet_sales_by_client} onChange={HandleFilterByClient}/> filter by client
                    </div>
                
                    <div id="searchBFilter">
                        <div className="searchInFilter">
                            <InputBase
                                variant="standard"
                                sx={{ ml: 1, flex: 1, fontSize:'.9em' }}
                                placeholder="Search Workclients"
                                inputProps={{ 'aria-label': 'search menu' }}
                                disabled={!props.filter_pellet_sales_by_client}
                                onChange={searchForClient}
                            />
                            <IconButton type="button" sx={{ padding: '10px 10px ', borderRadius: 0 }} aria-label="search" >
                                <SearchIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div className="filterSearchRes">
                        {clientSearchRes.length > 0 && props.filter_pellet_sales_by_client ?
                            <Paper elevation={1} className="searchRBody">
                                {clientSearchRes.map((elm,ind) => (
                                    <div className="sResult" data-uid={elm.UID} onClick={selectClient} key={ind}>
                                        {elm.name}
                                    </div>
                                ))}
                            
                            </Paper>
                        :''}
                        {props.filter_pellet_sales_by_client && props.pelletfilterByClient ?
                            <div className="selectedSearchItem">
                                
                                <span>selected client: </span>
                                <span>{ props.pelletfilterByClient[0] }</span>
                            </div>
                        :''}
                    
                    </div>
                    
                </div>


                <div className="filterInput">
                    <div>
                        <Checkbox checked={props.filter_pellet_sales_by_recorder} onChange={HandleFilterByRecorder}/> filter by recorder
                    </div>
                
                    <div id="searchBFilter">
                        <div className="searchInFilter">
                            <InputBase
                                variant="standard"
                                sx={{ ml: 1, flex: 1, fontSize:'.9em' }}
                                placeholder="Search Menu"
                                inputProps={{ 'aria-label': 'search menu' }}
                                disabled={!props.filter_pellet_sales_by_recorder}
                                onChange={searchSysetmUsers}
                            />
                            <IconButton type="button" sx={{ padding: '10px 10px ', borderRadius: 0 }} aria-label="search" >
                                <SearchIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div className="filterSearchRes">
                        {userSearchRes.length > 0 && props.filter_pellet_sales_by_recorder ?
                            <Paper elevation={1} className="searchRBody">
                                {userSearchRes.map((elm,ind) => (
                                    <div className="sResult" data-uid={elm.UID} onClick={selectRecorder} key={ind}>
                                        {elm.user}
                                    </div>
                                ))}
                            
                            </Paper>
                        :''}
                        {props.filter_pellet_sales_by_recorder && props.pelletfilterByRecorder ?
                            <div className="selectedSearchItem">
                                
                                <span>selected system user: </span>
                                <span>{ props.pelletfilterByRecorder }</span>
                            </div>
                        :''}
                    
                    </div>
                    
                </div>

            </div>
          : ''}

          
          
        </DialogContent>
        <DialogActions>
            <Button onClick={closeDownloader}>
                Cancel
            </Button>
            <Button onClick={download_document}>
                Download document
            </Button>
            
        </DialogActions>
    </Dialog>

  );
}