import {  useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox'

import IconButton from '@mui/material/IconButton'

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'
import main  from '../resources/code/utils'

export default function FilterDialog(props) {
   
    const [ searchRes, setSearchRes ] = useState([])


    function filterRecords(){
     
        props.getHeaterSalesRecords()
        props.setPage(0)
        props.close_heater_records_filter()
    }
    function ResetRecords(){
        props.reset()
    }

    function HandleFilterByClient(){
        props.setFilterHeaterSalesByCustomer(prev => !prev)
        props.setHeaterSalesCustomer(null)
    }
  
    function searchClients(event){
        if(props.filter_heater_sales_by_customer){
            console.log(event.target.value)
            
            if(event.target.value === ''){
                setSearchRes([])
                return
            }

            fetch(main.server + `/clients?client=${event.target.value}`).then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                    setSearchRes(res.data.client_list)
                    props.setHeaterSalesCustomer(null)
                }
            })
            
        }
    }

    

    function setectCustomer(event){
        const uid = event.target.dataset.uid
        const name = event.target.innerHTML
        props.setHeaterSalesCustomer([name, uid])
        setSearchRes([])
    }

    
    function closeFilter(){
        props.setFilterHeaterSalesByStartDate(false)
        props.setFilterHeaterSalesByEndDate(false)
        props.setFilterHeaterSalesByCustomer(false)
        props.close_heater_records_filter()
    }
    function setHeaterID(event){
        props.setHeaterSalesHeaterID(event.target.value)
    }
    
    useEffect(()=>{
       

       
    })


    return (
        
        <Dialog
            open={props.state}
            onClose={closeFilter}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">
            Filter Records
        </DialogTitle>
        <DialogContent>
            
            <div className="dateTimeFInput">
                <div>
                    <Checkbox checked={props.filter_heater_sales_by_startdate} onChange={() => props.setFilterHeaterSalesByStartDate(prev => !prev)}/> start date
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="start date"
                        disabled={!props.filter_heater_sales_by_startdate}
                        value={props.heaterSales_startDate}
                        variant="standard"
                        onChange={(newstartDate) => {
                            props.setHeaterSalesstartDate(newstartDate)
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>

            <div className="dateTimeFInput">
                <div>
                    <Checkbox checked={props.filter_heater_sales_by_endDate} onChange={() => props.setFilterHeaterSalesByEndDate(prev => !prev)}/> end date
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        variant="standard"
                        label="end date"
                        disabled={!props.filter_heater_sales_by_endDate}
                        value={props.heaterSales_endDate}
                        onChange={(newEndDate) => {
                            props.setHeaterSalesEndDate(newEndDate);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>

            <div className="filterInput">
                <div>
                    <Checkbox checked={props.filter_heater_sales_by_customer} onChange={HandleFilterByClient}/> filter by client
                </div>
               
                <div id="searchBFilter">
                    <div className="searchInFilter">
                        <InputBase
                            variant="standard"
                            sx={{ ml: 1, flex: 1, fontSize:'.9em' }}
                            placeholder="Search for client"
                            inputProps={{ 'aria-label': 'search for client' }}
                            disabled={!props.filter_heater_sales_by_customer}
                            onChange={searchClients}
                        />
                        <IconButton type="button" sx={{ padding: '10px 10px ', borderRadius: 0 }} aria-label="search" >
                            <SearchIcon />
                        </IconButton>
                    </div>
                </div>

                <div className="filterSearchRes">
                    {searchRes.length > 0 && props.filter_heater_sales_by_customer ?
                        <Paper elevation={1} className="searchRBody">
                            {searchRes.map((elm,ind) => (
                                <div className="sResult" data-uid={elm.UID} onClick={setectCustomer} key={ind}>
                                    {elm.name}
                                </div>
                            ))}
                           
                        </Paper>
                    :''}
                    {props.heaterSales_customer && props.filter_heater_sales_by_customer ?
                        <div className="selectedSearchItem">
                             
                            <div >selected customer: </div>
                            <div className='padv10'>{ props.heaterSales_customer[0] }</div>
                        </div>
                    :''}
                   
                </div>
                
            </div>



            

            <div className="filterInput">
                <div>
                    <Checkbox checked={props.filter_heater_sales_by_serialnumber} onChange={() => props.setFilterHeaterSalesBySerialNumber(prev => !prev)}/> filter by serial number
                </div>
                <div>
                    <TextField
                        
               
                        variant="standard"
                        sx={{width: 200}}
                        value={props.heaterSales_heaterID}
                        onChange={setHeaterID}
                        helperText="serial number"
                        disabled={!props.filter_heater_sales_by_serialnumber}
                        placeholder='serial number'
                    />
                     
                   
                </div>
                
            </div>


            
        </DialogContent>
        <DialogActions>
            <Button onClick={ResetRecords} >
                Reset
            </Button>
            <Button onClick={filterRecords} >
                Filter
            </Button>
        </DialogActions>
    </Dialog>

  );
}