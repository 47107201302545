import { useState, useEffect } from "react"
import { useLoaderData, Link } from "react-router-dom";
import main, {_DateTime} from '../resources/code/utils.js'
import '../styles/utils.css'
import '../styles/users.css'
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Fade from '@mui/material/Fade';
import { useOutletContext } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ClientsImage from '../resources/icons/id_placeholder.png'
import ClientsTable from "../tables/clients_table.js";
import FilterClientList from "../elements/filter_client_list.js";
import AddIcon from '../resources/icons/add-black-18dp.svg'

import DownloadDoc from '../elements/download_document'
import DownloadClientList from '../downloaders/download_client_list.js'

export function loader(){
    return fetch(main.server + '/clients').then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  


export default function Clients(){
    const [data, setData ] = useState(useLoaderData())
    
    const [ start, setStart ] = useState(false)
    const [setSnack, , , , _actions_, setNotAllowed] = useOutletContext()

    const [client_stats, setClientStats] = useState({ total_clients: 0, latest_clients:[]})
    const [searchInput, setSearchInput] = useState('')

    const [tab, setTab] = useState(1)   
    const [ check_actions, setCheckActions ]  = useState(false)


    function viewClient(elm){
        window.location.assign(`clients/view_client/${elm.target.dataset.uid}`)
    }

    function viewClientFromList(elm){

        window.location.assign(`clients/view_client/${elm.target.parentElement.dataset.uid}`)
    
    }
    function handleTabChange(event, new_value){
        
        if(new_value === 3){
            fetch(main.server + '/client_stats').then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                  
                    setClientStats(res.data)
                }
                
            })
        }
        if(new_value === 2){
            fetch(main.server + '/client_list').then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                  
                    setClientList(res.data.list)
                    setClientListTotals(res.data.totals)
                }
                
            })
        }
        setTab(new_value)
    }
    
  
    
    function serchForClients(elm){
        setSearchInput(elm.target.value)
        fetch(main.server + `/clients?client=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setData(res.data)
            }
        })
    }

    function serchClientList(elm){
        setClientListSearchInput(elm.target.value)
        fetch(main.server + `/client_list?client=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                  
                setClientList( res.data.list )
                setClientListTotals(res.data.totals)
            }
        })
    }

    
    const [client_list_data, setClientListData ] = useState(useLoaderData())


    const [dtf, setDTF] = useState(false)


    const [client_list_page, setClientListPage] = useState(0)
    const [ clientListTotals, setClientListTotals ] = useState({})
    const [ client_list, setClientList ] = useState([])

    const [ dl_client_list, setDlClientList ] = useState(false)
    const [ downloader_state, setDownloaderState ]  = useState(false)
    const [ client_filter_state, setStoveClientFilterState ] = useState(false)

    const [ view_stove_sale, setViewStoveSale ] = useState(false)
   
    const [ clientStartDate, setClientStartdate ] = useState(new Date())
    const [ clientEndDate, setClientEnddate ] = useState(new Date())
    const [ filter_clients_by_startDate, setFilterClientStartdate ] = useState(false)
    const [ filter_clients_by_endDate, setFilterClientEnddate ] = useState(false)

    const [ filterByStoveDebt, setFilterByStoveDebt] = useState(true)

    const [ filterByStoveSales, setFilterByStoveSales] = useState(true)
    const [ filterByHeaterSales, setFilterByHeaterSales] = useState(true)
    const [ filterByPelletSales, setFilterByPelletSales] = useState(true)

    const [ clientListSearchInput, setClientListSearchInput ] = useState('')
    const [filter_stove_sales_by_customer, setFilterStoveSalesByCustomer ] = useState(false)

    const [client_list_customer, setClientListCustomer] = useState([])


    function getClientListForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_clients_by_startDate){
            data['start_date'] = clientStartDate.toDateString()
        }
        if(filter_clients_by_endDate){
            data['end_date'] = clientEndDate.toDateString()
        }
        if(filter_stove_sales_by_customer){
            data['client'] = client_list_customer[1]
        }
      
        data['stove_debt'] = filterByStoveDebt
        data['stove_sales'] = filterByStoveSales
        data['pellet_sales'] = filterByPelletSales
        data['heater_sales'] = filterByHeaterSales
        
        if(filter_clients_by_startDate && filter_clients_by_endDate){
            setDTF(true)
        }
        
        form.append('form', JSON.stringify(data))

        return form
    }

   
    function get_client_list(form){
        fetch(main.server + '/client_list',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setClientList( res.data.list )
                setClientListTotals(res.data.totals)
            }
            
        })
    }

    
    function get_more_client_list(form){
        fetch(main.server + '/client_list',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setClientList( prev => [ ...prev , ...res.data.list] )
                setClientListTotals(res.data.totals)
            }
            
        })
    }


   
    function fetchMoreClients(){
        
        const form = getClientListForm( client_list.length > 0 ? client_list.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_more_client_list(form)
        
    }
    function getClientList(){
        const form = getClientListForm(0)
        get_client_list(form)
    }

    function close_client_records_filter(){
        setStoveClientFilterState(false)
    }
    function open_client_records_filter(){
        setStoveClientFilterState(true)
    }

    
    function downloadClientList(all=false) {

        
        setDownloaderState(true)
        const form = getClientListForm(0)
        if(all){
            form.append('download_all_records', true)
        }
 
        setSnack({message:'Download document...'})


        fetch(main.server + '/download_client_list_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDlClientList(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDlClientList(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
        })




        
        
    }

    useEffect(() => {
        if(_actions_.length > 0 && !check_actions){

            if(_actions_.indexOf('view_clients') !== -1){
                setNotAllowed(false)
            }else{
                setNotAllowed(true)
            }
            setCheckActions(true)
        }
    })

    
    function RenderClients(){
        if(tab === 1){
            return (
                <div>
                    
                    <div id="user_main_nav" >
                       

                        <div className="searchBarSmall">
                            <div><input type="text"  value={searchInput} onChange={serchForClients} /></div>
                            <div><SearchIcon/></div>
                        </div>
                        <div>
                            { _actions_.indexOf('create_new_clients') !== -1 ?
                                <Link className="clear_decor" to="/clients/new_client">
                                    <div className="btn outlined responsiveBtn">
                                        <div>
                                            <img src={AddIcon} alt="icon"/>
                                        </div>
                                        <div>
                                            Add new client
                                        </div>

                                    </div>
                                </Link>
                            :''}
                        </div>
                    </div>
                    <div className="clientsListMain">
                        {data.client_list.map((elm, ind) => (
                            <div key={ind} className="client_container" data-uid={elm.UID} onClick={viewClient}>
                        
                                <div className="flex_row flex_align_center flex_justify_center">
                                    <div className="clientIDAvatar">
                                        { elm.id_image ? <img src={ main.server + '/media/'+ elm.id_image} alt="user image"/>:
                                        <img src={ClientsImage} alt="profile image"/>}
                                    </div>
                                    
                                    
                                </div>
                            
                                <div className="center bold600 pad10">{elm.name}</div>
                              
                                
                                
                            </div>
                        ))}
                    </div>

                </div>
            )
        }
        if(tab === 3){
            return(
                <div id="user_stats">

                    <div>
                        <div  className="container">
                            <div className="container_heading">statistics</div>
                            <div className="container_main">
                                
                                <div className="info_value_pair">
                                    <div>Total clients: </div> <div>{client_stats.total_clients}</div>
                                </div>
                            
                            
                            </div>
                        </div> 
                    </div>
                    <div>
                        <div  className="container">
                            <div className="container_heading">latest clients</div>
                            <div className="container_main">
                                
                                {client_stats.latest_clients.map((record, ind) => (
                                    <div className="user_list_item" key={ind}>
                                        <div className="user_list_item_image">
                                            { record.id_image ? <img src={'http://127.0.0.1:8000/media/' + record.id_image} alt="profile image"/>:
                                            <img src={ClientsImage} alt="profile image"/>}
                                        </div>
                                        <div >
                                            <div className="info_value_pair_inline">
                                                <div>{record.name}</div>
                                            </div>
                                            <div className="info_value_pair_inline">
                                                <div>date & time created:</div><div>{_DateTime(record.date_created)}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                
                            
                            </div>
                        </div>
                    </div>
                </div>
            )
        }if(tab === 2){
            return(
                <div className="pad10">
                    <div className="tableSearchBar">
                        <div className="searchBarSmall">
                            <div><input type="text"  value={clientListSearchInput} onChange={serchClientList} /></div>
                            <div><SearchIcon/></div>
                        </div>  
                    </div>

                    <div>
                        <ClientsTable 
                            startDate={clientStartDate} 
                            endDate={clientEndDate}  
                            dtf={dtf} 
                            page={client_list_page} 
                            setPage={setClientListPage} 
                            totals={clientListTotals} 
                            sales={client_list} 
                            fetch_more={fetchMoreClients} 
                            filter={open_client_records_filter} 
                            view_record={viewClientFromList}
                            download={() => setDlClientList(true)}
                        />
                    </div>


                    <FilterClientList
                    
                        state={client_filter_state}

                        filter_by_startDate={filter_clients_by_startDate}
                        filter_by_endDate={filter_clients_by_endDate}
                        filter_stove_sales_by_customer={filter_stove_sales_by_customer}

                        setFilterByStartDate={setFilterClientStartdate}
                        setFilterByEndDate={setFilterClientEnddate}
                        setFilterStoveSalesByCustomer={setFilterStoveSalesByCustomer}
                        
                        setEndDate={setClientEnddate}
                        setStartDate={setClientStartdate}

                        filter_endDate={clientEndDate}
                        filter_startDate={clientStartDate}
                        
                        client_list_customer={client_list_customer}
                        setClientListCustomer={setClientListCustomer}


                        getRecords={getClientList}
                        setPage={setClientListPage}
                        close_filter={close_client_records_filter}

                        filterByStoveSales={filterByStoveSales}
                        setFilterByStoveSales={setFilterByStoveSales}

                        filterByPelletSales={filterByPelletSales}
                        setFilterByPelletSales={setFilterByPelletSales}
                        
                        filterByHeaterSales={filterByHeaterSales}
                        setFilterByHeaterSales={setFilterByHeaterSales}

                        filterByStoveDebt={filterByStoveDebt}
                        setFilterByStoveDebt={setFilterByStoveDebt}
                       


                    />


                    <DownloadClientList
                        state={dl_client_list}
                        setDownloaderState={setDlClientList}
                        download={downloadClientList}

                        filter_stove_sales_by_startdate={filter_clients_by_startDate}
                        filter_stove_sales_by_endDate={filter_clients_by_endDate}
                        filter_stove_sales_by_customer={filter_stove_sales_by_customer}
                        
                        
                        setFilterStoveSalesByStartDate={setFilterClientStartdate}
                        setFilterStoveSalesByEndDate={setFilterClientEnddate}
                        setFilterStoveSalesByCustomer={setFilterStoveSalesByCustomer}
                        

                        stoveSales_startDate={clientStartDate}
                        setStoveSalesstartDate={setClientStartdate}

                        stoveSales_endDate={clientEndDate}
                        setStoveSalesEndDate={setClientEnddate}

                
                        stoveSales_customer={client_list_customer}
                        setStoveSalesCustomer={setClientListCustomer}

                        filterByStoveSales={filterByStoveSales}
                        setFilterByStoveSales={setFilterByStoveSales}

                        filterByPelletSales={filterByPelletSales}
                        setFilterByPelletSales={setFilterByPelletSales}
                        
                        filterByHeaterSales={filterByHeaterSales}
                        setFilterByHeaterSales={setFilterByHeaterSales}

                        filterByStoveDebt={filterByStoveDebt}
                        setFilterByStoveDebt={setFilterByStoveDebt}


                    />
                    
                    
                    
                </div>
            )
        }
    }
    useEffect(() =>{
        if(!start){
            setSnack({
                message: 'page loaded',
               
            })
            setStart(true)
        }
    })



    return(
        <Fade in={true}>
            <div className="page">
              
                <div className="system_title">
                    <div>Clients</div>
                </div>
                    
          
                <div className="padv10">
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab value={1} label="Clients"  />
                        <Tab value={2} label="Client list"  />
                        <Tab value={3} label="Statistics"  />
                    
                    </Tabs>
                </div>

                <div className="centerPage">
                    {RenderClients()}
                </div>

                <DownloadDoc 
                    state={downloader_state} 
                    setDownloaderState={setDownloaderState}
                    
                />

               
            </div>
        </Fade>
    )
}