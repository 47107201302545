import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { _DateTime, formatCash, _Date } from '../resources/code/utils'
import main from '../resources/code/utils'

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import TextField from '@mui/material/TextField';


export default function ViewPelletSale(props) {

    const [record_id, setRecordID ] = useState('')
    const [start, setStart] = useState(false)
    const [ mod_record, setModRecord ] = useState(false)
    const [branch_list, setBranchList] = useState([])
    const [shop, setShop] = useState('')

    function closeViewer(){

      setShop('')

      setRecordID('')
      setModRecord(false)
      props.close(false)
    }



    function delete_record(){
      props.delete_messenger()      
    }



    
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

   



    function modify_stove_sale(){
      const form = new FormData()
  
      const f = {

        shop: shop,
        UID: record_id,
      }
      
  
      form.append('form', JSON.stringify(f))

      props.modify_messenger(form)

    }

    function searchForBranches(elm){
      setShop(elm.target.value)
      setAnchorEl(elm.currentTarget)
      fetch(main.server + `/shops?q=${elm.target.value}`).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
      }).then(res => {
          if(res.request_status){
              setBranchList(res.data.shops)
          }
      })
  }

    function selectBranch(name, uid){
      setShop(name)
      setAnchorEl(null)
    }

    
    function modifyRecord(){
      setModRecord(true)
      setShop(props.data.shop)

      setRecordID(props.data.UID)
    }
    function back(){
      setModRecord(false)
      setShop('')

      setRecordID('')
    }



 
    useEffect(() => {
      if(!start){
        
        setStart(true)
      }
    })
  

    return (
        
        <Dialog
            open={props.state}
            onClose={closeViewer}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">
            Pellet Sale Info
        </DialogTitle>
        <DialogContent>
          {!mod_record?
          <div>
            <div>
              <div className="info_value_pair">
                <div>Date: </div>
                <div>{_Date(props.data.date)}</div>
              </div>
              
              <div className="info_value_pair padv10">
                <div>Shop: </div>
                <div>{props.data.shop}</div>
              </div>
              <div className="info_value_pair">
                <div>Amount: </div>
                <div>{props.data.amount} kg</div>
              </div>
              <div className="info_value_pair">
                <div>Pellet payment: </div>
                <div>{formatCash(props.data.pellet_payment)}</div>
              </div>
              <div className="info_value_pair">
                <div>Stove payment: </div>
                <div>{formatCash(props.data.stove_payment)}</div>
              </div>
              <div className="info_value_pair">
                <div>Store commission: </div>
                <div>{formatCash(props.data.store_commission)}</div>
              </div>
              <div className="info_value_pair">
                <div>Pellet debt: </div>
                <div>{formatCash(props.data.pellet_debt)}</div>
              </div>
              <div className="info_value_pair">
                <div>Stove debt: </div>
                <div>{formatCash(props.data.stove_debt)}</div>
              </div>
              <div className="info_value_pair">
                <div>Commission debt: </div>
                <div>{formatCash(props.data.commission_debt)}</div>
              </div>
              <div className="info_value_pair">
                <div>Date time recorded: </div>
                <div>{_DateTime(props.data.date_created)}</div>
              </div>
              <div className="info_value_pair">
                <div>Recorded by: </div>
                <div>{props.data.recorded_by}</div>
              </div>
             

             

            </div>


            

            <div className="padv20 viewRecordActions">
              <div className='btn btn_aqua' onClick={modifyRecord}>
                <div>modify record</div>
              </div>
              <div className='btn btn_red' onClick={delete_record}>
                <div>delete record</div>
              </div>
            </div>


          </div>
          :

          <div>
            <div className="form_main">
                        
              <div className="form_section">
              
                <div className="form_section_main">

                  <div className="flex_row ">
                    <TextField value={shop} onChange={searchForBranches} sx={{width: 250}} className="text_field" id="standard-basic" label="Shop" variant="standard" />

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >  
                        {branch_list.map((elm,ind) => (
                            <MenuItem key={ind} onClick={() => selectBranch(elm.name, elm.UID)}>{elm.name}</MenuItem>
                        ))}
                    </Menu>
                  </div>
                  
                 
                  
                </div>

              </div>  
              
              

            </div>
            <div className="padv20 viewRecordActions">
              <div className='btn ' onClick={back}>
                <div>back</div>
              </div>
              <div className='btn btn-success' onClick={modify_stove_sale}>
                <div>modify record</div>
              </div>
                
            </div>
          </div>
        }
        </DialogContent>
        <DialogActions>
            <Button onClick={closeViewer}>
              Close
            </Button>
            
        </DialogActions>
    </Dialog>

  );
}