import { useEffect, useState } from 'react'

import { useLoaderData } from "react-router-dom";
import '../styles/utils.css'

import ProfileImage from '../resources/icons/profile.png'
import {uuid4, _DateTime} from '../resources/code/utils'
import main from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";





import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UpdateProfileImage from '../elements/update_profileImage';
import UpdatePassword from '../elements/updatePassword';

export function loader({params}){
    console.log(params)

    return fetch(main.server + `/user_info?i=${params.id}`).then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  


export default function ViewAccountInfo(){
    
    const [start, setStart] = useState(false)
    const [data ] = useState(useLoaderData())
    const [filled, setFilled ] = useState(false)

    const [username, setUsername] = useState('')
    const [phone_number1, setPhoneNumber1] = useState('')
    const [phone_number2, setPhoneNumber2] = useState('')
    const [description, setDescription ] = useState('')
    const [password, setPassword ] = useState('')
    const [profileImage, setProfileImage ] = useState(false)

 
    const [account_type, setAccountType] = useState('')
  
    const [setSnack, setAlert, , user_uid ] = useOutletContext()
    const [ date_created, setDateCreated ] = useState('') 
    const [ uid, setUID] = useState('')



    const [ profileImageDialogState, setProfileImageDialogState ]   = useState(false)


    function closeProfileImageUpdater(){
        setProfileImageDialogState(false)
    }
    function openProfileImageUpdater(){
        setProfileImageDialogState(true)
    }


    const [ passwordDialogState, setPasswordDialogState ]   = useState(false)


    function closePasswordUpdater(){
        setPasswordDialogState(false)
    }
    function openPasswordUpdater(){
        setPasswordDialogState(true)
    }



    const [ confrim_state, setConfirmState ] = useState(false)
    const [ confrim_function, setConfirmFunction ] = useState([])


    function _delete_user (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:data.UID}))
  
        fetch( main.server + '/delete_user',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setAlert({ message:'user deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.assign('/users') }, 3000)
          }
        })
    }



    function closeConfirmDialog(){
        setConfirmState(false)
        setConfirmFunction([])
    }


    function accept(){
        confrim_function[0]()
    }


    function modifyUser(elm){
        window.location.assign(`/users/modify_user_info/UID/${elm.target.dataset.uid}`)
    }
  
    useEffect(() => {
        if(!start){
            setPassword(uuid4())

            setSnack({
                message: 'page loaded',
            })
            setStart(true)
            
        }
        if(! filled){
            setUsername(data.user)
            setPhoneNumber1(data.phone_number1)
            setPhoneNumber2(data.phone_number2)
            setDescription(data.description)
            setProfileImage(data.profile_image)
            setAccountType(data.account_type)
            setDateCreated(data.date_created)
            setUID(data.UID)
            setFilled(true)
        }
    })



    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>My Account</div>
                </div>
                <div className="account_info_main">
                    
                    <div>
    
                    
                        
                    
                     
                
                        <div className="my_account_main_info">
                            <div>

                                <div className="profileImageContainerFlat">
                                    { profileImage ? <img src={ main.server + `/media/${profileImage} `} alt="user profile image"/> : 
                                    <img src={ProfileImage} alt="user profile image"/>
                                    }
                                </div>
                                <div className="padv20">
                                    <div className="title_value_pair">
                                        <div>Username</div>
                                        <div>{username}</div>
                                    </div>
                                    <div className="padv10">
                                        <div className="btn outlined" data-uid={data.UID} onClick={modifyUser}>
                                            <div>modify user info</div>
                                        </div>
                                    </div>
                                    <div className="padv10">
                                        <div className="btn outlined" data-uid={data.UID} onClick={openProfileImageUpdater}>
                                            <div>update profile image</div>
                                        </div>
                                    </div>
                                    <div className="padv10">
                                        <div className="btn outlined" data-uid={data.UID} onClick={openPasswordUpdater}>
                                            <div>change password</div>
                                        </div>
                                    </div>
                               
                                </div>
                         
                                
                                
                            </div>
                            <div>
                                <div className="title_value_pair">
                                    <div>Account type</div>
                                    <div>{account_type}</div>
                                </div>
                                <div className="title_value_pair">
                                    <div>Phone number 1</div>
                                    <div>{phone_number1}</div>
                                </div>
                                <div className="title_value_pair">
                                    <div>Phone number 2</div>
                                    <div>{phone_number2}</div>
                                </div>
                                
                            </div>
                            <div>
                                <div className="title_value_pair">
                                    <div>Date & time created</div>
                                    <div>{_DateTime(date_created)}</div>
                                </div>
                                <div className="title_value_pair">
                                    <div>UID</div>
                                    <div>{uid}</div>
                                </div>
                                <div className="title_value_pair">
                                    <div>Description</div>
                                    <div>{description}</div>
                                </div>
                            </div>

                           
                        </div>
                    </div>
                       
              
                     
                    
                </div>
                <UpdateProfileImage 
                    state={profileImageDialogState}
                    close={closeProfileImageUpdater}
                    userID={user_uid}
                    setAlert={setAlert}
                    setSnack={setSnack}
                />

                <UpdatePassword
                    state={passwordDialogState}
                    close={closePasswordUpdater}
                    userID={user_uid}
                    setAlert={setAlert}
                    setSnack={setSnack}
                />

                <Dialog
                    open={confrim_state}
                    onClose={closeConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Action"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to perform this action ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog}>DECLINE</Button>
                        <Button onClick={accept}>
                            ACCEPT
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Fade>
    )
}