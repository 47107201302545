
import { useEffect, useState } from 'react'
import { useLoaderData } from "react-router-dom";
import '../styles/utils.css'
import main from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'



export function loader(){
    return fetch(main.server + '/settings_presets').then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  
export default function Settings(){
    const [data  ] = useState(useLoaderData())
    const [setSnack, , , , _actions_ , setNotAllowed ] = useOutletContext()
    const [start, setStart] = useState(false)



    const [ stove_price, setStovePrice ] = useState(data.prices.stove_price)
    const [ pellet_price, setPelletPrice ] = useState(data.prices.pellet_price)
    const [ store_commission, setStoreCommision ] = useState(data.prices.store_commission)
    const [ stove_split, setStoveSplit ] = useState(data.prices.stove_split)
    const [ pellet_split, setPelletSplit ] = useState(data.prices.pellet_split)
    const [ heater_price, setHeaterPrice ] = useState(data.prices.heater_price)


    const [ edit_stove_price, setEditStovePrice ] = useState(false)
    const [ edit_pellet_price, setEditPelletPrice ] = useState(false)
    const [ edit_store_commission, setEditStoreCommission ] = useState(false)
    const [ edit_stove_split, setEditStoveSplit ] = useState(false)
    const [ edit_pellet_split, setEditPelletSplit ] = useState(false)
    const [ edit_heater_price, setEditHeaterPrice ] = useState(false)


    const [ check_actions, setCheckActions ]  = useState(false)

    function updateVal(event, updater){
        const val = event.target.value
        if(Number(val) || val === ''){
            updater(val)
        }
    }

    function get_form(){
        
        const form = new FormData()

        const f = {}

        if(edit_stove_price){
            f['stove_price'] = stove_price
        }
        if(edit_pellet_price){
            f['pellet_price'] = pellet_price
        }
        if(edit_store_commission){
            f['store_commission'] = store_commission
        }
        if(edit_stove_split){
            f['stove_split'] = stove_split
        }
        if(edit_pellet_split){
            f['pellet_split'] = pellet_split
        }
        if(edit_heater_price){
            f['heater_price'] = heater_price
        }
        
        form.append('form', JSON.stringify(f))
        return form
    }

    function updatePricing(){
        const form = get_form()
        fetch(main.server + '/update_pricing',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
               setSnack({message:'settings updated'})
               setTimeout(() => { window.location.reload() } , 5000 )
            }
            
        })
    }

    useEffect(() => {
        if(!start){
            
            setSnack({
                message: 'page loaded',
            })
                
            setStart(true)
        }
        if(_actions_.length > 0 && !check_actions){

            if(_actions_.indexOf('view_settings') !== -1){
                setNotAllowed(false)
            }else{
                setNotAllowed(true)
            }
            setCheckActions(true)
        }
    })
    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    Settings
                </div>
                <div id="settings_main">
                    <div className="settings_section">

                        <div className="underlined_title">
                            <div>Product pricing and commission</div>
                        </div>
                        <div>
                            <div className="filterInput">
                                <div>
                                    <Checkbox checked={edit_stove_price} onChange={() => setEditStovePrice(prev => !prev)}/> Edit stove price
                                </div>
                                <div className='pad10'><TextField disabled={!edit_stove_price} value={stove_price} onChange={ event => updateVal(event, setStovePrice)} sx={{width: 250}} className="text_field" id="standard-basic" label="stove price" variant="standard" /></div>
                                
                            </div>
                            <div className="filterInput">
                                <div>
                                    <Checkbox checked={edit_heater_price} onChange={() => setEditHeaterPrice(prev => !prev)}/> Edit heater price
                                </div>
                                <div className='pad10'><TextField disabled={!edit_heater_price} value={heater_price} onChange={ event => updateVal(event, setHeaterPrice)} sx={{width: 250}} className="text_field" id="standard-basic" label="heater price" variant="standard" /></div>
                            </div>
                            <div className="filterInput">
                                <div>
                                    <Checkbox checked={edit_pellet_price} onChange={() => setEditPelletPrice(prev => !prev)}/> Edit pellet price per kg
                                </div>
                                <div className='pad10'><TextField disabled={!edit_pellet_price} value={pellet_price} onChange={event => updateVal(event, setPelletPrice)} sx={{width: 250}} className="text_field" id="standard-basic" label="pellet price" variant="standard" /></div>
                                
                            </div>
                            <div className="filterInput">
                                <div>
                                    <Checkbox checked={edit_pellet_split} onChange={() => setEditPelletSplit(prev => !prev)}/> Edit pellet split
                                </div>
                                <div className='pad10'><TextField disabled={!edit_pellet_split} value={pellet_split} onChange={event => updateVal(event, setPelletSplit)} sx={{width: 250}} className="text_field" id="standard-basic" label="pellet split" variant="standard" /></div>
                                
                            </div>
                            <div className="filterInput">
                                <div>
                                    <Checkbox checked={edit_stove_split} onChange={() => setEditStoveSplit(prev => !prev)}/> Edit stove split
                                </div>
                                <div className='pad10'><TextField disabled={!edit_stove_split} value={stove_split} onChange={event => updateVal(event, setStoveSplit)} sx={{width: 250}} className="text_field" id="standard-basic" label="stove split" variant="standard" /></div>
                                
                            </div>
                            <div className="filterInput">
                                <div>
                                    <Checkbox checked={edit_store_commission} onChange={() => setEditStoreCommission(prev => !prev)}/> Edit store commission
                                </div>
                                <div className='pad10'><TextField disabled={!edit_store_commission} value={store_commission} onChange={event => updateVal(event, setStoreCommision)} sx={{width: 250}} className="text_field" id="standard-basic" label="store commision" variant="standard" /></div>
                                
                            </div>
                            
                        </div>
                        <div className='padv20 flex_row flex_align_center flex_justify_end'>
                            <div className="btn" onClick={updatePricing}>
                                Save changes
                            </div>
                        </div>
                    </div>
                </div>
           
               
            </div>
        </Fade>
    )
}