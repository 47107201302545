import { useEffect, useState, useRef } from 'react'
import TextField from '@mui/material/TextField';
import '../styles/utils.css'
import UploadIcon from '@mui/icons-material/Upload';
import ProfileImage from '../resources/icons/profile.png'
import {uuid4} from '../resources/code/utils'
import main, { get_file_size, check_phone_number } from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';



export default function NewUserForm(){
    
    const [start, setStart] = useState(false)

    const [username, setUsername] = useState('')
    const [phone_number1, setPhoneNumber1] = useState('')
    const [phone_number2, setPhoneNumber2] = useState('')
    const [description, setDescription ] = useState('')
    const [password, setPassword ] = useState('')
    const [profileImage, setProfileImage ] = useState(false)
    const [profileImageFile, setProfileImageFile] = useState(false)
    const [image_name, setImageName] = useState('image name')
    const [image_size, setImageSize] = useState('0 B')
    const [account_type, setAccountType] = useState('')
    const [ basic, setBasic ] = useState({account_types:[]})
    const [setSnack, setAlert, username_ ] = useOutletContext()

    const phone_number = 'phone_number'
    const text = 'text'

    const profile_image_input = useRef()

    function Update(elm, updater, type){
        const value = elm.target.value
        
        if(type === phone_number){
            if((Number(value) || value === '' || value === '0') && value.length <= 10){
                updater(value)
            }    
        }else{
            updater(value)
        }
    }

    const required_inputs = [
        {input: username},
      
        {input: password},
        {input: account_type},
    ]

    function validate_new_userForm(){
        let incomplete = false
        for(const input of required_inputs){
            if(!input.input){
                incomplete = true
            }
        }
        if(incomplete){
            setSnack({
                message:'incomplete form',
                type:'error'
            })
        }
        return incomplete
    }

    

    const handleAccountType = (event: SelectChangeEvent) => {
        setAccountType( event.target.value )
    }


    function createNewUser(){

        if(!validate_new_userForm()){
            const form = new FormData()
            const f = {
                basic:{
                    username: username,
                    password: password
                },
                secondary:{
                    phone_number1: phone_number1,
                    phone_number2: phone_number2,
                    description: description,
                    account_type:account_type,
                    created_by: username_,
                    
                }
            }
            form.append('form', JSON.stringify(f))
            if(profileImageFile){
                form.append('profile_image', profileImageFile)
            }


            fetch(main.server + '/create_new_user', {
                method: 'POST',
                body: form
            }).then(response => response.json()).then(result => {
                console.log(result)
                if(result.request_status){
                    setAlert({
                        message:'user created',
                        type:'success'
                    })
                    setTimeout(() => window.location.assign('/users'),5000)
                }else{
                    setSnack({
                        message: result.message
                    })
                }
            })
        }
        
    }
    function openImageFile(){
        console.log(profile_image_input)
        profile_image_input.current.click()
    }
    function HandleImageUpload(elm){
        const file = elm.target.files[0]
        setImageSize(get_file_size(Number(file.size)))
        setProfileImageFile(file)
        setImageName(file.name)
        const reader = new FileReader()
        reader.onload = function() {
            setProfileImage(reader.result);
        }
        reader.readAsDataURL(file)
    }

    function fetchMain(){
        console.log('fetch main')
        fetch(main.server + '/basic').then(resp => resp.json()).then(result =>{
            setBasic(result.data)
        })
    }

  
    useEffect(() => {
        if(!start){
            setPassword('password')
            fetchMain()
            setSnack({
                message: 'page loaded',
               
            })
            setStart(true)
            
        }
    })



    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>New user form</div>
                </div>
                <div className="form_main form_centered">
                    <div className="form_section">
                        <div className="form_section_title">user image</div>
                        <div className="imageMain">
                            <div className="ProfileImage">
                                <div className="profileImageContainer">
                                    { profileImage ? <img src={ main.server + `/media/${profileImage}`} alt="profile image" /> : <img src={ProfileImage} alt="profile image placeholder" />} 
                                </div>
                                <div className="profileImageName">{image_name}</div>
                                <div className="profileImageSize">{image_size}</div>
                                
                            </div>
                            <div className="btn outlined" onClick={openImageFile}>
                                <div>
                                    <UploadIcon />
                                </div>
                                <div>
                                    upload profile image
                                </div>
                            </div>
                            <input ref={profile_image_input} onChange={HandleImageUpload} className="hidden_file_input" type="file"/>
                        </div>
                    </div>
                    <div className="form_section">
                        <div className="form_section_title">basic information</div>
                        <div className="form_section_main">
                            <div>
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Account type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={account_type}
                                        label="Account type"
                                        onChange={handleAccountType}
                                        variant="standard"
                                        sx={{width: 250}}
                                    >   
                                        {basic.account_types.map((elm, ind) => (
                                            <MenuItem value={elm.name} key={ind}>{elm.name}</MenuItem>
                                        ))}
                                        
                                    
                                    </Select>
                                </FormControl>
                            </div>
                            <div><TextField value={username} onChange={(elm) => Update(elm, setUsername, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Username" variant="standard" /></div>
                            <div><TextField error={!check_phone_number(phone_number1)} helperText={!check_phone_number(phone_number1) ? 'invalid phone number':''} value={phone_number1} onChange={(elm) => Update(elm, setPhoneNumber1, phone_number)} sx={{width: 250}} className="text_field" id="standard-basic" label="Phone number 1" variant="standard" /></div>
                            <div><TextField error={!check_phone_number(phone_number2)} helperText={!check_phone_number(phone_number2) ? 'invalid phone number':''} value={phone_number2} onChange={(elm) => Update(elm, setPhoneNumber2, phone_number)} sx={{width: 250}} className="text_field" id="standard-basic" label="Phone number 2" variant="standard" /></div>
                            <div><TextField sx={{width: 250}} disabled value={password} className="text_field" id="standard-basic" label="First time password" variant="standard" /></div>
                            <div>
                                <TextField
                                    sx={{width: 250}}
                                    className="text_field"
                                    id="standard-multiline-static"
                                    label="user description"
                                    multiline
                                    rows={4}
                                
                                    value={description}
                                    variant="standard"
                                    onChange={(elm) => Update(elm, setDescription, text)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="submit_form">
                
                    <div className="btn outlined" onClick={createNewUser}>
                        <div>
                            Create new user
                        </div>
                    </div>
                    
                </div>
            </div>
        </Fade>
    )
}