
import { useEffect, useState } from 'react'
import { useLoaderData } from "react-router-dom";
import '../styles/utils.css'
import main, { formatCash } from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CollectedPelletSales from '../elements/collected_pellet_sales'
import CollectedStoveSales from '../elements/collected_stove_sales'

import BankTransfers from '../elements/bank_transfers'

import FilterCashDeposits from '../elements/filter_cash_deposits'
import FilterPelletCashCollection from '../elements/filter_collection'
import FilterStoveCashCollection from '../elements/filter_collection'
import TransferFunds from '../elements/transfer_funds';
import WithDrawalCash from './withdrawal'
import FilterBankWithdrawal from '../elements/filter_bank_withdrawal'


import BankWithDrawal from '../elements/bank_withdrawal'
import EditBalances from '../elements/edit_balances';
import DownloadDoc from '../elements/download_document'
import ViewPelletCollection from '../elements/view_collection';
import ViewCashDeposit from '../elements/view_cash_deposit';
import ViewCashWithdrawal from '../elements/view_cash_withdrawal'


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadCollectedPelletSales from '../elements/download_collected_sales'
import DownloadCollectedStoveSales from '../elements/download_collected_sales'
import DownloadCashDeposits from '../elements/download_cash_deposits'
import DownloadCashWithdrawal from '../elements/download_cash_withdrawal'


import ViewStoveCollection from '../elements/view_collection'

export function loader(){

    return fetch(main.server + `/accounting`).then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            return res.data
        }
        
    })
      
}  

export default function Accounting(){
    
    
    const [ tab, setTab ] = useState(1)

    const [data ] = useState(useLoaderData())
    const [filled, setFilled ] = useState(false)

    const [start, setStart] = useState(false)
    const [setSnack, setAlert,username , , _actions_ ] = useOutletContext()

    const [ dl_collected_pellet_sales, setDlCollectedPelletSales ] = useState(false)
    const [ dl_collected_stove_sales, setDlCollectedStoveSales ] = useState(false)
    const [ dl_cash_deposits, setDlCashDeposits ] = useState(false)
    const [ dl_cash_withdrawal, setDlCashWithdrawal ] = useState(false)

    const [filter_pellet_collection_by_startdate, setFilterPelletCollectionStartDate] = useState(false)
    const [filter_pellet_collection_by_endDate, setFilterPelletCollectionEndDate] = useState(false)
    const [filter_pellet_collection_by_transactor, setFilterPelletCollectionTransactor ] = useState(false)
    const [filter_pellet_collection_by_shop, setFilterPelletCollectionShop ] = useState(false)
   

    const [pellet_collection_dtf, setPelletCollectionDTF] = useState(false)
    const [pelletCollectionStartDate, setPelletCollectionStartDate] = useState(new Date())
    const [pelletCollectionEndDate, setPelletCollectionEndDate] = useState(new Date())
    const [pelletCollectionTransactor, setPelletCollectionTransactor] = useState(['',''])
    const [pelletCollectionShop, setPelletCollectionShop] = useState(['',''])
    
    const [ pellet_deposit_filter_state, setPelletDepositFilterState] = useState(false) 

    const [ open_cash_deposit_filter, setCashDepositFilterState ] = useState(false)


    const [pellet_collection_page, setPelletCollectionPage] = useState(0)
    const [ pellet_collection_total, setPelletCollectionTotal ] = useState(0)
    const [ pellet_collection, setPelletCollections ] = useState([])
    

   
    

    const [ filter_bank_transfers_by_startDate, setFilterBankTransfersByStartDate] = useState(false)
    const [ filter_bank_transfers_by_endDate, setFilterBankTransfersByEndDate ] = useState(false)
    const [ filter_bank_transfers_by_transactor, setFilterBankTransfersByTransactor ] = useState(false)
    const [ filter_bank_transfers_by_source, setFilterBankTransfersBySource ] = useState(false)
   
    const [ bankTransfers_startDate, setBankTransfersStartDate] = useState(new Date())
    const [ bankTransfers_EndDate, setBankTransfersEndDate] = useState(new Date())
    const [ bankTransfersfilterByTransactor, setBankTransfersByTransactor ] = useState(['',''])
    const [ bankTransfersfilterBySource, setBankTransfersBySource ] = useState('')
    


    const [ bankTransfers_dtf, setBankTransfersDTF] = useState(false)



    const [ bank_transfers_page, setBankTransfersPage] = useState(0)
    const [ bankTransfers_total,setBankTransfersTotal ] = useState(0)
    const [ bank_transfers,setBankTransfers ] = useState([])
   



    const [ filter_bank_withdrawals_by_startDate, setFilterBankWithdrawalsByStartDate] = useState(false)
    const [ filter_bank_withdrawals_by_endDate, setFilterBankWithdrawalsByEndDate ] = useState(false)

    const [ filter_bank_withdrawals_by_transactor, setFilterBankWithdrawalsByTransactor ] = useState(false)
   

    const [ bankWithdrawals_startDate, setBankWithdrawalsStartDate] = useState(new Date())
    const [ bankWithdrawals_EndDate, setBankWithdrawalsEndDate] = useState(new Date())
    const [ bankWithdrawalsTransactor, setBankWithdrawalTransactor ] = useState(['',''])

    


    const [ bankWithdrawals_dtf, setBankWithdrawalsDTF] = useState(false)



    const [ bank_withdrawals_page, setBankWithdrawalsPage] = useState(0)
    const [ bankWithdrawals_total,setBankWithdrawalsTotal ] = useState(0)
    const [ bank_withdrawals,setBankWithdrawals ] = useState([])
   
    const [ bankWithdrawals_filterState, setBankWithdrawalsFilterState ] = useState(false)


    const [ downloader_state, setDownloaderState ]  = useState(false)

    



    const [filter_stove_collection_by_startdate, setFilterStoveCollectionStartDate] = useState(false)
    const [filter_stove_collection_by_endDate, setFilterStoveCollectionEndDate] = useState(false)
    const [filter_stove_collection_by_transactor, setFilterStoveCollectionTransactor ] = useState(false)
    const [filter_stove_collection_by_shop, setFilterStoveCollectionShop ] = useState(false)
   

    const [stove_collection_dtf, setStoveCollectionDTF] = useState(false)
    const [stoveCollectionStartDate, setStoveCollectionStartDate] = useState(new Date())
    const [stoveCollectionEndDate, setStoveCollectionEndDate] = useState(new Date())
    const [stoveCollectionTransactor, setStoveCollectionTransactor] = useState(['',''])
    const [stoveCollectionShop, setStoveCollectionShop] = useState(['',''])
 
    const [ open_stove_collection_filter, setStoveCollectionFilterState ] = useState(false)


    const [stove_collection_page, setStoveCollectionPage] = useState(0)
    const [ stove_collection_total, setStoveCollectionTotal ] = useState(0)
    const [ stove_collection, setStoveCollections ] = useState([])








    const [ edit_balances, setEditBalances ] = useState(false)
    const [ edit_pellet_cash_balance, setEditPelletCashBalance ] = useState(false)
    const [ edit_stove_cash_balance, setEditStoveCashBalance ] = useState(false)
    const [ edit_account_balance, setEditAccountBalance] = useState(false)
  
    function handleTabChange(event, new_value){
        if(new_value === 1){
            setPelletCollectionPage(0)
            getPelletCollectionRecords()
        }
        if(new_value === 2){
            setStoveCollectionPage(0)
            getStoveCollectionRecords()
        }
        if(new_value === 3){
            setBankTransfersPage(0)
            getBankTransferRecords()
        }
        if(new_value === 4){
            setBankWithdrawalsPage(0)
            getBankWithdrawalRecords()
        }
        setTab(new_value)
    }

    function setPelletCollectionForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_pellet_collection_by_startdate){
            data['start_date'] = pelletCollectionStartDate.toDateString()
        }
        if(filter_pellet_collection_by_endDate){
            data['end_date'] = pelletCollectionEndDate.toDateString()
        }
        if(filter_pellet_collection_by_transactor){
            data['transactor'] = pelletCollectionTransactor[0]
        }
        if(filter_pellet_collection_by_shop){
            data['shop'] = pelletCollectionShop[1]
        }
        if(filter_pellet_collection_by_startdate && filter_pellet_collection_by_endDate){
            setPelletCollectionDTF(true)
        }
        
        form.append('form', JSON.stringify(data))

        return form
    }

    function setStoveCollectionForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_stove_collection_by_startdate){
            data['start_date'] = stoveCollectionStartDate.toDateString()
        }
        if(filter_stove_collection_by_endDate){
            data['end_date'] = stoveCollectionEndDate.toDateString()
        }
        if(filter_stove_collection_by_transactor){
            data['transactor'] = stoveCollectionTransactor[0]
        }
        if(filter_stove_collection_by_shop){
            data['shop'] = stoveCollectionShop[1]
        }
        if(filter_stove_collection_by_startdate && filter_stove_collection_by_endDate){
            setStoveCollectionDTF(true)
        }
        
        form.append('form', JSON.stringify(data))

        return form
    }
    

    function get_pellet_collections(form){
        fetch(main.server + '/get_pellet_collections',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setPelletCollections( res.data.list )
                setPelletCollectionTotal(res.data.total)
            }
            
        })
    }


    function get_stove_collections(form){
        fetch(main.server + '/get_stove_collections',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setStoveCollections( res.data.list )
                setStoveCollectionTotal(res.data.total)
            }
            
        })
    }



    function getPelletCollectionRecords(){
        const form = setPelletCollectionForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})
        get_pellet_collections(form)
    }

    function getStoveCollectionRecords(){
        const form = setStoveCollectionForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})
        get_stove_collections(form)
    }

    function fetchMorePelletCollectionRecords(){
        
        const form = setPelletCollectionForm( pellet_collection.length > 0 ? pellet_collection.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_pellet_collections(form)
        
    }

    function fetchMoreStoveCollectionRecords(){
        
        const form = setStoveCollectionForm( stove_collection.length > 0 ? stove_collection.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_stove_collections(form)
        
    }





    function getBankTransfersForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_bank_transfers_by_startDate){
            data['start_date'] = pelletCollectionStartDate.toDateString()
        }
        if(filter_bank_transfers_by_endDate){
            data['end_date'] = pelletCollectionEndDate.toDateString()
        }
        if(filter_bank_transfers_by_transactor){
            console.log('transactor', bankTransfersfilterByTransactor)
            data['transactor'] = bankTransfersfilterByTransactor[0]
        }
        if(filter_bank_transfers_by_startDate && filter_bank_transfers_by_endDate){
            setBankTransfersDTF(true)
        }
        if(filter_bank_transfers_by_source){
            data['source'] = bankTransfersfilterBySource
        }
        
        form.append('form', JSON.stringify(data))

        return form
    }



    function getBankWithdrawalForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_bank_withdrawals_by_startDate){
            data['start_date'] = bankWithdrawals_startDate.toDateString()
        }
        if(filter_bank_withdrawals_by_endDate){
            data['end_date'] = bankWithdrawals_EndDate.toDateString()
        }
        if(filter_bank_withdrawals_by_transactor){
            data['transactor'] = bankWithdrawalsTransactor[0]
        }

        if(filter_bank_withdrawals_by_startDate && filter_bank_withdrawals_by_endDate){
            setBankWithdrawalsDTF(true)
        }
        
        form.append('form', JSON.stringify(data))

        return form
    }

    function get_bank_transfers(form, append=false){
        fetch(main.server + '/get_bank_transfers',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(append){
                    setBankTransfers( prev => [ ...prev, ...res.data.list ])
                }else{
                    setBankTransfers( res.data.list )
                }
                
                setBankTransfersTotal(res.data.total)
            }
            
        })
    }

    function get_bank_withdrawals(form, append=false){
        fetch(main.server + '/get_bank_withdrawals',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(append){
                    setBankWithdrawals(prev => [ ...prev, ...res.data.list ])
                }else{
                    setBankWithdrawals( res.data.list )
                }
                
                setBankWithdrawalsTotal(res.data.total)
            }
            
        })
    }

    function getBankTransferRecords(){
        const form = getBankTransfersForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})
        get_bank_transfers(form)
    }

    function getBankWithdrawalRecords(){
        const form = getBankWithdrawalForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})
        get_bank_withdrawals(form)
    }

    function fetchMoreBankTransfers(){
        
        const form = getBankTransfersForm( bank_transfers.length > 0 ? bank_transfers.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_bank_transfers(form, true)
        
    }
    function fetchMoreBankWithdrawals(){
        const form = getBankWithdrawalForm( bank_withdrawals.length > 0 ? bank_withdrawals.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_bank_withdrawals(form, true)
    }
    function open_cash_deposit_filter_(){
        setCashDepositFilterState(true)
    }
    function close_cash_deposit_filter(){
        setCashDepositFilterState(false)
    }
    function set_pellet_collection_page(page){
        setPelletCollectionPage(page)
    }
    function set_stove_collection_page(page){
        setStoveCollectionPage(page)
    }

   
    function set_bank_transfer_page(page){
        setBankTransfersPage(page)
    }




    const [ transfer_store_cash, setTransferCash ] = useState(false)
    const [ pellet_sales_transfer_amount, setPelletSalesTransferAmount ] = useState('') 
    const [ stove_sales_transfer_amount, setStoveSalesTransferAmount ] = useState('') 
   
    const [ transfer_description, setTransferDescription ] = useState('')


    const [ transfer_stove_funds, setTransferStoveFunds ] = useState(false)

   
    const [ stoveFunds_transfer_description, setStoveFundsTransferDescription ] = useState('')



    const pellet_sales_balance = data.pellet_sales_balance
    const stove_sales_balance = data.stove_sales_balance
    const account_balance = data.account_balance

    function TransferStorePelletFunds(){
      
        setTransferCash(true)
    }

    function TransferStoveFunds_(){
      
        setTransferStoveFunds(true)
    }

   
    function TransferPelletFundsToBank(){
        const form = new FormData()

        form.append('amount', pellet_sales_transfer_amount)
        form.append('transactor', username)
        form.append('description', transfer_description)
        

        
        fetch(main.server + '/transfer_pellet_funds_to_bank', {
            method: 'POST',
            body: form,
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                setAlert({
                    message:'funds transfered',
                    type:'success'
                })
                setTimeout(() => window.location.reload(),5000)
            }
            
        })
    }



     
    function TransferStoveFundsToBank(){
        const form = new FormData()

        form.append('amount', stove_sales_transfer_amount)
        form.append('transactor', username)
        form.append('description', stoveFunds_transfer_description)
        

    
        fetch(main.server + '/transfer_stove_funds_to_bank', {
            method: 'POST',
            body: form,
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                setAlert({
                    message:'funds transfered',
                    type:'success'
                })
                setTimeout(() => window.location.reload(),5000)
            }
            
        })
    }


    function closeShopTransfer(){
        setTransferCash(false)
    }

    function closeStoveFundsTransfer(){
        setTransferStoveFunds(false)
    }
    function setShopPelletTransferAmount(amount){
        if((Number(amount) && Number(amount) <= Number(pellet_sales_balance)) || amount === '' ){
            setPelletSalesTransferAmount(amount)
        }
    }

    function setStoveTransferAmount(amount){
        if((Number(amount) && Number(amount) <= Number(stove_sales_balance)) || amount === '' ){
            setStoveSalesTransferAmount(amount)
        }
    }


    const [ withdrawal_cash, setWithDrawalCash ] = useState(false)
    const [ withdrawal_amount, setWithDrawalAmount ] = useState('') 
    const [ withdrawal_description, setWithDrawalDescription ] = useState('')

    const [ pellet_cash_balance_, setPelletCashBalance_ ] = useState(data.pellet_sales_balance)
    const [ stove_cash_balance_, setStoveCashBalance_ ] = useState(data.stove_sales_balance)
    const [ account_balance_, setAccountBalance_ ] = useState(data.account_balance)

    const [ edit_reason, setEditReason ] = useState('')

    function closeCashWithDrawal(){
        setWithDrawalCash(false)
    }
    function WidthDrawalFunds(){
        setWithDrawalCash(true)
    }
    function setwithDrawalAmount(amount){
        if((Number(amount) && Number(amount) <= Number(account_balance)) || amount === '' ){
            setWithDrawalAmount(amount)
        }
    }
    function withDrawalCash(){
        const form = new FormData()

        form.append('amount', withdrawal_amount)
        form.append('transactor', username)
        form.append('description', withdrawal_description)
        

        
        fetch(main.server + '/withdrawal_cash', {
            method: 'POST',
            body: form,
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                setAlert({
                    message:'funds withdrawn',
                    type:'success'
                })
                setTimeout(() => window.location.reload(),5000)
            }
            
        })
    }

    function downloadPelletCollectionRecords() {
      
        setDownloaderState(true)
        

        const form = setPelletCollectionForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_pellet_collection_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
            
        })


        
    }

    function downloadStoveCollectionRecords() {
        console.log('downloading doc........')
        setDownloaderState(true)
        

        const form = setStoveCollectionForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_stove_collection_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
        })


        
    }


    function downloadBankTransferRecords(){
        console.log('downloading doc........')
        setDownloaderState(true)
        

        const form = getBankTransfersForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_bank_transfers',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
        })
    }

    function downloadBankWithDrawalRecords(){
        console.log('downloading doc........')
        setDownloaderState(true)
        

        const form = getBankWithdrawalForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_bank_withdrawals',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
        })
    }

    function open_filter_collected_pellet_sales(){
        setPelletDepositFilterState(true)
    }
    function close_pellet_deposit_filter(){
        setPelletDepositFilterState(false)
    }

    function open_filter_collected_stove_sales(){
        setStoveCollectionFilterState(true)
    }
    function close_stove_deposit_filter(){
        setStoveCollectionFilterState(false)
    }

    function open_filter_bank_withdrawal(){
        setBankWithdrawalsFilterState(true)
    }
    function close_bank_withdrawal_filter(){
        setBankWithdrawalsFilterState(false)
    }

    function getPage(){
        if (tab === 1){
            return(<div>
                <CollectedPelletSales 
                    title="Collected pellet sales"
                    startDate={pelletCollectionStartDate} 
                    endDate={pelletCollectionEndDate}  
                    dtf={pellet_collection_dtf} 
                    page={pellet_collection_page} 
                    setPage={set_pellet_collection_page} 
                    total={pellet_collection_total} 
                    transfers={pellet_collection} 
                    fetch_more={fetchMorePelletCollectionRecords} 
                    filter={open_filter_collected_pellet_sales} 
                    download={() => setDlCollectedPelletSales(true)}
                    view_record={viewPelletCollectionRecord}
                />
            </div>)
        }
        else if(tab === 2){
            return(<div>
                <CollectedStoveSales 
                    title="Collected stove sales"
                    startDate={stoveCollectionStartDate} 
                    endDate={stoveCollectionEndDate}  
                    dtf={stove_collection_dtf} 
                    page={stove_collection_page} 
                    setPage={set_stove_collection_page} 
                    total={stove_collection_total} 
                    transfers={stove_collection} 
                    fetch_more={fetchMoreStoveCollectionRecords} 
                    filter={open_filter_collected_stove_sales} 
                    download={() => setDlCollectedStoveSales(true)}
                    view_record={viewStoveCollectionRecord}
                />
            </div>)
        }
        else if(tab === 3){
            return(<div>
                <BankTransfers
                    title="Cash deposits to bank" 
                    startDate={bankTransfers_startDate} 
                    endDate={bankTransfers_EndDate}  
                    dtf={bankTransfers_dtf} 
                    page={bank_transfers_page} 
                    setPage={set_bank_transfer_page} 
                    total={bankTransfers_total} 
                    transfers={bank_transfers} 
                    fetch_more={fetchMoreBankTransfers} 
                    filter={open_cash_deposit_filter_} 
                    download={() => setDlCashDeposits(true)}
                    view_record={viewCashDepositRecord}
                />
            </div>)
        }else if(tab === 4){
            return(<div>
                <BankWithDrawal
                    title="Cash withdrawal from bank" 
                    startDate={bankTransfers_startDate} 
                    endDate={bankTransfers_EndDate}  
                    dtf={bankWithdrawals_dtf} 
                    page={bank_withdrawals_page} 
                    setPage={setBankWithdrawalsPage} 
                    total={bankWithdrawals_total} 
                    transfers={bank_withdrawals} 
                    fetch_more={fetchMoreBankWithdrawals} 
                    filter={open_filter_bank_withdrawal} 
                    download={() => setDlCashWithdrawal(true)}
                    view_record={viewCashWithdrawalRecord}
                />
            </div>)
        }
    }

    function closeEditBalances(){
        setEditBalances(false)
    }

    function EditGlobalBalances(){
        setEditBalances(true)
    }



    const [ confrim_state, setConfirmState ] = useState(false)
    const [ confrim_function, setConfirmFunction ] = useState([])




    const [ view_pellet_collection, setViewPelletCollectionRecord ] = useState(false)
    const [ view_pellet_collection_data, set_view_pellet_collection_data ] = useState({})

    const [ view_stove_collection, setViewStoveCollectionRecord ] = useState(false)
    const [ view_stove_collection_data, set_view_stove_collection_data ] = useState({})

    function viewPelletCollectionRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewPelletCollectionRecord(true)
        set_view_pellet_collection_data(pellet_collection[key])
        
    }
    function viewStoveCollectionRecord(event){
        const key = event.target.parentElement.dataset.key 
        setViewStoveCollectionRecord(true)
        set_view_stove_collection_data(stove_collection[key])
        
    }
    function closeViewPelletCashCollection(){
        setViewPelletCollectionRecord(false)
    }
    function closeViewStoveCashCollection(){
        setViewStoveCollectionRecord(false)
    }






    function _modify_pellet_cash_collection(form){
        console.log('last step', form)


        fetch(main.server + '/modify_pellet_collection_record',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }



    function _modify_stove_cash_collection(form){
        console.log('last step', form)


        fetch(main.server + '/modify_stove_collection_record',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }





    function _delete_pellet_cash_collection (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_pellet_collection_data.UID}))
  
        fetch( main.server + '/delete_pellet_cash_collection',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }else{
            setSnack({ message: res.message })
            setConfirmState(false)
            setConfirmFunction([])
        
          }
        })
    }

    function _delete_stove_cash_collection (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_stove_collection_data.UID}))
  
        fetch( main.server + '/delete_stove_cash_collection', 
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }else{
            setSnack({ message: res.message })
            setConfirmState(false)
            setConfirmFunction([])
        
          }
        })
    }

    
    const modify_messenger_pellet_cash_collection = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_pellet_cash_collection(form)])
    }

    const delete_messenger_pellet_cash_collection = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_pellet_cash_collection])
    }


    const modify_messenger_stove_cash_transfer = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_stove_cash_collection(form)])
    }

    const delete_messenger_stove_cash_collection = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_stove_cash_collection])
    }
    






    const [ view_cash_deposit, setViewCashDepositRecord ] = useState(false)
    const [ view_cash_deposit_data, set_view_cash_deposit_data ] = useState({})

    function viewCashDepositRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewCashDepositRecord(true)
        set_view_cash_deposit_data(bank_transfers[key])
        
    }
    function closeViewCashDeposit(){
        setViewCashDepositRecord(false)
    }





    function _modifycash_deposit(form){
        console.log('last step', form)


        fetch(main.server + '/modify_deposit_record',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }




    function _delete_cash_deposit (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_cash_deposit_data.UID}))
  
        fetch( main.server + '/delete_cash_deposit',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }

    
    const modify_messenger_cash_deposit = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modifycash_deposit(form)])
    }

    const delete_messenger_cash_deposit = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_cash_deposit])
    }







    const [ view_cash_withdrawal, setViewCashWithdrawalRecord ] = useState(false)
    const [ view_cash_withdrawal_data, set_view_cash_withdrawal_data ] = useState({})

    function viewCashWithdrawalRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewCashWithdrawalRecord(true)
        set_view_cash_withdrawal_data(bank_withdrawals[key])
        
    }
    function closeViewCashWithdrawal(){
        setViewCashWithdrawalRecord(false)
    }



    function _modifycash_withdrawal(form){
        console.log('last step', form)
        
        fetch(main.server + '/modify_withdrawal_record',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }




    function _delete_cash_withdrawal (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_cash_withdrawal_data.UID}))
  
        fetch( main.server + '/delete_cash_withdrawal',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }

    
    const modify_messenger_cash_withdrawal = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modifycash_withdrawal(form)])
    }

    const delete_messenger_cash_withdrawal = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_cash_withdrawal])
    }


    function closeConfirmDialog(){
        setConfirmState(false)
        setConfirmFunction([])
    }

    function accept(){
        confrim_function[0]()
    }


    useEffect(() => {
        if(!start){
          
            setSnack({
                message: 'page loaded',
            })
            getPelletCollectionRecords()

            setStart(true)
        }
        if(! filled){
            
            setFilled(true)
        }
    })
    
    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>Accounting</div>
                </div>

                <div className="centerPage">
                    <div id="acountMain">
                        <div className="container_fixed">
                            <div className="container_heading">Accounting Info</div>
                            <div className="container_main">
                                <div className="info_value_pair">
                                    <div>Pellet sales cash balance:</div>
                                    <div>{formatCash(pellet_sales_balance)}</div>
                                </div>
                                <div className="info_value_pair">
                                    <div>Stove sales cash balance:</div>
                                    <div>{formatCash(stove_sales_balance)}</div>
                                </div>

                                <div className="info_value_pair">
                                    <div>Account balance:</div>
                                    <div>{formatCash(data.account_balance)}</div>
                                </div>
                                { _actions_.indexOf('edit balances') !== -1 ? 
                                    <div className='flex_column padv20'>
                                        <div  className="padv10">
                                            <div className="btn outlined" data-uid={data.UID} onClick={EditGlobalBalances}>
                                                <div>edit balances</div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                : ''}
                            </div>
                        </div>
                        <div className="container_fixed">
                            <div className="container_heading">Transfers</div>
                            <div className="container_main">
                                    { _actions_.indexOf('transfer_money_to_bank') !== -1 ? 
                                        <div>
                                            <div className="padv10">
                                                <div className="btn outlined" data-uid={data.UID} onClick={TransferStorePelletFunds}>
                                                    <div>transfer pellet sales to bank</div>
                                                </div>
                                            </div>
                                            <div className="padv10">
                                                <div className="btn outlined" data-uid={data.UID} onClick={TransferStoveFunds_}>
                                                    <div>transfer stove sales to bank</div>
                                                </div>
                                            </div>
                                        </div>
                                    : ''}
                                    { _actions_.indexOf('withdraw_money_from_bank') !== -1 ? 
                                        <div  className="padv10">
                                            <div className="btn outlined" data-uid={data.UID} onClick={WidthDrawalFunds}>
                                                <div>withdrawal money from account</div>
                                            </div>
                                        </div>
                                    : ''}
                         
                            </div>
                        </div>
                    </div>

                    <div className="tabs">
                        <Tabs className='tabBody_medium' value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab value={1} label="collected pellet sales"  />
                            <Tab value={2} label="collected stove sales"  />
                            <Tab value={3} label="cash deposits to bank"  />
                            <Tab value={4} label="cash withdrawal from bank"  />
                        
                        </Tabs>
                    </div>
                    <div >
                        {getPage()}
                    </div>
     


                  
                </div> 


                <FilterCashDeposits
                    state={open_cash_deposit_filter}

                    transfer_sources={data.transfer_sources}
                    filter_bank_transfer_by_startdate={filter_bank_transfers_by_startDate}
                    filter_bank_transfer_by_endDate={filter_bank_transfers_by_endDate}
                    filter_bank_transfer_by_transactor={filter_bank_transfers_by_transactor}
                    filter_bank_transfers_by_source={filter_bank_transfers_by_source}
                    
                    
                    setFilterBankTransfersBySource={setFilterBankTransfersBySource}
                    setFilterBankTransfersByStartDate={setFilterBankTransfersByStartDate}
                    setFilterBankTransfersByEndDate={setFilterBankTransfersByEndDate}
                    setFilterBankTransfersByTransactor={setFilterBankTransfersByTransactor}
                    
                    bankTransferStartDate={bankTransfers_startDate}
                    setBankTransfersStartDate={setBankTransfersStartDate}

                    bankTransferEndDate={bankTransfers_EndDate}
                    setBankTransfersEndDate={setBankTransfersEndDate}


                    bankTransferTransactor={bankTransfersfilterByTransactor}
                    setBankTransfersByTransactor={setBankTransfersByTransactor} 

                    bankTransfersfilterBySource={bankTransfersfilterBySource}
                    setBankTransfersBySource={setBankTransfersBySource}

                    getBankTransferRecords={getBankTransferRecords}
                    setPage={setBankTransfersPage} 
                    
                    close_cash_deposit_filter={close_cash_deposit_filter}
                    
                />

                <FilterPelletCashCollection
                    state={pellet_deposit_filter_state}
                    
                    filter_by_startDate={filter_pellet_collection_by_startdate}
                    filter_by_endDate={filter_pellet_collection_by_endDate}
                    filter_by_transactor={filter_pellet_collection_by_transactor}
                    filter_by_center={filter_pellet_collection_by_shop}


                    setFilterByCenter={setFilterPelletCollectionShop}
                    setFilterByStartDate={setFilterPelletCollectionStartDate}
                    setFilterByEndDate={setFilterPelletCollectionEndDate}
                    setFilterByTransactor={setFilterPelletCollectionTransactor}

                    StartDate={pelletCollectionStartDate}
                    setStartDate={setPelletCollectionStartDate}

                    EndDate={pelletCollectionEndDate}
                    setEndDate={setPelletCollectionEndDate}


                    Transactor={pelletCollectionTransactor}
                    setTransactor={setPelletCollectionTransactor} 

                    Center={pelletCollectionShop}
                    setCenter={setPelletCollectionShop}

                    getRecords={getPelletCollectionRecords}
                    setPage={setPelletCollectionPage} 

                    close_filter={close_pellet_deposit_filter}
                />

                <FilterStoveCashCollection
                    state={open_stove_collection_filter}
                                        
                    filter_by_startDate={filter_stove_collection_by_startdate}
                    filter_by_endDate={filter_stove_collection_by_endDate}
                    filter_by_transactor={filter_stove_collection_by_transactor}
                    filter_by_center={filter_stove_collection_by_shop}


                    setFilterByCenter={setFilterStoveCollectionShop}
                    setFilterByStartDate={setFilterStoveCollectionStartDate}
                    setFilterByEndDate={setFilterStoveCollectionEndDate}
                    setFilterByTransactor={setFilterStoveCollectionTransactor}

                    StartDate={stoveCollectionStartDate}
                    setStartDate={setStoveCollectionStartDate}

                    EndDate={stoveCollectionEndDate}
                    setEndDate={setStoveCollectionEndDate}


                    Transactor={stoveCollectionTransactor}
                    setTransactor={setStoveCollectionTransactor} 

                    Center={stoveCollectionShop}
                    setCenter={setStoveCollectionShop}

                    getRecords={getStoveCollectionRecords}
                    setPage={setStoveCollectionPage} 

                    close_filter={close_stove_deposit_filter}
                />

                <FilterBankWithdrawal
                    state={bankWithdrawals_filterState}
                                        
                    filter_by_startDate={filter_bank_withdrawals_by_startDate}
                    filter_by_endDate={filter_bank_withdrawals_by_endDate}
                    filter_by_transactor={filter_bank_withdrawals_by_transactor}
                 

                    setFilterByStartDate={setFilterBankWithdrawalsByStartDate}
                    setFilterByEndDate={setFilterBankWithdrawalsByEndDate}
                    setFilterByTransactor={setFilterBankWithdrawalsByTransactor}

                    StartDate={bankWithdrawals_startDate}
                    setStartDate={setBankWithdrawalsStartDate}

                    EndDate={bankWithdrawals_EndDate}
                    setEndDate={setBankWithdrawalsEndDate}


                    Transactor={bankWithdrawalsTransactor}
                    setTransactor={setBankWithdrawalTransactor} 


                    getRecords={getBankWithdrawalRecords}
                    setPage={setBankWithdrawalsPage} 

                    close_filter={close_bank_withdrawal_filter}
                />


                <DownloadDoc 
                    state={downloader_state} 
                    setDownloaderState={setDownloaderState}
                    
                />
               
                <TransferFunds 
                    state={transfer_store_cash}
                    title="Transfer pellet sales balance to bank"
                    action="Transfer Funds"
                    balance={pellet_sales_balance}

                    amount={pellet_sales_transfer_amount}
                    setAmount={setShopPelletTransferAmount}

                  

                    description={transfer_description}
                    setDescription={setTransferDescription}
                    close={closeShopTransfer}
                    accept={TransferPelletFundsToBank}
               />


                <TransferFunds
                    state={transfer_stove_funds}
                    title="Transfer stove sales balance to bank"
                    action="Transfer Funds"
                    balance={stove_sales_balance}

                
                    amount={stove_sales_transfer_amount}
                    setAmount={setStoveTransferAmount}

                    description={stoveFunds_transfer_description}
                    setDescription={setStoveFundsTransferDescription}
                    close={closeStoveFundsTransfer}
                    accept={TransferStoveFundsToBank}
               />

                <WithDrawalCash 
                    state={withdrawal_cash}
                    title="Withdrawal cash from bank"
                    balance={account_balance}
                    amount={withdrawal_amount}
                    setAmount={setwithDrawalAmount}
                    description={withdrawal_description}
                    setDescription={setWithDrawalDescription}
                    close={closeCashWithDrawal}
                    accept={withDrawalCash}
               />
                
                <DownloadCollectedPelletSales
                    state={dl_collected_pellet_sales}
                    setDownloaderState={setDlCollectedPelletSales}
                    download={downloadPelletCollectionRecords}

                    filter_by_startDate={filter_pellet_collection_by_startdate}
                    filter_by_endDate={filter_pellet_collection_by_endDate}
                    filter_by_transactor={filter_pellet_collection_by_transactor}
                    filter_by_center={filter_pellet_collection_by_shop}


                    setFilterByCenter={setFilterPelletCollectionShop}
                    setFilterByStartDate={setFilterPelletCollectionStartDate}
                    setFilterByEndDate={setFilterPelletCollectionEndDate}
                    setFilterByTransactor={setFilterPelletCollectionTransactor}

                    StartDate={pelletCollectionStartDate}
                    setStartDate={setPelletCollectionStartDate}

                    EndDate={pelletCollectionEndDate}
                    setEndDate={setPelletCollectionEndDate}


                    Transactor={pelletCollectionTransactor}
                    setTransactor={setPelletCollectionTransactor} 

                    Center={pelletCollectionShop}
                    setCenter={setPelletCollectionShop}
                />

                <DownloadCollectedStoveSales
                    state={dl_collected_stove_sales}
                    setDownloaderState={setDlCollectedStoveSales}
                    download={downloadStoveCollectionRecords}

                    filter_by_startDate={filter_stove_collection_by_startdate}
                    filter_by_endDate={filter_stove_collection_by_endDate}
                    filter_by_transactor={filter_stove_collection_by_transactor}
                    filter_by_center={filter_stove_collection_by_shop}


                    setFilterByCenter={setFilterStoveCollectionShop}
                    setFilterByStartDate={setFilterStoveCollectionStartDate}
                    setFilterByEndDate={setFilterStoveCollectionEndDate}
                    setFilterByTransactor={setFilterStoveCollectionTransactor}

                    StartDate={stoveCollectionStartDate}
                    setStartDate={setStoveCollectionStartDate}

                    EndDate={stoveCollectionEndDate}
                    setEndDate={setStoveCollectionEndDate}


                    Transactor={stoveCollectionTransactor}
                    setTransactor={setStoveCollectionTransactor} 

                    Center={stoveCollectionShop}
                    setCenter={setStoveCollectionShop}
                   
                />

                <DownloadCashDeposits
                    state={dl_cash_deposits}
                    setDownloaderState={setDlCashDeposits}
                    download={downloadBankTransferRecords}

                    transfer_sources={data.transfer_sources}
                    filter_bank_transfer_by_startdate={filter_bank_transfers_by_startDate}
                    filter_bank_transfer_by_endDate={filter_bank_transfers_by_endDate}
                    filter_bank_transfer_by_transactor={filter_bank_transfers_by_transactor}
                    filter_bank_transfers_by_source={filter_bank_transfers_by_source}
                    
                    
                    setFilterBankTransfersBySource={setFilterBankTransfersBySource}
                    setFilterBankTransfersByStartDate={setFilterBankTransfersByStartDate}
                    setFilterBankTransfersByEndDate={setFilterBankTransfersByEndDate}
                    setFilterBankTransfersByTransactor={setFilterBankTransfersByTransactor}
                    
                    bankTransferStartDate={bankTransfers_startDate}
                    setBankTransfersStartDate={setBankTransfersStartDate}

                    bankTransferEndDate={bankTransfers_EndDate}
                    setBankTransfersEndDate={setBankTransfersEndDate}


                    bankTransferTransactor={bankTransfersfilterByTransactor}
                    setBankTransfersByTransactor={setBankTransfersByTransactor} 

                    bankTransfersfilterBySource={bankTransfersfilterBySource}
                    setBankTransfersBySource={setBankTransfersBySource}
                />

                <DownloadCashWithdrawal
                    state={dl_cash_withdrawal}
                    setDownloaderState={setDlCashWithdrawal}
                    download={downloadBankWithDrawalRecords}


                    filter_by_startDate={filter_bank_withdrawals_by_startDate}
                    filter_by_endDate={filter_bank_withdrawals_by_endDate}
                    filter_by_transactor={filter_bank_withdrawals_by_transactor}
                 

                    setFilterByStartDate={setFilterBankWithdrawalsByStartDate}
                    setFilterByEndDate={setFilterBankWithdrawalsByEndDate}
                    setFilterByTransactor={setFilterBankWithdrawalsByTransactor}

                    StartDate={bankWithdrawals_startDate}
                    setStartDate={setBankWithdrawalsStartDate}

                    EndDate={bankWithdrawals_EndDate}
                    setEndDate={setBankWithdrawalsEndDate}


                    Transactor={bankWithdrawalsTransactor}
                    setTransactor={setBankWithdrawalTransactor} 


                />

                <EditBalances
                    state={edit_balances}
                    close={closeEditBalances}
                    pellet_sales_balance={pellet_sales_balance}
                    stove_sales_balance={stove_sales_balance}
                    account_balance={account_balance}


                    edit_pellet_cash_balance={edit_pellet_cash_balance}
                    edit_stove_cash_balance={edit_stove_cash_balance}
                    edit_account_balance={edit_account_balance}
                    setEditPelletCashBalance={setEditPelletCashBalance}
                    setEditAccountBalance={setEditAccountBalance}
                    setEditStoveCashBalance={setEditStoveCashBalance}

                    pellet_cash_balance_={pellet_cash_balance_}
                    setPelletCashBalance_ ={setPelletCashBalance_ }
                    stove_cash_balance_={stove_cash_balance_}
                    setStoveCashBalance_={setStoveCashBalance_}
                    account_balance_={account_balance_}
                    setAccountBalance_={setAccountBalance_}

                    reason={edit_reason}
                    setWithDrawalReason={setEditReason}

                    setSnack={setSnack}
                    setAlert={setAlert}
                
                />

                <ViewPelletCollection
                    state={view_pellet_collection}
                    title="Pellet cash collection record"
                    close={closeViewPelletCashCollection}
                    data={view_pellet_collection_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_pellet_cash_collection}
                    delete_messenger={delete_messenger_pellet_cash_collection}
                />

                <ViewStoveCollection 
                    state={view_stove_collection}
                    title="Stove cash collection record"
                    close={closeViewStoveCashCollection}
                    data={view_stove_collection_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_stove_cash_transfer}
                    delete_messenger={delete_messenger_stove_cash_collection}
                />


                <ViewCashDeposit
                    state={view_cash_deposit}
                    close={closeViewCashDeposit}
                    data={view_cash_deposit_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_cash_deposit}
                    delete_messenger={delete_messenger_cash_deposit}
                />

                <ViewCashWithdrawal
                    state={view_cash_withdrawal}
                    close={closeViewCashWithdrawal}
                    data={view_cash_withdrawal_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_cash_withdrawal}
                    delete_messenger={delete_messenger_cash_withdrawal}
                />


               

                <Dialog
                    open={confrim_state}
                    onClose={closeConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Action"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to perform this action ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog}>DECLINE</Button>
                        <Button onClick={accept}>
                            ACCEPT
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </Fade>
    )
}