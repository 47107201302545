import {useState} from 'react'
import main, {  _DateTime } from '../resources/code/utils'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../styles/utils.css'
import '../styles/users.css'
import { formatCash } from '../resources/code/utils'
import DialogTitle from '@mui/material/DialogTitle';
import { Paper } from '@mui/material';
import Checkbox from '@mui/material/Checkbox'



export default function TransferPellets(props){

    
    const [shop_list, setShopList] = useState([])
    const [ workshop_list, setWorkshopList ] = useState([])
    const [ destination, setDestination ] = useState(true)
    const [ compel, setCompel ] = useState(false)

    function handleAmount(event){   
        const amount = event.target.value
        if( amount === '' || Number(amount)){
            props.setAmount(amount)
            if(Number(amount) > Number(props.balance)){
                setCompel(true)
            }else{
                setCompel(false)
            }
        }
        
    }
    function updateDescription(event){
        props.setDescription(event.target.value)
    }
    function accept(){
        if((Number(props.amount) > Number(props.balance) && props.description.length > 0) || Number(props.balance) <= Number(props.balance)){
            props.accept()
        }else{
            setCompel(true)
        }
        
    }

    function handleClicks(event){
       
        if(!event.nativeEvent.srcElement.classList.contains('searchSys')){
            setShopList([])
        }
        
    }

    function selectCenter(name, uid){
        props.setTransferCenter(name)
        props.setTransferCenterUID(uid)
       
        setShopList([])
    }


    function selectWorkshop(name, uid){
        props.setTransferWorkshop(name)
        props.setTransferWorkshopUID(uid)
       
        setWorkshopList([])
    }
    
    function searchForShop(elm){
        props.setTransferCenter(elm.target.value)
       
        fetch(main.server + `/shops?q=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setShopList(res.data.shops)
            }
        })
    }

    function searchForWorkshop(elm){
        props.setTransferWorkshop(elm.target.value)
       
        fetch(main.server + `/workshops?q=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setWorkshopList(res.data.workshops)
            }
        })
    }
    
    function updateDescription(event){
        props.setDescription(event.target.value)
    }

    const ToggleDestination = () => {
        setDestination(prev => !prev)
        
        props.setTransferWorkshop('')
        props.setTransferWorkshopUID('')
       
        setWorkshopList([])

        props.setTransferCenter('')
        props.setTransferCenterUID('')
       
        setShopList([])
    }
    return(
        <Dialog
            open={props.state}
            onClose={props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <div className='pad10 transferPelletMain' onClick={handleClicks}>
                    <div className="info_value_pair pad10">
                        <div>Balanace: </div>
                        <div>{props.balance} kg</div>
                    </div>
                    <div className="pad10 padv20"><TextField value={props.amount} onChange={handleAmount} sx={{width: 250}} className="text_field" id="standard-basic" label="Amount" variant="standard" /></div>
                    

                    <div className='padv20'>
                        <Checkbox checked={destination} onChange={ToggleDestination}/> send to center
                    </div>
                    <div className="pad10 padv20"><TextField disabled={!destination} value={props.transfer_center} onChange={searchForShop} sx={{width: 250}} className="text_field" id="standard-basic" label="Center" variant="standard" /></div>


                    <div className="filterSearchRes">
                        {shop_list.length > 0 ?
                            <Paper elevation={1} className="searchRBody searchSys">
                                {shop_list.map((elm,ind) => (
                                    <div className="generalSearchResult searchSys"  onClick={() => selectCenter(elm.name, elm.UID)} key={ind}>
                                        {elm.name}
                                    </div>
                                ))}
                                
                            </Paper>
                        :''}
                
                    </div> 

                    
                    <div className='padv20'>
                        <Checkbox checked={!destination} onChange={ToggleDestination}/> send to workshop
                    </div>
                    <div className="pad10"><TextField disabled={destination} value={props.transfer_workshop} onChange={searchForWorkshop} sx={{width: 250}} className="text_field" id="standard-basic" label="Workshop" variant="standard" /></div>
                   

                    <div className="filterSearchRes">
                        {workshop_list.length > 0 ?
                            <Paper elevation={1} className="searchRBody searchSys">
                                {workshop_list.map((elm,ind) => (
                                    <div className="generalSearchResult searchSys"  onClick={() => selectWorkshop(elm.name, elm.UID)} key={ind}>
                                        {elm.name}
                                    </div>
                                ))}
                                
                            </Paper>
                        :''}
                
                    </div> 

                    <div className="pad10">
                        <TextField
                            sx={{width: 250}}
                            className="text_field"
                            id="standard-multiline-static"
                            label="Transfer description"
                            multiline
                            rows={3}
                        
                            value={props.description}
                            variant="standard"
                            onChange={updateDescription}
                            error={compel && !props.description}
                            helperText={ compel && !props.description ? "you must provide a reason for overdrawing pellets reserves": ''}
                        />
                    </div>
                    
                   
                        
                </div>
            </DialogContent>
            <DialogActions>
              
                <Button onClick={accept}>
                    {props.action}
                </Button>
            </DialogActions>
        </Dialog>
    )
}