import { useEffect, useState, useRef } from 'react'
import TextField from '@mui/material/TextField';
import '../styles/utils.css'
import UploadIcon from '@mui/icons-material/Upload';
import IDImage from '../resources/icons/id.png'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import main, { get_file_size, check_phone_number } from '../resources/code/utils'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";


export default function NewClientForm(){
    
    const [start, setStart] = useState(false)

    const [name, setName] = useState('')
    const [middle_name, setMiddlename] = useState('')
    const [surname, setSurname] = useState('')


    const [phone_number1, setPhoneNumber1] = useState('')
    const [phone_number2, setPhoneNumber2] = useState('')
    const [description, setDescription ] = useState('')

    const [geo_coords, setGeoCoords] = useState('')
    
    const [ IdImage, setIDImage ] = useState('')
    const [ IDImageFile, setIDImageFile ] = useState(false)
    const [ location, setLocation ] = useState('')
    const [ region, setRegion ] = useState('')
    const [ image_name, setImageName ] = useState('image name')
    const [ image_size, setImageSize ] = useState('0 B')
    const [ setSnack, setAlert, username ] = useOutletContext()

    const [id_number, setIdNumber] = useState('')
    const [ basic, setBasic ] = useState({regions:[], id_types: []})

    const phone_number = 'phone_number'
    const text = 'text'
    const number = 'number'

    const profile_image_input = useRef()

    const [id_type, setIdType] = useState('')

    const handleIDChange = (event: SelectChangeEvent) => {
      setIdType( event.target.value )
    }

    const handleRegionChange =  (event: SelectChangeEvent) => {
        setRegion( event.target.value )
    }

    
    function Update(elm, updater, type){
        const value = elm.target.value
        
        if(type === phone_number){
            if( (Number(value) || value === '' || value === '0') && value.length <= 10 ){
                updater(value)
            }    
        }if(type === number){

            if(Number(value) || value === '' || value === '0'){
                updater(value)
            }    
        }
        else if (type === text){
            updater(value)
        }
    }

 
    function openImageFile(){
        console.log(profile_image_input)
        profile_image_input.current.click()
    }
    function HandleImageUpload(elm){
        const file = elm.target.files[0]
        setImageSize(get_file_size(Number(file.size)))
        setIDImageFile(file)
        setImageName(file.name)
        const reader = new FileReader()
        reader.onload = function() {
            setIDImage(reader.result);
        }
        reader.readAsDataURL(file)
    }

    const required_client_inputs = [
    
        {input:name},
       
        {input: phone_number1},
       

        {input: location},
        {input: region},
       
      
    ]
    
    function validate_new_clientForm(){
        let incomplete = false
        for(const input of required_client_inputs){
            if(!input.input){
                incomplete = true
            }
        }
        if(incomplete){
            setSnack({
                message:'incomplete form',
                type:'error'
            })
        }
        return incomplete
    }

    
    function createNewClient(){

        if(!validate_new_clientForm()){
            const form = new FormData()
            
            const f = {
                name:name,
                
                phone_number1:phone_number1,
                location: location,
                region: region,
               
                created_by: username,
               
            }
            
           
            if(phone_number2){
                f['phone_number2'] = phone_number2
            }
            if(id_type){
                f['id_type'] = id_type
            }
            if(id_number){
                f['id_number'] = id_number
            }
            if(geo_coords){
                f['geo_coords'] = geo_coords
            }   
            if(description){
                f['description'] = description
            }
        




            form.append('form', JSON.stringify(f))
            if(IDImageFile){
                form.append('id_image', IDImageFile)
            }


            fetch(main.server + '/create_new_client', {
                method: 'POST',
                body: form
            }).then(response => response.json()).then(result => {
                console.log(result)
                if(result.request_status){
                    setAlert({
                        message:'client created',
                        type:'success'
                    })
                    setTimeout(() => window.location.assign('/clients'),5000)
                }else{
                    setSnack({
                        message: result.message
                    })
                }
                
            })
        }
        
    }
    

    function fetchMain(){
        console.log('fetch main')
        fetch(main.server + '/basic').then(resp => resp.json()).then(result =>{
            setBasic(result.data)
        })
    }

    useEffect(() => {
        if(!start){
            fetchMain()
            setSnack({
                message: 'page loaded',
            })

            setStart(true)
        }
    })
    
    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>New client form</div>
                </div>
                <div className="form_main form_centered">
                    <div className="form_section">
                    
                        <div className="form_section_main center_form_section">
                           
                            <div className="ProfileImage">
                                <div className="clientIdImageContainer">
                                    {IdImage ? <img src={IdImage} className="clientIdImage" alt="client id image"/>: 
                                        <img className="IdImagePlaceholder" src={IDImage} alt="client id image"/>
                                        
                                    }
                                </div>
                                <div className="profileImageName">{image_name}</div>
                                <div className="profileImageSize">{image_size}</div>
                                
                            </div>
                            <div className="btn outlined" onClick={openImageFile}>
                                <div>
                                    <UploadIcon />
                                </div>
                                <div>
                                    upload ID image
                                </div>
                            </div>
                            <input ref={profile_image_input} onChange={HandleImageUpload} className="hidden_file_input" type="file"/>
                            
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">ID type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={id_type}
                                    label="ID type"
                                    onChange={handleIDChange}
                                    variant="standard"
                                    sx={{width: 250}}
                                >   
                                    {basic.id_types.map((elm, ind) => (
                                        <MenuItem value={elm.name} key={ind}>{elm.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div><TextField value={id_number} onChange={(elm) => Update(elm, setIdNumber, number)} sx={{width: 250}} className="text_field" id="standard-basic" label="ID number" variant="standard" /></div>
                        
                        </div>
                    </div>
                    <div className="form_section">
                        
                        <div className="form_section_main">
                            <div><TextField value={name} onChange={(elm) => Update(elm, setName, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Name" variant="standard" /></div>
                            <div><TextField error={!check_phone_number(phone_number1)} helperText={!check_phone_number(phone_number1) ? 'invalid phone number':''}  value={phone_number1} onChange={(elm) => Update(elm, setPhoneNumber1, phone_number)} sx={{width: 250}} className="text_field" id="standard-basic" label="Phone number 1" variant="standard" /></div>
                            <div><TextField error={!check_phone_number(phone_number2)} helperText={!check_phone_number(phone_number2) ? 'invalid phone number':''}  value={phone_number2} onChange={(elm) => Update(elm, setPhoneNumber2, phone_number)} sx={{width: 250}} className="text_field" id="standard-basic" label="Phone number 2" variant="standard" /></div>
                            
                            
                        </div>
                    </div>
                    <div className="form_section">
                        <div className="form_section_main">
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={region}
                                    label="ID type"
                                    onChange={handleRegionChange}
                                    variant="standard"
                                >   
                                    {basic.regions.map((elm, ind) => (
                                        <MenuItem value={elm.name} key={ind}>{elm.name}</MenuItem>
                                    ))}
                                    
                                
                                </Select>
                            </FormControl>
                            
                          
                            <div><TextField value={location} onChange={(elm) => Update(elm, setLocation, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Location" variant="standard" /></div>
                            <div><TextField value={geo_coords} onChange={(elm) => Update(elm, setGeoCoords, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Geo coordinates" variant="standard" /></div>
                            
                            <div>
                                <TextField
                                    sx={{width: 250}}
                                    className="text_field"
                                    id="standard-multiline-static"
                                    label="user description"
                                    multiline
                                    rows={4}
                                
                                    value={description}
                                    variant="standard"
                                    onChange={(elm) => Update(elm, setDescription, text)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="submit_form">
                
                    <div className="btn outlined" onClick={createNewClient}>
                        <div>
                            Create new client
                        </div>
                    </div>
                    
                </div>
            </div>
        </Fade>
    )
}