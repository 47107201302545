import React, { useEffect, useRef } from 'react'
import {useState} from 'react'
import { Outlet } from 'react-router-dom'
import './styles/main.css'
import { useTheme } from '@mui/material/styles';
import Navpanel from './elements/nav_panel'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import main from './resources/code/utils'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper';
import Login from './pages/login'
import Divider from '@mui/material/Divider';
import ProfileImage from './resources/icons/profile.png'
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { render } from '@testing-library/react';
import Logo from  './resources/images/logo.png'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})


export default function Main(){
    const theme = useTheme();
    const [ alert, set_alert ] = useState({message: ''})
    const [start, setStart] = useState(false)
    const [ user, setUser ]  = useState({})
    const [ username, setUserName ] = useState(localStorage.getItem('username'))
    const [ user_uid, setUserUID ] = useState(localStorage.getItem('userUID'))
    const [ _actions_, setActions ]  = useState([])
    const [ nav_state, setNavState ] = useState(true)
    const [ menuBtnState, setMenuBtnState ] = useState(false)
    const [ not_allowed, setNotAllowed ] = useState(false)

    const down_md = useMediaQuery(theme.breakpoints.down('md'))

    
    const account_block_ref = useRef()


    const timeout = 5000


    const [ snack, set_snack ] = useState({
        message: ''
    })


    function setSnack(data){
        set_snack(data)
        setTimeout(() => { handleSnackClose() }, timeout)
    }

    function setAlert(data){
        set_alert(data)
        setTimeout(() => { handleAlertClosure() }, timeout)
    }



    function handleAlertClosure(){
        setAlert({message:''})
    }
    function handleSnackClose(){
        setSnack({message:''})
    }


    function setActiveUser(data){

        localStorage.setItem('username', data.username)
        localStorage.setItem('userUID', data.UID)
        setUser(data)
        setUserUID(data.UID)
        setUserName(data.username)
        setActions(data.actions)

        
    }

    function getActiveUser(){
        localStorage.getItem('user')
    }

    function logout(){
        const form = new FormData()
       
        form.append('UID', user_uid)
        fetch(main.server + `/logout`,{
            method:"POST",
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
               
                setUser({})
                setUserName(false)
                setUserUID(false)
                localStorage.removeItem('username')
                localStorage.removeItem('userUID')
                closeAccountOptions()

                
            }
        })
    }
    




    const [ account_options, setAccountOptions ] = useState(false)

    const openAccountOptions = () => {
        setAccountOptions(true)
    };
    const closeAccountOptions = () => {
        setAccountOptions(false)
    };

   
    function viewAccount(){
        window.location.assign(`/account/${user_uid}`)
    }

    function check_login_status(){
        if(user_uid){
            const form = new FormData()
            form.append('UID', user_uid)
            fetch(main.server + `/check_login_status`,{
                method:"POST",
                body: form
            }).then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                   
                    setActiveUser(res.data)
                }else{
                    setUserName(false)
                    setUserUID(false)
                    localStorage.removeItem('username')
                    localStorage.removeItem('userUID')
                }
                
            })
        }
    }

    
    function toggleMenu(){
        setNavState(prev => !prev)
    }
    function openMenu(){
        setNavState(true)
    }function closeMenu(){
        setNavState(false)
    }
    function senseTouch(event){
        const id = event.nativeEvent.srcElement.id
        if(id  !== 'open_menu' && down_md){
            closeMenu()
        } 
    }


   


    function render(){
        if(user_uid && !not_allowed){ 
            return( <div id="main" >
                
                <Navpanel state={nav_state}  actions={_actions_} setNotAllowed={setNotAllowed} down_md={down_md} closeMenu={closeMenu} />


                <Menu
                    id="basic-menu"
                    anchorEl={account_block_ref.current}
                    open={account_options}
                    onClose={closeAccountOptions}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
        
                
                >
                    <div  className="profileSettings">
                    
                        <MenuItem onClick={viewAccount}>My account</MenuItem>
                        <Divider />
                        <MenuItem onClick={logout}>Logout</MenuItem>
                    </div>
                
                </Menu>

                
                <div id="main_render_space" onClick={senseTouch}>
                    <div className="navBarContainer">
                      
                            <div id="navBar">
                                <div>
                                    
                                    { menuBtnState ?  <div id="nav_menu_btn" >
                                        <IconButton id="open_menu" aria-label="menu" onClick={toggleMenu}>
                                            <MenuIcon sx={{ pointerEvents:'none' }} />
                                        </IconButton>
                                        
                                    </div>: '' }
                                
                                </div>
                                
                                
                                <div id="userNavPanel" onClick={openAccountOptions} ref={account_block_ref}>
                                    <div id="userImageNavBar">
                                        <img src={ user.profile_image ? main.server + `${user.profile_image}` : ProfileImage } alt="user image"/>
                                    </div>
                                    <div>{username}</div>
                                </div>
                               
                            </div>

                       
                    </div>
                    
                    <div id="pageOutlet">
                        <Outlet context={[setSnack, setAlert, username, user_uid, _actions_ , setNotAllowed ]}/>
                    </div>
                </div>
            </div> )
        }
        else if( not_allowed ){
            return(
                <div id="error_page">
                    <div id="error_page_logo">
                        <img src={Logo} alt="Dorcas organization logo" />
                    </div>
                    <div className="error_type padv10">
                       403 Error
                    </div>
                    <div id="not_allowed_text">
                        You are not allowed to view this page.
                    </div>
                </div>
            )
        }
        else if( !user_uid ){
            return(
                <Login setUser={setActiveUser} getUser={getActiveUser} logout={logout}/>  
            )
        }
                
                
               
    }

    useEffect(() =>{
       
        if(!start){
            check_login_status()
          

            if(down_md){
                setNavState(false)
                setMenuBtnState(true)

            }else {
                setNavState(true)
                setMenuBtnState(false)
            }

         
            setStart(true)
        }
        /*
        if( user_uid && sysTimeout === false){
            setSystemTimeout(
                setTimeout( logout, _system_timeout_)
            )
        }*/
        
    })
    

    return(
        <div >
            { render()  }
            <Snackbar open={alert.message !== ''} autoHideDuration={6000} onClose={handleAlertClosure}>
                <Alert onClose={handleAlertClosure} severity={alert.type} sx={{ width: '100%' }}>
                    { alert.message }
                </Alert>
            </Snackbar>
            <Snackbar
                className="pushSnack"
                anchorOrigin={{vertical:'top', horizontal: 'center' }}
                open={snack.message !== ''}
                onClose={handleSnackClose}
                message={snack.message}
               
            />

            
          
        </div>
    )
}