
import { useEffect, useState } from 'react'
import { useLoaderData } from "react-router-dom";
import '../styles/utils.css'
import main from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import StoveSalesTable from '../tables/stove_sales_table'
import HeaterSalesTable from '../tables/heater_sales_table'
import PelletSalesTable from '../tables/pellet_sales_table'

import FilterStoveRecords from '../elements/filter_stove_records'
import FilterPelletSalesRecords from '../elements/filter_pellet_sales_records'
import FilterPelletDebtRecords from '../filters/filter_pellet_debt_records'
import DownloadDoc from '../elements/download_document'
import ViewStoveSale from '../elements/view_stove_sale_record'
import ViewPelletSale from '../elements/view_pellet_sale_record'
import ViewPelletDebt from  '../elements/view_pellet_debt_record'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PelletProductionTable from '../tables/pellet_production_table'
import FilterPelletProduction from '../elements/filter_pellet_production_records'
import FilterStovePayments from '../elements/filter_stove_payments'
import ViewPelletProductionRecord from '../elements/view_pellet_production_record';
import ViewStovePaymentRecord from '../elements/view_stovePayment_records';
import ViewHeaterSale from '../elements/view_heater_sale_record'

import FilterHeaterSales from '../elements/filter_heater_records'
import StovePaymentTable from '../tables/stove_payments_table'
import StoveDebtsTable from '../tables/stove_debts_table'


import DownloadStoveSales from '../elements/download_stove_records'
import DownloadHeaterRecords from '../elements/download_heater_records'
import DownloadStovePaymentRecords from '../elements/download_stove_payment_records'
import DownloadPelletSales from '../elements/download_pellet_sales'
import DownloadPelletProduction from '../elements/download_pellet_production'


import PelletDebtsTable from '../tables/pellet_debts_table'
import FilterStoveDebts from '../filters/filter_stove_debts'

import DownloadStoveDebts from '../downloaders/download_stove_debt_records'
import DownloadPelletDebts from '../downloaders/download_pellet_debts'

import ViewStoveDebt from '../elements/view_stove_debt_record'


/*
export function loader(){


    const data = {}
    const form = new FormData()

    data['start'] = 0
    
    form.append('form', JSON.stringify(data))




    return fetch(main.server + '/get_stove_sales', {
        method: 'POST',
        body: form
    }).then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}*/

export default function Records(){
    const [stove_sales_data, setStoveSalesData ] = useState({})

    const [setSnack, setAlert, , user_uid, _actions_ , setNotAllowed  ] = useOutletContext()
    const [start, setStart] = useState(false)
    const [ tab, setTab ] = useState(1)

    const [ payment_plans, setPaymentPlans ] = useState([])

    const [ dl_stove_sales, setDlStoveSales ] = useState(false)
    const [ dl_heater_sales, setDlHeaterSales ] = useState(false)
    const [ dl_stove_payments, setDlStovePayments ] = useState(false)
    const [ dl_pellet_sales, setDlPelletSales ] = useState(false)
    const [ dl_pellet_debts, setDlPelletDebts ] = useState(false)
    const [ dl_pellet_production, setDlPelletProduction ] = useState(false)
    const [ dl_stove_debts, setDlStoveDebts ] = useState(false)


    const [ downloader_state, setDownloaderState ]  = useState(false)

    const [filter_stove_sales_by_startdate, setFilterStoveSalesByStartDate] = useState(false)
    const [filter_stove_sales_by_endDate, setFilterStoveSalesByEndDate] = useState(false)
    const [filter_stove_sales_by_customer, setFilterStoveSalesByCustomer ] = useState(false)
    const [filter_stove_sales_by_paymentplan, setFilterStoveSalesByPaymentPlan] = useState(false)
    const [ filter_stove_sales_by_serialnumber, setFilterStoveSalesBySerialNumber ] = useState(false)
    const [ filter_stove_sales_by_center,  setFilterStoveSalesByCenter ] = useState(false)
   
    const [dtf, setDTF] = useState(false)
    const [stoveSales_startDate, setStoveSalesstartDate] = useState(new Date())
    const [stoveSales_endDate, setStoveSalesEndDate] = useState(new Date())
    const [stoveSales_customer, setStoveSalesCustomer] = useState([])
    const [stoveSales_paymentPlan, setStoveSalesPaymentPlan ] = useState('select')
    const [stoveSales_stoveID, setStoveSalesStoveID ] = useState('')
    const [ stoveSales_center, setStoveSalesCenter] = useState([])
    const [ open_stoveRecords_filter, setStoveRecordsFilterState ] = useState(false)


    const [stove_sales_page, setStoveSalesPage] = useState(0)
    const [ stoveSalesTotal, setStoveSalesTotal ] = useState({price:'', amount_paid:''})
    const [ stove_sales, setStoveSales ] = useState([])



 


    const [ filter_pellet_sales_by_startDate, setFilterPelletSalesByStartDate] = useState(false)
    const [ filter_pellet_sales_by_endDate, setFilterPelletSalesByEndDate ] = useState(false)
    const [ filter_pellet_sales_by_shop, setFilterPelletSalesByShop ] = useState(false)
    const [ filter_pellet_sales_by_recorder, setFilterPelletSalesByRecorder ] = useState(false)
   

    const [ pelletSales_startDate, setPelletSalesStartDate] = useState(new Date())
    const [ pelletSales_endDate, setPelletSalesEndDate] = useState(new Date())
    const [ pelletfilterByRecorder, setFilterPelletsByRecorder ] = useState('')
    const [ pelletfilterByShop, setFilterPelletsByShop ] = useState('')
   


    const [ pellet_dtf, setPelletDTF] = useState(false)



    const [ pellet_sales_page, setPelletSalesPage] = useState(0)
    const [ pellet_total,setPelletTotal ] = useState({})
    const [ pellet_sales,setPelletSales ] = useState([])
   
    const [ pellet_filterState, setPelletFilterState ] = useState(false)







    const [ filter_pellet_debts_by_startDate, setFilterPelletDebtsByStartDate] = useState(false)
    const [ filter_pellet_debts_by_endDate, setFilterPelletDebtsByEndDate ] = useState(false)
    const [ filter_pellet_debts_by_shop, setFilterPelletDebtsByShop ] = useState(false)
    const [ filter_pellet_debts_by_client, setFilterPelletDebtsByClient ] = useState(false)
   

    const [ pelletDebts_startDate, setPelletDebtsStartDate] = useState(new Date())
    const [ pelletDebts_endDate, setPelletDebtsEndDate] = useState(new Date())
    const [ pelletdebtfilterByClient, setPelletFilterDebtsByClient ] = useState([])
    const [ pelletdebtfilterByShop, setPelletFilterDebtsByShop ] = useState('')
   


    const [ pellet_debt_dtf, setPelletDebtDTF] = useState(false)



    const [ pellet_debts_page, setPelletDebtsPage] = useState(0)
    const [ pellet_debt_total,setPelletDebtTotal ] = useState({})
    const [ pellet_debts,setPelletDebts ] = useState([])
   
    const [ pellet_debt_filterState, setPelletDebtFilterState ] = useState(false)







    const [ filter_pellet_production_by_startDate, setFilterPelletProductionByStartDate] = useState(false)
    const [ filter_pellet_production_by_endDate, setFilterPelletProductionByEndDate ] = useState(false)
    const [ filter_pellet_production_by_workshop, setFilterPelletProductionByWorkshop ] = useState(false)
    const [ filter_pellet_production_by_recorder, setFilterPelletProductionByRecorder ] = useState(false)
   

    const [ pelletProduction_startDate, setPelletProductionStartDate] = useState(new Date())
    const [ pelletProduction_endDate, setPelletProductionEndDate] = useState(new Date())
    const [ pelletProductionFilterByRecorder, setFilterProductionByRecorder ] = useState('')
    const [ pelletProductionFilterByShop, setFilterProductionByShop ] = useState('')
   


    const [ pellet_production_dtf, setPelletProductionDTF] = useState(false)



    const [ pellet_production_page, setPelletProductionPage] = useState(0)
    const [ pellet_production_total,setPelletProductionTotal ] = useState({ amount: 0 })
    const [ pellet_production,setPelletProduction ] = useState([])
   
    const [ filter_pellet_production_state , setFilterPelletProduction ] = useState(false)










    const [ filter_stove_payment_by_startDate, setFilterStovePaymentByStartDate] = useState(false)
    const [ filter_stove_payment_by_endDate, setFilterStovePaymentByEndDate ] = useState(false)
    const [ filter_stove_payment_by_client, setFilterStovePaymentByClient ] = useState(false)
    const [ filter_stove_payment_by_recorder, setFilterStovePaymentByRecorder ] = useState(false)
   

    const [ stove_payment_startDate, setStovePaymentStartDate] = useState(new Date())
    const [ stove_payment_endDate, setStovePaymentEndDate] = useState(new Date())
    const [ stovePaymentFilterRecorder, setFilterStovePaymentRecorder ] = useState('')
    const [ stovePaymentFilterClient, setFilterStovePaymentClient ] = useState('')
   


    const [ stove_payment_dtf, setStovePaymentDTF] = useState(false)



    const [ stove_payment_page, setStovePaymentPage] = useState(0)
    const [ stove_payment_total,setStovePaymentTotal ] = useState({})
    const [ stove_payments , setStovePayments ] = useState([])
   
    const [ filter_stove_payment_state , setFilterStovePayment ] = useState(false)





    const [ view_heater_sale, setViewHeaterSale ] = useState(false)
    const [ view_stove_sale, setViewStoveSale ] = useState(false)
    const [ view_stove_debt, setViewStoveDebt ] = useState(false)
    const [ view_pellet_sale, setViewPelletSale ] = useState(false)
    const [ view_pellet_debt, setViewPelletDebt ] = useState(false)
    const [ view_pellet_production_record, setViewPelletProductionRecord ] = useState(false)



    const [ view_stove_payment_record, viewStovePaymentRecord ] = useState(false)
    

    const [ confrim_state, setConfirmState ] = useState(false)
    const [ confrim_function, setConfirmFunction ] = useState([])


    const [ check_actions, setCheckActions ]  = useState(false)
    









    const [filter_heater_sales_by_startdate, setFilterHeaterSalesByStartDate] = useState(false)
    const [filter_heater_sales_by_endDate, setFilterHeaterSalesByEndDate] = useState(false)
    const [filter_heater_sales_by_customer, setFilterHeaterSalesByCustomer ] = useState(false)

    const [ filter_heater_sales_by_serialnumber, setFilterHeaterSalesBySerialNumber ] = useState(false)

    const [heater_dtf, setHeaterDTF] = useState(false)
    const [heaterSales_startDate, setHeaterSalesstartDate] = useState(new Date())
    const [heaterSales_endDate, setHeaterSalesEndDate] = useState(new Date())
    const [heaterSales_customer, setHeaterSalesCustomer] = useState([])
    const [heaterSales_heaterID, setHeaterSalesHeaterID ] = useState('')
    const [ open_heaterRecords_filter, setHeaterRecordsFilterState ] = useState(false)


    const [heater_sales_page, setHeaterSalesPage] = useState(0)
    const [ heaterSalesTotal, setHeaterSalesTotal ] = useState(0)
    const [ heater_sales, setHeaterSales ] = useState([])





 
    const [filter_stove_debts_by_startdate, setFilterStoveDebtsByStartDate] = useState(false)
    const [filter_stove_debts_by_endDate, setFilterStoveDebtsByEndDate] = useState(false)
    const [filter_stove_debts_by_customer, setFilterStoveDebtsByCustomer ] = useState(false)

   
    const [stove_debt_dtf, setStoveDebtsDTF] = useState(false)
    const [stoveDebts_startDate, setStoveDebtstartDate] = useState(new Date())
    const [stoveDebts_endDate, setStoveDebtEndDate] = useState(new Date())
    const [stoveDebts_customer, setStoveDebtsCustomer] = useState([])



    const [ open_stoveDebtRecords_filter, setStoveDebtRecordsFilterState ] = useState(false)


    
    const [ stoveDebtsTotal, setStoveDebtsTotal ] = useState({price:'', amount_paid:'', debt:''})
    const [ stove_debts, setStoveDebts ] = useState([])

 


    const [stove_debts_page, setStoveDebtsPage] = useState(0)

    


    function handleTabChange(event, new_value){
        if(new_value === 1){
            setStoveSalesPage(0)
            getStoveSalesRecords()
        }
        if(new_value === 2){
            setHeaterSalesPage(0)
            get_heater_payments_records(getStovePaymentForm(0))
        }
        if(new_value === 3){
            setStovePaymentPage(0)
            get_stove_payments_records(getStovePaymentForm(0))
        }
        if(new_value === 4){
            setPelletSalesPage(0)
            get_pellet_records(getPelletSalesForm(0))
        }
        if(new_value === 5){
            setPelletProductionPage(0)
            get_pellet_production_records(getPelletProductionForm(0))
        }
        if(new_value === 6){
            setStoveDebtsPage(0)
            getStoveDebtsRecords(getStoveDebtsForm(0))
        }
        if(new_value === 7){
            setPelletDebtsPage(0)
            get_pellet_debt_records(getPelletDebtForm(0))
        }


        setTab(new_value)
    }

    function getStoveSalesForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_stove_sales_by_startdate){
            data['start_date'] = stoveSales_startDate.toDateString()
        }
        if(filter_stove_sales_by_endDate){
            data['end_date'] = stoveSales_endDate.toDateString()
        }
        if(filter_stove_sales_by_customer){
            data['client'] = stoveSales_customer[1]
        }
        if(filter_stove_sales_by_paymentplan){
            data['payment_plan'] = stoveSales_paymentPlan
        }
        if(filter_stove_sales_by_serialnumber){
            data['stove_id'] = stoveSales_stoveID
        }
        if(filter_stove_sales_by_center){
            data['center'] = stoveSales_center[1]
        }
        if(filter_stove_sales_by_startdate && filter_stove_sales_by_endDate){
            setDTF(true)
        }
        form.append('form', JSON.stringify(data))

        return form
    }


    function getStoveDebtsForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_stove_debts_by_startdate){
            data['start_date'] = stoveDebts_startDate.toDateString()
        }
        if(filter_stove_debts_by_endDate){
            data['end_date'] = stoveDebts_endDate.toDateString()
        }
        if(filter_stove_debts_by_customer){
            data['client'] = stoveDebts_customer[1]
        }
       
        if(filter_stove_debts_by_startdate && filter_stove_debts_by_endDate){
            setStoveDebtsDTF(true)
        }
        form.append('form', JSON.stringify(data))

        return form
    }

    


    function getHeaterSalesForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_heater_sales_by_startdate){
            data['start_date'] = heaterSales_startDate.toDateString()
        }
        if(filter_heater_sales_by_endDate){
            data['end_date'] = heaterSales_endDate.toDateString()
        }
        if(filter_heater_sales_by_customer){
            data['client'] = heaterSales_customer[1]
        }
        if(filter_heater_sales_by_serialnumber){
            data['stove_id'] = heaterSales_heaterID
        }
        if(filter_heater_sales_by_startdate && filter_heater_sales_by_endDate){
            setHeaterDTF(true)
        }
        form.append('form', JSON.stringify(data))

        return form
    }

    function getPelletSalesForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_pellet_sales_by_startDate){
            data['start_date'] = pelletSales_startDate.toDateString()
        }
        if(filter_pellet_sales_by_endDate){
            data['end_date'] = pelletSales_endDate.toDateString()
        }
        if(filter_pellet_sales_by_shop){
            data['shop'] = pelletfilterByShop[1]
        }
        if(filter_pellet_sales_by_recorder){
            data['recorded_by'] = pelletfilterByRecorder
        }
        if(filter_pellet_sales_by_startDate && filter_pellet_sales_by_endDate){
            setPelletDTF(true)
        }
        
        form.append('form', JSON.stringify(data))

        return form
    }

    function getPelletDebtForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_pellet_debts_by_startDate){
            data['start_date'] = pelletDebts_startDate.toDateString()
        }
        if(filter_pellet_debts_by_endDate){
            data['end_date'] = pelletDebts_endDate.toDateString()
        }
        if(filter_pellet_debts_by_shop){
            data['shop'] = pelletdebtfilterByShop[1]
        }
        if(filter_pellet_debts_by_client){
            data['client'] = pelletdebtfilterByClient[1]
        }
        if(filter_pellet_sales_by_startDate && filter_pellet_sales_by_endDate){
            setPelletDebtDTF(true)
        }
        
        form.append('form', JSON.stringify(data))

        return form
    }


    function getPelletProductionForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_pellet_production_by_startDate){
            data['start_date'] = pelletProduction_startDate.toDateString()
        }
        if(filter_pellet_production_by_endDate){
            data['end_date'] = pelletProduction_endDate.toDateString()
        }
        if(filter_pellet_production_by_workshop){
            data['workshop'] = pelletProductionFilterByShop[1]
        }
        if(filter_pellet_production_by_recorder){
            data['recorded_by'] = pelletProductionFilterByRecorder
        }
        if(filter_pellet_production_by_startDate && filter_pellet_production_by_endDate){
            setPelletProductionDTF(true)
        }
        
        form.append('form', JSON.stringify(data))

        return form
    }

    function getStovePaymentForm(start){
        const data = {}
        const form = new FormData()

        data['start'] = start

        if(filter_stove_payment_by_startDate){
            data['start_date'] = stove_payment_startDate.toDateString()
        }
        if(filter_stove_payment_by_endDate){
            data['end_date'] = stove_payment_endDate.toDateString()
        }
        if(filter_stove_payment_by_client){
            data['client'] = stovePaymentFilterClient[1]
        }
        if(filter_stove_payment_by_recorder){
            data['recorded_by'] = stovePaymentFilterRecorder
        }
        if(filter_stove_payment_by_startDate && filter_stove_payment_by_endDate){
            setStovePaymentDTF(true)
        }
        
        form.append('form', JSON.stringify(data))

        return form
    }

    function get_stove_records(form, append=false){
        fetch(main.server + '/get_stove_sales',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(append){

                    setStoveSales( prev => [...prev, ...res.data.list] )
                }
                else{
                    setStoveSales( res.data.list )
                }
               
                setStoveSalesTotal(res.data.totals)
            }
            
        })
    }

    function get_debtors_stove_records(form, append=false){
        fetch(main.server + '/get_stove_sales',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(append){

                    setStoveDebtList( prev => [...prev, ...res.data.list] )
                }
                else{
                    setStoveDebtList( res.data.list )
                }
               
                setStoveDebtsTotals(res.data.totals)
            }
            
        })
    }




    function get_stove_debt_records(form, append=false){
        fetch(main.server + '/get_stove_debts',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(append){

                    setStoveDebts( prev => [...prev, ...res.data.list] )
                }
                else{
                    setStoveDebts( res.data.list )
                }
               
                setStoveDebtsTotal(res.data.totals)
            }
            
        })
    }

    


    function get_heater_records(form, append=false){
        fetch(main.server + '/get_heater_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(!append){
                    setHeaterSales( res.data.list )
                }else{
                    setHeaterSales( prev => [ ...prev , ...res.data.list ])
                }
                
               
                setHeaterSalesTotal(res.data.totals)
            }
            
        })
    }

    function get_pellet_records(form, append=false){
        fetch(main.server + '/get_pellet_sales',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(!append){
                    setPelletSales( res.data.list )
                }else{
                    setPelletSales( prev => [ ...prev , ...res.data.list ])
                }
                
                setPelletTotal(res.data.total)
            }
            
        })
    }

    function get_pellet_debt_records(form, append=false){
        fetch(main.server + '/get_pellet_debts',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(!append){
                    setPelletDebts( res.data.list )
                }else{
                    setPelletDebts( prev => [ ...prev , ...res.data.list ])
                }
                
                setPelletDebtTotal(res.data.total)
            }
            
        })
    }

    function get_pellet_production_records(form, append=false){
        fetch(main.server + '/get_pellet_production_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(!append){
                    setPelletProduction( res.data.list )
                    
                }else{
                    setPelletProduction( prev => [...prev , ...res.data.list] )
                }
                setPelletProductionTotal(res.data.totals)
            }
            
        })
    }
    function get_stove_payments_records(form, append=false){
        fetch(main.server + '/get_stove_payment_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                if(!append){
                    setStovePayments( res.data.list )
                }else{ 
                    setStovePayments( prev => [...prev,  ...res.data.list ] )
                }
                
                setStovePaymentTotal(res.data.totals)
            }
            
        })
    }

    function get_heater_payments_records(form){
        fetch(main.server + '/get_heater_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setHeaterSales( res.data.list )
                setHeaterSalesTotal(res.data.totals)
            }
            
        })
    }

    function getStoveSalesRecords(){
        const form = getStoveSalesForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})
        get_stove_records(form)
    }

    function getStoveDebtsRecords(){
        const form = getStoveDebtsForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})
        get_stove_debt_records(form)
    }


    function getHeaterSalesRecords(){
        const form = getHeaterSalesForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})
        get_heater_records(form)
    }

    function getPelletSalesRecords(){
        const form = getPelletSalesForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})

        get_pellet_records(form)

        
    }
    function getPelletDebtRecords(){
        const form = getPelletDebtForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})

        get_pellet_debt_records(form)

        
    }

    function getPelletProductionRecords(){
        const form = getPelletProductionForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})

        get_pellet_production_records(form)

        
    }
    function getStovePaymentRecords(){
        const form = getStovePaymentForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})

        get_stove_payments_records(form)

        
    }

    function fetchMoreStoveSales(){
        
        const form = getStoveSalesForm( stove_sales.length > 0 ? stove_sales.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_stove_records(form, true)
        
    }

    function fetchMoreStoveDebts(){
        
        const form = getStoveDebtsForm( stove_debts.length > 0 ? stove_debts.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_stove_debt_records(form, true)
        
    }

    function fetchMoreHeaterSales(){
        
        const form = getHeaterSalesForm( stove_sales.length > 0 ? stove_sales.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_heater_records(form, true)
        
    }

    function fetchMorePelletSales(){
        
        const form = getPelletSalesForm(pellet_sales.length)
        setSnack({message:'Fetching records...'})
        get_pellet_records(form, true)
        
    }

    function fetchMorePelletDebts(){
        
        const form = getPelletSalesForm(pellet_sales.length)
        setSnack({message:'Fetching records...'})
        get_pellet_debt_records(form, true)
        
    }


    function fetchMorePelletProductionRecords(){
        
        const form = getPelletProductionForm(pellet_production.length)
        setSnack({message:'Fetching records...'})
        get_pellet_production_records(form, true)
        
    }


    function fetchMoreStovePaymentRecords(){
        
        const form = getStovePaymentForm(stove_payments.length)
        setSnack({message:'Fetching records...'})
        get_stove_payments_records(form, true)
        
    }

    function close_stove_records_filter(){
        setStoveRecordsFilterState(false)
    }
    function close_stove_debts_filter(){
        setStoveDebtRecordsFilterState(false)
    }
    function close_heater_records_filter(){
        setHeaterRecordsFilterState(false)
    }
    function close_pellet_records_filter(){
        setPelletFilterState(false)
    }
    function close_pellet_debts_filter(){
        setPelletDebtFilterState(false)
    }
    function close_pellet_production_filter(){
        setFilterPelletProduction(false)
    }
    function close_stove_payment_filter(){
        setFilterStovePayment(false)
    }

    function StoveRecordsFilter(){
        setStoveRecordsFilterState(true)
    }

    function HeaterRecordsFilter(){
        setHeaterRecordsFilterState(true)
    }
    function FilterPelletSales(){
        setPelletFilterState(true)
    }
    function FilterPelletDebts(){
        setPelletDebtFilterState(true)
    }
    function FilterPelletProductionRecords(){
        setFilterPelletProduction(true)
    }
    function FilterStovePaymentRecords(){
        setFilterStovePayment(true)
    }
    function FilterStoveDebtsRecords(){
        setStoveDebtRecordsFilterState(true)
    }



    function downloadStoveRecords(all=false) {

        
        setDownloaderState(true)
        const form = getStoveSalesForm(0)
        if(all){

            form.append('download_all_records', true)
        }
 
        setSnack({message:'Download document...'})


        fetch(main.server + '/download_stove_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDlStoveSales(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDlStoveSales(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
        })


        
    }


    function downloadStoveDebts(all=false) {

        
        setDownloaderState(true)
        const form = getStoveDebtsForm(0)
        if(all){

            form.append('download_all_records', true)
        }
 
        setSnack({message:'Download document...'})


        fetch(main.server + '/download_stove_debt_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDlStoveSales(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDlStoveSales(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
        })


        
    }

     
    function downloadHeaterRecords() {
        
        setDownloaderState(true)
        

        const form = getHeaterSalesForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_heater_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDlHeaterSales(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDlHeaterSales(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
            
        })


        
    }
    function downloadPelletsalesRecords() {

        setDownloaderState(true)
        
        const form = getPelletSalesForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_pellet_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDlPelletSales(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDlPelletSales(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
        })


        
    }
    function downloadPelletDebtsRecords() {

        setDownloaderState(true)
        
        const form = getPelletSalesForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_pellet_debt_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDlPelletDebts(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDlPelletDebts(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
        })


        
    }
    function downloadPelletProductionRecords() {

        setDownloaderState(true)
        

        const form = getPelletProductionForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_pellet_production_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDlPelletProduction(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDlPelletProduction(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
        })


        
    }

    function downloadStovePaymentRecords() {

        setDownloaderState(true)
        

        const form = getStovePaymentForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_stove_payment_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDlStovePayments(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDlStovePayments(false)
                setSnack({message:res.message})
            }
            setDownloaderState(false)
        })


        
    }

    const [ view_stove_sale_data, set_stove_sale_data ] = useState({})
    const [ view_stove_debt_data, set_stove_debt_data ] = useState({})
    const [ view_heater_sale_data, set_heater_sale_data ] = useState({})
    const [ view_pellet_sale_data, set_pellet_sale_data ] = useState({})
    const [ view_pellet_debt_data, set_pellet_debt_data ] = useState({})
    const [ view_pellet_production_data, set_pellet_production_data ] = useState({})
    const [ view_stove_payment_data, set_view_stove_payment_data ] = useState({})




    function getSaleStovePaymentForm(uid){
      const data = {}
      const form = new FormData()
      
      data.sale = uid
      form.append('form', JSON.stringify(data))

      return form
    }

    function getSaleStoveDebtForm(client){
        const data = {}
        const form = new FormData()
        
        data.client_name = client
        form.append('form', JSON.stringify(data))
  
        return form
      }

    const [ stove_sale_payments, setStoveSalePayments ] = useState([])
    const [ stove_debt_list, setStoveDebtList ] = useState([])
    const [ stove_debts_totals, setStoveDebtsTotals ] = useState([])
    const [ stove_sale_payments_total, setStoveSalePaymentsTotal ] = useState([])


    

    function get_stove_sale_payments_records(form){
      fetch(main.server + '/get_stove_payment_records_suid',{
          method: 'POST',
          body: form
      }).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
      }).then(res => {
          if(res.request_status){
              
            setStoveSalePayments( res.data.list )
            setStoveSalePaymentsTotal(res.data.totals)
          }
          
      })
    }

    /*
    function downloadStovePaymentRecords() {      

      const form = getStovePaymentForm()

      props.setSnack({message:'Download document...'})

  
      fetch(main.server + '/download_stove_payment_records_suid',{
          method: 'POST',
          body: form
      }).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
      }).then(res => {
          if(res.request_status){
           
              window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
          }else{
            props.setSnack({message:res.message})
          }
          
      })


        
    }*/



    function viewStoveSaleRecord(event){
        const key = event.target.parentElement.dataset.key
        get_stove_sale_payments_records(getSaleStovePaymentForm(event.target.parentElement.dataset.uid))
        setViewStoveSale(true)
        set_stove_sale_data(stove_sales[key])
        
    }
    function viewStoveDebtRecord(event){
        const key = event.target.parentElement.dataset.key
        get_debtors_stove_records(getSaleStoveDebtForm(event.target.parentElement.dataset.client))
        setViewStoveDebt(true)
        set_stove_debt_data(stove_debts[key])
        
    }
    function viewHeaterSaleRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewHeaterSale(true)
        set_heater_sale_data(heater_sales[key])
        
    }
    function viewPelletSaleRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewPelletSale(true)
        console.log(key)
        set_pellet_sale_data(pellet_sales[key])
        
    }
    function viewPelletDebtRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewPelletDebt(true)
        console.log(key)
        set_pellet_debt_data(pellet_debts[key])
        
    }

    function viewStovePaymentRecord_(event){
        const key = event.target.parentElement.dataset.key
        viewStovePaymentRecord(true)
        console.log(key)
        set_view_stove_payment_data(stove_payments[key])
        
    }

    
    function ViewPelletProduction(event){
        const key = event.target.parentElement.dataset.key
        setViewPelletProductionRecord(true)
        console.log(key)
        set_pellet_production_data(pellet_production[key])
    }
    function closeStoveRecordViewer(){
        setViewStoveSale(false)
    }
    function closeStoveDebtViewer(){
        setViewStoveDebt(false)
    }
    function closeHeaterRecordViewer(){
        setViewHeaterSale(false)
    }
    function closePelletRecordViewer(){
        setViewPelletSale(false)
    }
    function closePelletDebtViewer(){
        setViewPelletDebt(false)
    }
    function closeStovePaymentRecordViewer(){
        viewStovePaymentRecord(false)
    }
    function closePelletProductionRecordViewer(){
        setViewPelletProductionRecord(false)
    }
    

    function closeConfirmDialog(){
        setConfirmState(false)
        setConfirmFunction([])
    }
    

    function _modify_stove_sale(form){
    
        fetch(main.server + '/modify_stove_sale',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }


    function _modify_heater_sale(form){
    
        fetch(main.server + '/modify_heater_sale',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }

    function _modify_pellet_sale(form){
        console.log('last step', form)
        fetch(main.server + '/modify_pellet_sale',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }


    function _modify_stove_payment(form){
        console.log('last step', form)
        fetch(main.server + '/modify_pellet_sale',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }


    function _modify_pellet_production(form){
        console.log('last step', form)
        fetch(main.server + '/modify_pellet_production',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }



     
    const delete_messenger = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_stove_sale])
    }

    const modify_messenger = (form) => {
        console.log(form)
        setConfirmState(true)
        setConfirmFunction([() => _modify_stove_sale(form)])
    }



    const delete_messenger_heater_sales = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_heater_sale])
    }

    const modify_messenger_heater_sales = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_heater_sale(form)])
    }


    const delete_messenger_pellet_sales = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_pellet_sale])
    }

    const delete_messenger_stovePayment = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_stove_payment])
    }

    const delete_messenger_pellet_production = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_pellet_production])
    }

    const modify_messenger_pellet_sales = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_pellet_sale(form)])
    }

 

    const modify_messenger_stovePayment = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_stove_payment(form)])
    }

    const modify_messenger_pellet_production = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_pellet_production(form)])
    }


    function _delete_stove_sale (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_stove_sale_data.UID}))
  
        fetch( main.server + '/delete_stove_sale',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }


    function _delete_heater_sale (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_heater_sale_data.UID}))
  
        fetch( main.server + '/delete_heater_sale',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }


    function _delete_pellet_sale (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_pellet_sale_data.UID}))
  
        fetch( main.server + '/delete_pellet_sale',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }






    function _delete_stove_payment (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_stove_payment_data.UID}))
  
        fetch( main.server + '/delete_stove_payment',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }



    function _delete_pellet_production (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_pellet_production_data.UID}))
  
        fetch( main.server + '/delete_pellet_production_record',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }

    function accept(){
        confrim_function[0]()
    }

    function getPage(){
        if(tab === 1){
            return (<div>
                <StoveSalesTable 
                    startDate={stoveSales_startDate} 
                    endDate={stoveSales_endDate}
                    dtf={dtf} 
                    page={stove_sales_page} 
                    setPage={setStoveSalesPage} 
                    totals={stoveSalesTotal} 
                    sales={stove_sales} 
                    fetch_more={fetchMoreStoveSales} 
                    filter={StoveRecordsFilter} 
                    view_record={viewStoveSaleRecord}
  
                    download={() => setDlStoveSales(true)}
                />
            </div>)
        }else if(tab === 2){
            return (<div>
                <HeaterSalesTable 
                    startDate={heaterSales_startDate} 
                    endDate={heaterSales_endDate}
                    dtf={heater_dtf} 
                    page={heater_sales_page} 
                    setPage={setHeaterSalesPage} 
                    totals={heaterSalesTotal} 
                    sales={heater_sales} 
                    fetch_more={fetchMoreHeaterSales} 
                    filter={HeaterRecordsFilter} 
                    view_record={viewHeaterSaleRecord}
                    download={() => setDlHeaterSales(true)}
                />
            </div>)
        }
        else if( tab === 3){
            return(<div>
                <StovePaymentTable
                    startDate={stove_payment_startDate} 
                    endDate={stove_payment_endDate}  
                    dtf={stove_payment_dtf} 
                    page={stove_payment_page} 
                    setPage={setStovePaymentPage} 
                    total={stove_payment_total} 
                    sales={stove_payments} 
                    fetch_more={fetchMoreStovePaymentRecords} 
                    filter={FilterStovePaymentRecords} 
                    view_record={viewStovePaymentRecord_}
                    download={() => setDlStovePayments(true)}
                />
            </div>)
        }
        else if(tab === 4){
            return (<div>
                <PelletSalesTable 
                    startDate={ pelletSales_startDate } 
                    endDate={pelletSales_endDate}  
                    dtf={pellet_dtf} 
                    page={pellet_sales_page} 
                    setPage={setPelletSalesPage} 
                    total={pellet_total} 
                    sales={pellet_sales} 
                    fetch_more={fetchMorePelletSales} 
                    filter={FilterPelletSales} 
                    view_record={viewPelletSaleRecord}
                    download={() => setDlPelletSales(true)}
                />
            </div>)
        }
        else if(tab === 5){
            return (<div>
                <PelletProductionTable 
                    startDate={ pelletProduction_startDate } 
                    endDate={pelletProduction_endDate}  
                    dtf={pellet_production_dtf} 
                    page={pellet_production_page} 
                    setPage={setPelletProductionPage} 
                    total={pellet_production_total} 
                    sales={pellet_production} 
                    fetch_more={fetchMorePelletProductionRecords} 
                    filter={FilterPelletProductionRecords} 
                    view_record={ViewPelletProduction}
                    download={() => setDlPelletProduction(true) }
                />
            </div>)
        }
        else if(tab === 6){
            return (<div>
                <StoveDebtsTable 
                    startDate={stoveDebts_startDate} 
                    endDate={stoveDebts_endDate}
                    dtf={stove_debt_dtf} 
                    page={stove_debts_page} 
                    setPage={setStoveDebtsPage} 
                    totals={stoveDebtsTotal} 
                    sales={stove_debts} 
                   
                    fetch_more={fetchMoreStoveDebts} 
                    filter={FilterStoveDebtsRecords} 
                    view_record={viewStoveDebtRecord}
  
                    download={() => setDlStoveDebts(true)}
                />
            </div>)
        }
        else if(tab === 7){
            return (<div>
                <PelletDebtsTable 
                    startDate={ pelletDebts_startDate } 
                    endDate={pelletDebts_endDate}  
                    dtf={pellet_debt_dtf} 
                    page={pellet_debts_page} 
                    setPage={setPelletDebtsPage} 
                    total={pellet_debt_total} 
                    sales={pellet_debts} 
                    fetch_more={fetchMorePelletDebts} 
                    filter={FilterPelletDebts} 
                    view_record={viewPelletDebtRecord}
                    download={() => setDlPelletDebts(true)}
                />
            </div>)
        }
       
    }

    function RecordsLoader(){


        const data = {}
        const form = new FormData()
    
        data['start'] = 0
        
        form.append('form', JSON.stringify(data))
    
    

        return fetch(main.server + '/get_stove_sales', {
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setStoveSalesData(res.data)
                setStoveSalesTotal(res.data.totals)
                setStoveSales(res.data.list)
            }
            
        })
          
    }
    useEffect(() => {
        if(!start){

            setSnack({
                message: 'page loaded',
            })

            RecordsLoader()

            fetch( main.server + '/sales_information').then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                    setPaymentPlans(res.data.payment_plans)
                }
                
            })

            

            setStart(true)
        }
        if(_actions_.length > 0 && !check_actions){

            if(_actions_.indexOf('view_records') !== -1){
                setNotAllowed(false)
            }else{
                setNotAllowed(true)
            }
            setCheckActions(true)
        }
    })


    /*
         

    */

    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>Records</div>
                </div>
                <div className="tabs">
                    <Tabs className='tabBody'  value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab value={1} label="Stove sales records"  />
                        <Tab value={2} label="Heater sales records"  />
                        <Tab value={3} label="Stove payment records"  />
                        <Tab value={4} label="Pellet sales records"  />
                        <Tab value={5} label="Pellet production records"  />
                        <Tab value={6} label="Stove debt records"  />
                        <Tab value={7} label="Pellet debt records"  />
                    
                    </Tabs>
                </div>
                <div className='centerPage'>
                    
                    {getPage()}
                </div>
              
                <FilterStoveRecords 
                    state={open_stoveRecords_filter}

                    payment_plans={payment_plans} 
                    filter_stove_sales_by_startdate={filter_stove_sales_by_startdate}
                    filter_stove_sales_by_endDate={filter_stove_sales_by_endDate}
                    filter_stove_sales_by_customer={filter_stove_sales_by_customer}
                    filter_stove_sales_by_paymentplan={filter_stove_sales_by_paymentplan}
                    filter_stove_sales_by_serialnumber={filter_stove_sales_by_serialnumber}
                    
                    setFilterStoveSalesBySerialNumber={setFilterStoveSalesBySerialNumber}
                    setFilterStoveSalesByStartDate={setFilterStoveSalesByStartDate}
                    setFilterStoveSalesByEndDate={setFilterStoveSalesByEndDate}
                    setFilterStoveSalesByCustomer={setFilterStoveSalesByCustomer}
                    setFilterStoveSalesByPaymentPlan={setFilterStoveSalesByPaymentPlan}
                    
                    stoveSales_stoveID={stoveSales_stoveID}
                    setStoveSalesStoveID={setStoveSalesStoveID}

                    stoveSales_startDate={stoveSales_startDate}
                    setStoveSalesstartDate={setStoveSalesstartDate}

                    stoveSales_endDate={stoveSales_endDate}
                    setStoveSalesEndDate={setStoveSalesEndDate}

          
                    stoveSales_customer={stoveSales_customer}
                    setStoveSalesCustomer={setStoveSalesCustomer}

                    stoveSales_paymentPlan={stoveSales_paymentPlan}
                    setStoveSalesPaymentPlan={setStoveSalesPaymentPlan}

                    stoveSales_center={stoveSales_center}
                    filter_stove_sales_by_center={filter_stove_sales_by_center}
                
                    setStoveSalesCenter={setStoveSalesCenter}
                    setFilterStoveSalesByCenter={setFilterStoveSalesByCenter}

                    getStoveSalesRecords={getStoveSalesRecords}

                    setPage={setStoveSalesPage} 
                    
                    close_stove_records_filter={close_stove_records_filter}
                  

                />

                <FilterStoveDebts
                    state={open_stoveDebtRecords_filter}

                    filter_stove_sales_by_startdate={filter_stove_debts_by_startdate}
                    filter_stove_sales_by_endDate={filter_stove_debts_by_endDate}
                    filter_stove_sales_by_customer={filter_stove_debts_by_customer}
                   
                   
                    setFilterStoveSalesByStartDate={setFilterStoveDebtsByStartDate}
                    setFilterStoveSalesByEndDate={setFilterStoveDebtsByEndDate}
                    setFilterStoveSalesByCustomer={setFilterStoveDebtsByCustomer}
                 
                    
                    stoveSales_startDate={stoveDebts_startDate}
                    setStoveSalesstartDate={setStoveDebtstartDate}

                    stoveSales_endDate={stoveDebts_endDate}
                    setStoveSalesEndDate={setStoveDebtEndDate}

          
                    stoveSales_customer={stoveDebts_customer}
                    setStoveSalesCustomer={setStoveDebtsCustomer}


                    getStoveSalesRecords={getStoveDebtsRecords}

                    setPage={setStoveDebtsPage} 
                    
                    close_stove_records_filter={close_stove_debts_filter}
                />




                <FilterPelletDebtRecords

                    state={pellet_debt_filterState}

                    payment_plans={payment_plans} 
                    filter_pellet_sales_by_startDate={filter_pellet_debts_by_startDate}
                    filter_pellet_sales_by_endDate={filter_pellet_debts_by_endDate}
                    filter_pellet_sales_by_shop={filter_pellet_debts_by_shop}
                    filter_pellet_debts_by_client={filter_pellet_debts_by_client}

                    setFilterPelletSalesByStartDate={setFilterPelletDebtsByStartDate}
                    setFilterPelletSalesByEndDate={setFilterPelletDebtsByEndDate}
                    setFilterPelletSalesByShop={setFilterPelletDebtsByShop}
                    setFilterPelletDebtsByClient={setFilterPelletDebtsByClient}

                    pelletSales_startDate={pelletDebts_startDate}
                    setPelletSalesStartDate={setPelletDebtsStartDate}

                    pelletSales_endDate={pelletDebts_endDate}
                    setPelletSalesEndDate={setPelletDebtsEndDate}

                    pelletdebtfilterByClient={pelletdebtfilterByClient}
                    setPelletFilterDebtsByClient={setPelletFilterDebtsByClient}

                    pelletfilterByShop={pelletdebtfilterByShop}
                    setFilterPelletsByShop={setPelletFilterDebtsByShop}


                    getPelletSalesRecords={getPelletDebtRecords}
                    setPage={setPelletDebtsPage}

                    close_pellet_records_filter={close_pellet_debts_filter}
                />

               


                <FilterHeaterSales 

                    state={open_heaterRecords_filter}

                    filter_heater_sales_by_startdate={filter_heater_sales_by_startdate}
                    filter_heater_sales_by_endDate={filter_heater_sales_by_endDate}
                    filter_heater_sales_by_customer={filter_heater_sales_by_customer}
                    filter_heater_sales_by_serialnumber={filter_heater_sales_by_serialnumber}
                    
                    setFilterHeaterSalesBySerialNumber={setFilterHeaterSalesBySerialNumber}
                    setFilterHeaterSalesByStartDate={setFilterHeaterSalesByStartDate}
                    setFilterHeaterSalesByEndDate={setFilterHeaterSalesByEndDate}
                    setFilterHeaterSalesByCustomer={setFilterHeaterSalesByCustomer}
                    
                    heaterSales_heaterID={heaterSales_heaterID}
                    setHeaterSalesHeaterID={setHeaterSalesHeaterID}

                    heaterSales_startDate={heaterSales_startDate}
                    setHeaterSalesstartDate={setHeaterSalesstartDate}

                    heaterSales_endDate={heaterSales_endDate}
                    setHeaterSalesEndDate={setHeaterSalesEndDate}

          
                    heaterSales_customer={heaterSales_customer}
                    setHeaterSalesCustomer={setHeaterSalesCustomer}
                 
                    getHeaterSalesRecords={getHeaterSalesRecords}

                    setPage={setHeaterSalesPage} 
                    
                    close_heater_records_filter={close_heater_records_filter}
                  

                />


                <FilterPelletSalesRecords 
                    state={pellet_filterState}

                    payment_plans={payment_plans} 
                    filter_pellet_sales_by_startDate={filter_pellet_sales_by_startDate}
                    filter_pellet_sales_by_endDate={filter_pellet_sales_by_endDate}
                    filter_pellet_sales_by_shop={filter_pellet_sales_by_shop}
                    filter_pellet_sales_by_recorder={filter_pellet_sales_by_recorder}

                    setFilterPelletSalesByStartDate={setFilterPelletSalesByStartDate}
                    setFilterPelletSalesByEndDate={setFilterPelletSalesByEndDate}
                    setFilterPelletSalesByShop={setFilterPelletSalesByShop}
                    setFilterPelletSalesByRecorder={setFilterPelletSalesByRecorder}

                    pelletSales_startDate={pelletSales_startDate}
                    setPelletSalesStartDate={setPelletSalesStartDate}

                    pelletSales_endDate={pelletSales_endDate}
                    setPelletSalesEndDate={setPelletSalesEndDate}

                    pelletfilterByRecorder={pelletfilterByRecorder}
                    setFilterPelletsByRecorder={setFilterPelletsByRecorder}

                    pelletfilterByShop={pelletfilterByShop}
                    setFilterPelletsByShop={setFilterPelletsByShop}

                    
                    getPelletSalesRecords={getPelletSalesRecords}
                    setPage={setPelletSalesPage} 

                    close_pellet_records_filter={close_pellet_records_filter}
                />



              

                <FilterPelletProduction

                    state={filter_pellet_production_state}


                    filter_pellet_sales_by_startDate={filter_pellet_production_by_startDate}
                    filter_pellet_sales_by_endDate={filter_pellet_production_by_endDate}
                    filter_pellet_sales_by_workshop={filter_pellet_production_by_workshop}
                    filter_pellet_sales_by_recorder={filter_pellet_production_by_recorder}

                    setFilterPelletSalesByStartDate={setFilterPelletProductionByStartDate}
                    setFilterPelletSalesByEndDate={setFilterPelletProductionByEndDate}
                    setFilterPelletSalesByWorkshop={setFilterPelletProductionByWorkshop}
                    setFilterPelletSalesByRecorder={setFilterPelletProductionByRecorder}

                    pelletSales_startDate={pelletProduction_startDate}
                    setPelletSalesStartDate={setPelletProductionStartDate}

                    pelletSales_endDate={pelletProduction_endDate}
                    setPelletSalesEndDate={setPelletProductionEndDate}

                    pelletfilterByRecorder={pelletProductionFilterByRecorder}
                    setFilterPelletsByRecorder={setFilterProductionByRecorder}

                    pelletfilterByWorkshop={pelletProductionFilterByShop}
                    setFilterPelletsByWorkshop={setFilterProductionByShop}

                    
                    getPelletSalesRecords={getPelletProductionRecords}
                    setPage={setPelletProductionPage} 

                    close_pellet_records_filter={close_pellet_production_filter}
                />




                <FilterStovePayments

                    state={filter_stove_payment_state}

                    filter_pellet_sales_by_startDate={filter_stove_payment_by_startDate}
                    filter_pellet_sales_by_endDate={filter_stove_payment_by_endDate}
                    filter_pellet_sales_by_client={filter_stove_payment_by_client}
                    filter_pellet_sales_by_recorder={filter_stove_payment_by_recorder}

                    setFilterPelletSalesByStartDate={setFilterStovePaymentByStartDate}
                    setFilterPelletSalesByEndDate={setFilterStovePaymentByEndDate}
                    setFilterPelletSalesByClient={setFilterStovePaymentByClient}
                    setFilterPelletSalesByRecorder={setFilterStovePaymentByRecorder}

                    pelletSales_startDate={stove_payment_startDate}
                    setPelletSalesStartDate={setStovePaymentStartDate}

                    pelletSales_endDate={stove_payment_endDate}
                    setPelletSalesEndDate={setStovePaymentEndDate}

                    pelletfilterByRecorder={stovePaymentFilterRecorder}
                    setFilterPelletsByRecorder={setFilterStovePaymentRecorder}

                    pelletfilterByClient={stovePaymentFilterClient}
                    setFilterPelletsByClient={setFilterStovePaymentClient}


                    getPelletSalesRecords={getStovePaymentRecords}
                    setPage={setStovePaymentPage} 

                    close_pellet_records_filter={close_stove_payment_filter}
                />


                <ViewStoveSale 
                    state={view_stove_sale}
                    close={closeStoveRecordViewer}
                    data={view_stove_sale_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger}
                    delete_messenger={delete_messenger}
                    stove_sale_payments={stove_sale_payments}
                    stove_sale_payments_total={stove_sale_payments_total}
                    user_uid={user_uid}
                />

                <ViewStoveDebt
                    state={view_stove_debt}
                    close={closeStoveDebtViewer}
                    data={view_stove_debt_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                   
                    stove_sales={stove_debt_list}
                    stove_sale_payments_total={stove_debts_totals}
                    user_uid={user_uid}
                />


                <ViewHeaterSale 
                    state={view_heater_sale}
                    close={closeHeaterRecordViewer}
                    data={view_heater_sale_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_heater_sales}
                    delete_messenger={delete_messenger_heater_sales}
                    user_uid={user_uid}
                />


                <ViewPelletSale 
                    state={view_pellet_sale}
                    close={closePelletRecordViewer}
                    data={view_pellet_sale_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_pellet_sales}
                    delete_messenger={delete_messenger_pellet_sales}
                />


                <ViewPelletDebt
                    state={view_pellet_debt}
                    close={closePelletDebtViewer}
                    data={view_pellet_debt_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                  
               
                />




                <ViewStovePaymentRecord
                    state={view_stove_payment_record}
                    close={closeStovePaymentRecordViewer}
                    data={view_stove_payment_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_stovePayment}
                    delete_messenger={delete_messenger_stovePayment}
                />

                

                <ViewPelletProductionRecord 
                    state={view_pellet_production_record}
                    close={closePelletProductionRecordViewer}
                    data={view_pellet_production_data}
                    alert={setAlert}
                    actions={_actions_}
                    snack={setSnack}
                    modify_messenger={modify_messenger_pellet_production}
                    delete_messenger={delete_messenger_pellet_production}

                />



                <DownloadStoveSales
                    state={dl_stove_sales}
                    setDownloaderState={setDlStoveSales}
                    download={downloadStoveRecords}

                    payment_plans={payment_plans} 
                    filter_stove_sales_by_startdate={filter_stove_sales_by_startdate}
                    filter_stove_sales_by_endDate={filter_stove_sales_by_endDate}
                    filter_stove_sales_by_customer={filter_stove_sales_by_customer}
                    filter_stove_sales_by_paymentplan={filter_stove_sales_by_paymentplan}
                    filter_stove_sales_by_serialnumber={filter_stove_sales_by_serialnumber}
                    
                    setFilterStoveSalesBySerialNumber={setFilterStoveSalesBySerialNumber}
                    setFilterStoveSalesByStartDate={setFilterStoveSalesByStartDate}
                    setFilterStoveSalesByEndDate={setFilterStoveSalesByEndDate}
                    setFilterStoveSalesByCustomer={setFilterStoveSalesByCustomer}
                    setFilterStoveSalesByPaymentPlan={setFilterStoveSalesByPaymentPlan}
                    
                    stoveSales_stoveID={stoveSales_stoveID}
                    setStoveSalesStoveID={setStoveSalesStoveID}

                    stoveSales_startDate={stoveSales_startDate}
                    setStoveSalesstartDate={setStoveSalesstartDate}

                    stoveSales_endDate={stoveSales_endDate}
                    setStoveSalesEndDate={setStoveSalesEndDate}

          
                    stoveSales_customer={stoveSales_customer}
                    setStoveSalesCustomer={setStoveSalesCustomer}

                    stoveSales_paymentPlan={stoveSales_paymentPlan}
                    setStoveSalesPaymentPlan={setStoveSalesPaymentPlan}

                    stoveSales_center={stoveSales_center}
                    filter_stove_sales_by_center={filter_stove_sales_by_center}
                    setStoveSalesCenter={setStoveSalesCenter}
                    setFilterStoveSalesByCenter={setFilterStoveSalesByCenter}

            

                />





                <DownloadStoveDebts
                    state={dl_stove_debts}
                    setDownloaderState={setDlStoveDebts}
                    download={downloadStoveDebts}

     
                    filter_stove_sales_by_startdate={filter_stove_debts_by_startdate}
                    filter_stove_sales_by_endDate={filter_stove_debts_by_endDate}
                    filter_stove_sales_by_customer={filter_stove_debts_by_customer}
 
                
                    setFilterStoveSalesByStartDate={setFilterStoveDebtsByStartDate}
                    setFilterStoveSalesByEndDate={setFilterStoveDebtsByEndDate}
                    setFilterStoveSalesByCustomer={setFilterStoveDebtsByCustomer}
     

                    stoveSales_startDate={stoveDebts_startDate}
                    setStoveSalesstartDate={setStoveDebtstartDate}

                    stoveSales_endDate={stoveDebts_endDate}
                    setStoveSalesEndDate={setStoveDebtEndDate}

          
                    stoveSales_customer={stoveDebts_customer}
                    setStoveSalesCustomer={setStoveDebtsCustomer}



                />




                <DownloadHeaterRecords
                    state={dl_heater_sales}
                    setDownloaderState={setDlHeaterSales}
                    download={downloadHeaterRecords}


                    filter_heater_sales_by_startdate={filter_heater_sales_by_startdate}
                    filter_heater_sales_by_endDate={filter_heater_sales_by_endDate}
                    filter_heater_sales_by_customer={filter_heater_sales_by_customer}
                    filter_heater_sales_by_serialnumber={filter_heater_sales_by_serialnumber}
                    
                    setFilterHeaterSalesBySerialNumber={setFilterHeaterSalesBySerialNumber}
                    setFilterHeaterSalesByStartDate={setFilterHeaterSalesByStartDate}
                    setFilterHeaterSalesByEndDate={setFilterHeaterSalesByEndDate}
                    setFilterHeaterSalesByCustomer={setFilterHeaterSalesByCustomer}
                    
                    heaterSales_heaterID={heaterSales_heaterID}
                    setHeaterSalesHeaterID={setHeaterSalesHeaterID}

                    heaterSales_startDate={heaterSales_startDate}
                    setHeaterSalesstartDate={setHeaterSalesstartDate}

                    heaterSales_endDate={heaterSales_endDate}
                    setHeaterSalesEndDate={setHeaterSalesEndDate}

          
                    heaterSales_customer={heaterSales_customer}
                    setHeaterSalesCustomer={setHeaterSalesCustomer}
                                
                    
                />


                <DownloadStovePaymentRecords
                    state={dl_stove_payments}
                    setDownloaderState={setDlStovePayments}
                    download={downloadStovePaymentRecords}


                    filter_pellet_sales_by_startDate={filter_stove_payment_by_startDate}
                    filter_pellet_sales_by_endDate={filter_stove_payment_by_endDate}
                    filter_pellet_sales_by_client={filter_stove_payment_by_client}
                    filter_pellet_sales_by_recorder={filter_stove_payment_by_recorder}

                    setFilterPelletSalesByStartDate={setFilterStovePaymentByStartDate}
                    setFilterPelletSalesByEndDate={setFilterStovePaymentByEndDate}
                    setFilterPelletSalesByClient={setFilterStovePaymentByClient}
                    setFilterPelletSalesByRecorder={setFilterStovePaymentByRecorder}

                    pelletSales_startDate={stove_payment_startDate}
                    setPelletSalesStartDate={setStovePaymentStartDate}

                    pelletSales_endDate={stove_payment_endDate}
                    setPelletSalesEndDate={setStovePaymentEndDate}

                    pelletfilterByRecorder={stovePaymentFilterRecorder}
                    setFilterPelletsByRecorder={setFilterStovePaymentRecorder}

                    pelletfilterByClient={stovePaymentFilterClient}
                    setFilterPelletsByClient={setFilterStovePaymentClient}
                                
                    
                />

                <DownloadPelletSales
                    state={dl_pellet_sales}
                    setDownloaderState={setDlPelletSales}
                    download={downloadPelletsalesRecords}

                    filter_pellet_sales_by_startDate={filter_pellet_sales_by_startDate}
                    filter_pellet_sales_by_endDate={filter_pellet_sales_by_endDate}
                    filter_pellet_sales_by_shop={filter_pellet_sales_by_shop}
                    filter_pellet_sales_by_recorder={filter_pellet_sales_by_recorder}

                    setFilterPelletSalesByStartDate={setFilterPelletSalesByStartDate}
                    setFilterPelletSalesByEndDate={setFilterPelletSalesByEndDate}
                    setFilterPelletSalesByShop={setFilterPelletSalesByShop}
                    setFilterPelletSalesByRecorder={setFilterPelletSalesByRecorder}

                    pelletSales_startDate={pelletSales_startDate}
                    setPelletSalesStartDate={setPelletSalesStartDate}

                    pelletSales_endDate={pelletSales_endDate}
                    setPelletSalesEndDate={setPelletSalesEndDate}

                    pelletfilterByRecorder={pelletfilterByRecorder}
                    setFilterPelletsByRecorder={setFilterPelletsByRecorder}

                    pelletfilterByShop={pelletfilterByShop}
                    setFilterPelletsByShop={setFilterPelletsByShop}


                />



                <DownloadPelletDebts
                    state={dl_pellet_debts}
                    setDownloaderState={setDlPelletDebts}
                    download={downloadPelletDebtsRecords}

                    filter_pellet_sales_by_startDate={filter_pellet_debts_by_startDate}
                    filter_pellet_sales_by_endDate={filter_pellet_debts_by_endDate}
                    filter_pellet_sales_by_shop={filter_pellet_debts_by_shop}
                    filter_pellet_debts_by_client={filter_pellet_debts_by_client}

                    setFilterPelletSalesByStartDate={setFilterPelletSalesByStartDate}
                    setFilterPelletSalesByEndDate={setFilterPelletSalesByEndDate}
                    setFilterPelletSalesByShop={setFilterPelletSalesByShop}
                    setFilterPelletDebtsByClient={setFilterPelletDebtsByClient}

                    pelletSales_startDate={pelletSales_startDate}
                    setPelletSalesStartDate={setPelletSalesStartDate}

                    pelletSales_endDate={pelletSales_endDate}
                    setPelletSalesEndDate={setPelletSalesEndDate}

                    pelletdebtfilterByClient={pelletdebtfilterByClient}
                    setPelletFilterDebtsByClient={setPelletFilterDebtsByClient}

                    pelletfilterByShop={pelletfilterByShop}
                    setFilterPelletsByShop={setFilterPelletsByShop}


                />


                
               


                <DownloadPelletProduction
                    state={dl_pellet_production}
                    setDownloaderState={setDlPelletProduction}
                    download={downloadPelletProductionRecords}

                    filter_pellet_sales_by_startDate={filter_pellet_production_by_startDate}
                    filter_pellet_sales_by_endDate={filter_pellet_production_by_endDate}
                    filter_pellet_sales_by_shop={filter_pellet_production_by_workshop}
                    filter_pellet_sales_by_recorder={filter_pellet_production_by_recorder}

                    setFilterPelletSalesByStartDate={setFilterPelletProductionByStartDate}
                    setFilterPelletSalesByEndDate={setFilterPelletProductionByEndDate}
                    setFilterPelletSalesByShop={setFilterPelletProductionByWorkshop}
                    setFilterPelletSalesByRecorder={setFilterPelletProductionByRecorder}

                    pelletSales_startDate={pelletProduction_startDate}
                    setPelletSalesStartDate={setPelletProductionStartDate}

                    pelletSales_endDate={pelletProduction_endDate}
                    setPelletSalesEndDate={setPelletProductionEndDate}

                    pelletfilterByRecorder={pelletProductionFilterByRecorder}
                    setFilterPelletsByRecorder={setFilterProductionByRecorder}

                    pelletfilterByShop={pelletProductionFilterByShop}
                    setFilterPelletsByShop={setFilterProductionByShop}

                />
                
                
           
                <DownloadDoc 
                    state={downloader_state} 
                    setDownloaderState={setDownloaderState}
                    
                />

                <Dialog
                    open={confrim_state}
                    onClose={closeConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Action"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to perform this action ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog}>DECLINE</Button>
                        <Button onClick={accept}>
                            ACCEPT
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Fade>
    )
}

/*
    <DownloadPelletDebts

        state={dl_pellet_sales}
        setDownloaderState={setDlPelletSales}
        download={downloadPelletsalesRecords}

        filter_pellet_sales_by_startDate={filter_pellet_sales_by_startDate}
        filter_pellet_sales_by_endDate={filter_pellet_sales_by_endDate}
        filter_pellet_sales_by_shop={filter_pellet_sales_by_shop}
        filter_pellet_sales_by_recorder={filter_pellet_sales_by_recorder}

        setFilterPelletSalesByStartDate={setFilterPelletSalesByStartDate}
        setFilterPelletSalesByEndDate={setFilterPelletSalesByEndDate}
        setFilterPelletSalesByShop={setFilterPelletSalesByShop}
        setFilterPelletSalesByRecorder={setFilterPelletSalesByRecorder}

        pelletSales_startDate={pelletSales_startDate}
        setPelletSalesStartDate={setPelletSalesStartDate}

        pelletSales_endDate={pelletSales_endDate}
        setPelletSalesEndDate={setPelletSalesEndDate}

        pelletfilterByRecorder={pelletfilterByRecorder}
        setFilterPelletsByRecorder={setFilterPelletsByRecorder}

        pelletfilterByShop={pelletfilterByShop}
        setFilterPelletsByShop={setFilterPelletsByShop}


    />
*/