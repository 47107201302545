
import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';


import '../styles/utils.css'

import main, { formatCash, Refresh, format_cash } from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import ProfileImage from '../resources/icons/profile.png'
import { useOutletContext } from "react-router-dom";
import { Paper } from '@mui/material';
import { useLoaderData } from "react-router-dom";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export function loader({params}){
    console.log(params)
    const parameters = params.parameters.split('&')
    
    const rules = {}
    for(const param of parameters){
        const s = param.split('=')
        console.log(param)
        if(s.length === 2){
            rules[s[0]] = s[1]
        }
    }

    console.log(rules)

    return rules
    
    
}   

export default function PayForStove(){
   
    const [rules ] = useState(useLoaderData())
    const [start, setStart] = useState(false)
    const [customer, setCustomer] = useState('')
    const [customer_list, setCustomerList] = useState([])
    const [ customer_uid, setCustomerUID ] = useState('')

    const [setSnack, setAlert, username ] = useOutletContext()
    const [debts, setDebts] = useState([])
    const [ payment_date, setPaymentDate ] = useState(new Date()) 

 
 

    
  

    function serchForClients(elm){
        setCustomer(elm.target.value)
        
        fetch(main.server + `/clients?client=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setCustomerList(res.data.client_list)
            }
        })
    }
  
    const required_stovSale_inputs = [
 
        { input: customer_uid },
      
    ]
    
    function validate_stovSale_Form(){
        let incomplete = false
        for(const input of required_stovSale_inputs){
            if(!input.input){
                incomplete = true
            }
        }
        if(incomplete){
            setSnack({
                message:'incomplete form',
                type:'error'
            })
        }
        return incomplete
    }
   
    function payDebts(){
        if(!validate_stovSale_Form() && debts.length > 0){
            const form = new FormData()

            const f = {
                amount: amount,
                client: customer_uid,
                recorded_by: username,
                date: payment_date.toDateString(),
            }
            

            form.append('form', JSON.stringify(f))


            fetch(main.server + '/pay_for_stoves',
                {
                    method: "POST",
                    body:form,
                }
            ).then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                    setAlert({ message:'stove debts payed', type:'success'})
                    Refresh()
                }else{
                    setAlert({ message:res.message, type:'error'})
                }
            })
        }
    
    }
    const [ amount, setAmount ] = useState('')
    const [ total_debt, setTotalDebt ] = useState(0)

    function handleAmount(event){

        const value = event.target.value

        console.log(value, total_debt)
 
     
        if(value.search(',') !== -1){
            let clean = value.replaceAll(',','')

            if((Number(clean) && Number(clean) <= total_debt) || clean === '' || clean === '0'){
              setAmount(Number(clean))
            }
        }else{
            if((Number(value) && Number(value) <= total_debt) || value === '' || value === '0'){
                setAmount(Number(value))
            } 
        } 
        
    }
    function selectClient(name, uid){
        setCustomer(name)
        setCustomerUID(uid)
      
        setCustomerList([])
        fetch(main.server + `/get_client_debts?client=${uid}`).then(resp => resp.json()).then(result =>{
            setDebts(result.data.debts)
            let total_debt = 0
            for(const debt of result.data.debts){
                total_debt += Number(debt.price) - Number(debt.amount_paid)
            }
            setTotalDebt(total_debt)
        })
    }

    function handleClicks(event){
       
        if(!event.nativeEvent.srcElement.classList.contains('searchSys')){
            setCustomerList([])
        }
        
    }
    
    useEffect(() => {
        if(!start){
            setSnack({
                message: 'page loaded',
            })

        
            if(rules.client_name){
                console.log(rules)
                selectClient(rules.client_name, rules.clientID)
                
            }

            setStart(true)
        }
    })
    return(
        <Fade in={true}>
            <div className="page" onClick={handleClicks}>
                <div className="system_title">
                    <div>Make stove payment</div>
                </div>

                { debts.length > 0 ? 
                    <div className='clientDebt pad20'>
                        <div>    
                            {debts.length > 0 ?
                                <div className="padv10">
                                    <div className="padv10">Debts: </div>
                                    <div>
                                        {debts.map((elm, index) => (
                                            <div className='debt' key={index}>
                                                <div className="info_value_pair">
                                                    <div>stove serial number: </div>
                                                    <div>{elm.stoveID}</div>
                                                </div>
                                            
                                                <div className="info_value_pair">
                                                    <div>price: </div>
                                                    <div>{formatCash(elm.price)}</div>
                                                </div>
                                                <div className="info_value_pair">
                                                    <div>amount paid: </div>
                                                    <div>{formatCash(elm.amount_paid)}</div>
                                                </div>
                                            
                                            </div>
                                        ))}
                                    </div>
                                </div>: ''
                            }
                        </div>
                    </div>
                :''}

                <div className="form_page_main">
                    

                    <div className="form_main form_centered">
                        
                        <div className="form_section">
                        
                            <div className="form_section_main">

                                <div>
                                    <div className="authenticatorTitle">Recorded by:</div>
                                    <div>
                                        <div className="authenticator_final" >
                                            
                                            <div className="flex_row flex_align_center">
                                                <div className="authenticator_avatar">
                                                    <img src={ProfileImage} alt="user image"/>
                                                </div>
                                                <div className="padh10">{username}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Payment date"
                                            value={payment_date}
                                            variant="standard"
                                            onChange={(newstartDate) => {
                                                setPaymentDate(newstartDate)
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                               
                                
                                
                       


                                
                            


                                
                            </div>
                        </div>

                        <div className="form_section">
                        
                            <div className="form_section_main">
                                
                                <div>
                                    <TextField value={customer} onChange={serchForClients} sx={{width: 250}} className="text_field" id="standard-basic" label="Customer" variant="standard" />



                                    <div className="filterSearchRes">
                                        {customer_list.length > 0 ?
                                            <Paper elevation={1} className="searchRBody searchSys">
                                                {customer_list.map((elm,ind) => (
                                                    <div className="generalSearchResult searchSys" data-uid={elm.UID} onClick={() => selectClient(elm.name, elm.UID)} key={ind}>
                                                        {elm.name}
                                                    </div>
                                                ))}
                                                
                                            </Paper>
                                        :''}

                                        
                                    
                                    </div>

                                    
                                </div>
                                

                                <div><TextField value={format_cash(amount)} onChange={handleAmount} sx={{width: 250}} className="text_field" id="standard-basic" helperText={`${total_debt > 0 ? `payable amount ${formatCash(total_debt)}`:'payable amount 0, select a client to continue'}`} label="Amount" variant="standard" /></div>
                            </div>
                        </div>
                        
                    
                    
                        
                    
                    </div>
                </div>
                <div className="uni_procced_btn">
                    <div className="btn_filled" onClick={payDebts}>
                        <div>
                            pay stove debts
                        </div>
                    </div>
                </div>
               
            </div>
        </Fade>
    )
}