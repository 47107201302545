import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';


export default function DownloadDocument(props) {
   
    function closeDownloader(){
        props.setDownloaderState(false)
    }


    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);
  
    const progressRef = React.useRef(() => {});
    React.useEffect(() => {
      progressRef.current = () => {
        if (progress > 100) {
          setProgress(0);
          setBuffer(10);
        } else {
          const diff = Math.random() * 10;
          const diff2 = Math.random() * 10;
          setProgress(progress + diff);
          setBuffer(progress + diff + diff2);
        }
      };
    });
  
    React.useEffect(() => {
      const timer = setInterval(() => {
        progressRef.current();
      }, 500);
  
      return () => {
        clearInterval(timer);
      };
    }, []);

    return (
        
        <Dialog
            open={props.state}
            onClose={closeDownloader}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">
            Download Document
        </DialogTitle>
        <DialogContent>
            <div className="padv10">downloading document...</div>
            <div>
                <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />

            </div>
            
        </DialogContent>
        <DialogActions>
            <Button onClick={closeDownloader}>
                Cancel
            </Button>
            
        </DialogActions>
    </Dialog>

  );
}