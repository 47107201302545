import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../styles/utils.css'
import '../styles/users.css'
import { formatCash } from '../resources/code/utils'
import DialogTitle from '@mui/material/DialogTitle';
import CheckBox from '@mui/material/Checkbox';
import main from '../resources/code/utils'


export default function EditBalances(props){
    const original_pellet_cash_balance = props.pellet_cash_balance
    const original_stove_cash_balance = props.stove_cash_balance
    const original_account_balalnce = props.account_balance

    function handlePelletCashSwitch(){
        props.setEditPelletCashBalance(prev => !prev)
    }
    function handleStoveCashSwitch(){
        props.setEditStoveCashBalance(prev => !prev)
    }
    function handleAccountSwitch(){
        props.setEditAccountBalance(prev => !prev)
    }
    function handlePelletCash(event){
        const val = event.target.value
        if(Number(val) || val === '0'|| val === ''){
            props.setPelletCashBalance_(val)
        }

    }
    function handleStoveCash(event){
        const val = event.target.value
        if(Number(val) || val === '0'|| val === ''){
            props.setStoveCashBalance_(val)
        }

    }
    function handleAccount(event){
        const val = event.target.value
        if(Number(val) || val === '0' || val === ''){
            props.setAccountBalance_(val)
        }

    }
    function updateDescription(event){
        props.setWithDrawalReason(event.target.value)
    }

    function getEditBalForm(){
        const x = new FormData()
        x.append('pellet_cash_balance', props.pellet_cash_balance_)
        x.append('stove_cash_balance', props.stove_cash_balance_)
        x.append('account_balance', props.account_balance_)
        x.append('reason', props.reason)
        x.append('transactor', 'bright')

        return x
    }
    function EditBalances(){
        console.log(props.cash_balance, props.account_balance)
        if(props.pellet_cash_balance_ === original_pellet_cash_balance && props.account_balance_ === original_account_balalnce && props.stove_cash_balance === original_stove_cash_balance){
            props.setSnack({
                message:'No changes detected.'
            })
        }else{
            if(props.reason){
                const form = getEditBalForm()

                fetch(main.server + '/edit_balances',{
                    method:"POST",
                    body:form
                }).then( response =>{
                    if(response.ok){
                        return response.json()
                    }
                    throw response
                }).then(res => {
                    if(res.request_status){
                        props.setAlert({
                            message:'edit successful'
                        })
                        setTimeout(() => { window.location.reload() }, 4000)
                    }
                    
                })
            }else if(!props.reason){
                props.setSnack({
                    message:'Provide a reason for the edit.'
                })
            }
        }
    }

    return(
        <Dialog
            open={props.state}
            onClose={props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <div className='pad10'>
                    <div className="info_value_pair padv10">
                        <div>pellet cash balanace: </div>
                        <div>{formatCash(props.pellet_sales_balance)}</div>
                    </div>

                    <div className="info_value_pair padv10">
                        <div>stove cash balanace: </div>
                        <div>{formatCash(props.stove_sales_balance)}</div>
                    </div>

                    <div className="info_value_pair padv10">
                        <div>account balanace: </div>
                        <div>{formatCash(props.account_balance)}</div>
                    </div>



                    <div className="filterInput padv10">
                        <div>
                            <CheckBox checked={props.edit_pellet_cash_balance} onChange={handlePelletCashSwitch}/> edit pellet cash balance
                        </div>
                        <div className="pad10"><TextField disabled={!props.edit_pellet_cash_balance} value={props.pellet_cash_balance_} onChange={handlePelletCash} sx={{width: 250}} className="text_field" id="standard-basic" label="pellet cash balance" variant="standard" /></div>
                    
                        
                    </div>
                    <div className="filterInput padv10">
                        <div>
                            <CheckBox checked={props.edit_stove_cash_balance} onChange={handleStoveCashSwitch}/> edit stove cash balance
                        </div>
                        <div className="pad10"><TextField disabled={!props.edit_stove_cash_balance} value={props.stove_cash_balance_} onChange={handleStoveCash} sx={{width: 250}} className="text_field" id="standard-basic" label="stove cash balance" variant="standard" /></div>
                    
                        
                    </div>


                    <div className="filterInput padv10">

                        <div>
                            <CheckBox checked={props.edit_account_balance} onChange={handleAccountSwitch}/> edit account balance
                        </div>
                        <div className="pad10"><TextField disabled={!props.edit_account_balance} value={props.account_balance_} onChange={handleAccount} sx={{width: 250}} className="text_field" id="standard-basic" label="account balance" variant="standard" /></div>

                    </div>

                    <div className="filterInput padv10">
                        <TextField
                            sx={{width: 250}}
                            className="text_field"
                            id="standard-multiline-static"
                            label="reason for editing"
                            multiline
                            rows={4}
                        
                            value={props.reason}
                            variant="standard"
                            onChange={updateDescription}
                        />
                    </div>
                    
                </div>
            </DialogContent>
            <DialogActions>
              
                <Button onClick={EditBalances}>
                    EDIT SYSTEM BALANCES
                </Button>
            </DialogActions>
        </Dialog>
    )
}