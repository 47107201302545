import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import UploadIcon from '@mui/icons-material/Upload';
import main, { get_file_size } from '../resources/code/utils'
import ProfileImage from '../resources/icons/profile.png'
import '../styles/utils.css'


export default function UpdateProfileImage(props) {
   
    const [profileImage, setProfileImage ] = useState(ProfileImage)
    const [profileImageFile, setProfileImageFile] = useState(false)
    const [image_name, setImageName] = useState('image name')
    const [image_size, setImageSize] = useState('0 B')
    const profile_image_input = useRef()

    function openImageFile(){
        console.log(profile_image_input)
        profile_image_input.current.click()
    }
    function HandleImageUpload(elm){
        const file = elm.target.files[0]
        setImageSize(get_file_size(Number(file.size)))
        setProfileImageFile(file)
        setImageName(file.name)
        const reader = new FileReader()
        reader.onload = function() {
            setProfileImage(reader.result);
        }
        reader.readAsDataURL(file)
    }

    function closeUpdater(){
        props.close()
    }
    function updateImage(){
        if(!profileImageFile){
            props.setSnack({
                message: 'Select an image first'
            })   
        }
        const form = new FormData()
        const f = {
            UID: props.userID
        }

        form.append('form', JSON.stringify(f))
      
        form.append('profile_image', profileImageFile)

        fetch(main.server + '/modify_user_info', {
            method: 'POST',
            body: form
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                props.setAlert({
                    message:'profile image updated',
                    type:'success'
                })
                setTimeout(() => window.location.assign(`/account/${props.userID}`),5000)
            }
            
        })
    }
    

    

    return (
        
        <Dialog
            open={props.state}
            onClose={closeUpdater}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
        <DialogTitle id="alert-dialog-title">
            Update Profile Image
        </DialogTitle>
        <DialogContent>
            <div id="update_profile_image_main">

                <div className="form_section_main">
                    <div className="ProfileImage">
                        <div className="profileImageContainer">
                            <img src={profileImage} />
                        </div>
                        <div className="profileImageName">{image_name}</div>
                        <div className="profileImageSize">{image_size}</div>
                        
                    </div>
                    <div className="btn outlined" onClick={openImageFile}>
                        <div>
                            <UploadIcon />
                        </div>
                        <div>
                            upload profile image
                        </div>
                    </div>
                    <input ref={profile_image_input} onChange={HandleImageUpload} className="hidden_file_input" type="file"/>
                </div>

            </div>
            
        </DialogContent>
        <DialogActions>
            <Button onClick={closeUpdater} >
                cancel
            </Button>
            <Button onClick={updateImage} >
                update image
            </Button>
        </DialogActions>
    </Dialog>

  );
}