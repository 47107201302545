
import { useEffect, useState, useRef } from 'react'
import { useLoaderData } from "react-router-dom";
import TextField from '@mui/material/TextField';
import '../styles/utils.css'
import UploadIcon from '@mui/icons-material/Upload';
import IDImage from '../resources/systemIcons/idImage_placeholder.png'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import main, { get_file_size } from '../resources/code/utils'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';


export function loader({params}){
    console.log(params)

    return fetch(main.server + `/client_info?i=${params.id}`).then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  

export default function EditClientInfo(){
    
    

    const [data, setData ] = useState(useLoaderData())
    const [filled, setFilled ] = useState(false)

    const [start, setStart] = useState(false)
    const [name, setName] = useState('')
   


    const [phone_number1, setPhoneNumber1] = useState('')
    const [phone_number2, setPhoneNumber2] = useState('')
    const [description, setDescription ] = useState('')

    const [geo_coords, setGeoCoords] = useState('')
    
    const [ IdImage, setIDImage ] = useState(false)
    const [ IDImageFile, setIDImageFile ] = useState(false)
    const [ location, setLocation ] = useState('')
    const [ region, setRegion ] = useState('')
    const [ image_name, setImageName ] = useState('image name')
    const [ image_size, setImageSize ] = useState('0 B')
    const [ setSnack, setAlert, username ] = useOutletContext()

    const [id_number, setIdNumber] = useState('')
    const [ basic, setBasic ] = useState({regions:[], id_types: []})

    const phone_number = 'phone_number'
    const text = 'text'
    const number = 'number'

    const profile_image_input = useRef()

    const [id_type, setIdType] = useState('')


    const handleIDChange = (event: SelectChangeEvent) => {
      setIdType( event.target.value )
    }

    const handleRegionChange =  (event: SelectChangeEvent) => {
        setRegion( event.target.value )
    }

    
    function Update(elm, updater, type){
        const value = elm.target.value
        
        if(type === phone_number){
            if(Number(value) || value === '' || value === '0'){
                updater(value)
            }    
        }if(type === number){
            if(Number(value) || value === '' || value === '0'){
                updater(Number(value))
            }    
        }
        else if (type === text){
            updater(value)
        }
    }

    
    function openImageFile(){
        console.log(profile_image_input)
        profile_image_input.current.click()
    }
    function HandleImageUpload(elm){
        const file = elm.target.files[0]
        setImageSize(get_file_size(Number(file.size)))
        setIDImageFile(file)
        setImageName(file.name)
        const reader = new FileReader()
        reader.onload = function() {
            setIDImage(reader.result);
        }
        reader.readAsDataURL(file)
    }

  
    
    
    

    function fetchMain(){
        console.log('fetch main')
        fetch(main.server + '/basic').then(resp => resp.json()).then(result =>{
            setBasic(result.data)
        })
    }
    function modifyClientInfo(event){
        const form = new FormData()
        const f = {
            name:name,
            
            
            location: location,
            region: region,
           
            created_by: username,
            UID: event.target.dataset.uid,
        }
        
        if(phone_number1){
            f['phone_number1'] = phone_number1
        }
        if(phone_number2){
            f['phone_number2'] = phone_number2
        }
        if(id_type){
            f['id_type'] = id_type
        }
        if(id_number){
            f['id_number'] = id_number
        }
        if(geo_coords){
            f['geo_coords'] = geo_coords
        }   
        if(description){
            f['description'] = description
        }
    
    
        form.append('form', JSON.stringify(f))

        if(IDImageFile){
            form.append('id_image', IDImageFile)
        }

        fetch(main.server + '/modify_client_info', {
            method: 'POST',
            body: form
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                setAlert({
                    message:'client modified',
                    type:'success'
                })
                setTimeout(() => window.location.assign('/clients'),5000)
            }
            
        })
    }

    useEffect(() => {
        if(!start){
            fetchMain()
            setSnack({
                message: 'page loaded',
            })

            setStart(true)
        }
        if(! filled){
            setName(data.name)
          
            setPhoneNumber1(data.phone_number1)
            setPhoneNumber2(data.phone_number2)
            setDescription(data.description)
            setGeoCoords(data.geo_coords)
            setIDImage(data.id_image)
            setLocation(data.location)
            setRegion(data.region)
            setIdType(data.id_type)
            setIdNumber(data.id_number)
            setFilled(true)
        }
    })
    
    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>Client information</div>
                </div>
                <div className="form_main form_centered">
                    
                    <div className="form_section">
                        <div className="form_section_title">client identification</div>
                        <div className="form_section_main">
                            
                            <div className="ProfileImage">
                                <div className="clientIdImageContainer">
                                    <img src={IdImage ? main.server + `/media/{IdImage}` : IDImage} alt="client id image"/>
                                </div>
                                <div className="profileImageName">{image_name}</div>
                                <div className="profileImageSize">{image_size}</div>
                                
                            </div>
                            <div className="btn outlined" onClick={openImageFile}>
                                <div>
                                    <UploadIcon />
                                </div>
                                <div>
                                    change ID image
                                </div>
                            </div>
                            <input ref={profile_image_input} onChange={HandleImageUpload} className="hidden_file_input" type="file"/>
                            
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">ID type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={id_type}
                                    
                                    label="ID type"
                                    onChange={handleIDChange}
                                    variant="standard"
                                >   
                                    {basic.id_types.map((elm, ind) => (
                                        <MenuItem value={elm.name} key={ind}>{elm.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div>
                                <TextField  value={id_number} onChange={(elm) => Update(elm, setIdNumber, number)} sx={{width: 250}} className="text_field" id="standard-basic" label="ID number" variant="standard" />
                                <div><IconButton aria-label="delete"><DriveFileRenameOutlineIcon /></IconButton></div>
                            </div>
                        
                        </div>
                    </div>
                    <div className="form_section">
                        <div className="form_section_title">basic information</div>
                        <div className="form_section_main">
                            <div><TextField  value={name} onChange={(elm) => Update(elm, setName, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Name" variant="standard" /></div>
                           
                            <div><TextField  value={phone_number1} onChange={(elm) => Update(elm, setPhoneNumber1, phone_number)} sx={{width: 250}} className="text_field" id="standard-basic" label="Phone number 1" variant="standard" /></div>
                            <div><TextField  value={phone_number2} onChange={(elm) => Update(elm, setPhoneNumber2, phone_number)} sx={{width: 250}} className="text_field" id="standard-basic" label="Phone number 2" variant="standard" /></div>
                            
                            
                        </div>
                    </div>
                    <div className="form_section">
                        <div className="form_section_main">
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={region}
                                    label="ID type"
                                    onChange={handleRegionChange}
                                    variant="standard"
                                    
                                >   
                                    {basic.regions.map((elm, ind) => (
                                        <MenuItem value={elm.name} key={ind}>{elm.name}</MenuItem>
                                    ))}
                                    
                                
                                </Select>
                            </FormControl>
                            
                          
                            <div><TextField  value={location} onChange={(elm) => Update(elm, setLocation, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Location" variant="standard" /></div>
                            <div><TextField  value={geo_coords} onChange={(elm) => Update(elm, setGeoCoords, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Geo coordinates" variant="standard" /></div>
                            
                            <div>
                                <TextField
                                    sx={{width: 250}}
                                    className="text_field"
                                    id="standard-multiline-static"
                                    label="user description"
                                    multiline
                                    rows={4}
                                    value={description}
                                    variant="standard"
                                    onChange={(elm) => Update(elm, setDescription, text)}
                                    
                                />
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                <div className="submit_form">
                
                    <div className="btn outlined" data-uid={data.UID} onClick={modifyClientInfo}>
                        <div>
                            Modify client info
                        </div>
                    </div>
                    
                </div>
              
            </div>
        </Fade>
    )
}