import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


import { _DateTime, formatCash } from '../resources/code/utils'



function descendingComparator(a, b, orderBy) {
 
  if(orderBy !== 'date_created'){
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }else{
    return  new Date(b[orderBy]) - new Date(a[orderBy])
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'date_created',
    numeric: false,
    disablePadding: true,
    label: 'Date time',
  },
  {
    id: 'shop',
    numeric: false,
    disablePadding: false,
    label: 'Shop',
  },
  {
    id: 'transacted_by',
    numeric: true,
    disablePadding: false,
    label: 'Transacted by',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },

];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
  props;
  
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
 
  onRequestSort: PropTypes.func.isRequired,

  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete Record">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ): ''}  
    
      <Tooltip title="Download table" >
        <IconButton onClick={props.download_table}>
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Filter list" >
        <IconButton onClick={props.filter}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const [ start, setStart ] = useState(false)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  
  const rows =  props.transfers
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const numPages = Math.ceil(rows.length / rowsPerPage)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.rid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

 

  const handleChangePage = (event, newPage) => {
    if( newPage === numPages - 1 && ! props.eof ){
      console.log('called')
      props.fetch_more()
     
    }
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };


  /*
    rowsPerPage rows
  */
  const isSelected = (rid) => selected.indexOf(rid) !== -1;

  // Avoid a layout jump when reaching the last props.page with empty rows.
  const emptyRows =
    props.page > 0 ? Math.max(0, (1 + props.page) * rowsPerPage - rows.length) : 0;

  const getTotal = () =>{
    const p = rows.slice(props.page * rowsPerPage, props.page * rowsPerPage + rowsPerPage)
    let total = 0
    for( const record of p){
      total += Number(record.amount)
    }
    return total
  }
  
  function empty_row(){
    return(
      <TableRow role="checkbox" tabIndex={-1}>
        <TableCell padding="checkbox"></TableCell>
        <TableCell component="th" scope="row" padding="none"></TableCell>
        <TableCell align="left"></TableCell>

        <TableCell align="left"></TableCell>
        <TableCell align="left"></TableCell>
      
      </TableRow>
    )
  }

  useEffect(() =>{
    if(!start){
      setStart(true)
    }
  })

  return (
   
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} filter={props.filter} title={props.title} download_table={props.download}/>
        <TableContainer>
          <Table
            sx={{ minWidth: 950 }}
            aria-labelledby="tableTitle"
            size='medium'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(props.page * rowsPerPage, props.page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.rid);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow onClick={props.view_record} data-key={index} className="table_row" data-uid={row.UID} hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={index} selected={isItemSelected}>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">{_DateTime(row.date_created)}</TableCell>
                      <TableCell align="left">{row.center}</TableCell>
                      <TableCell align="left">{row.recorded_by}</TableCell>
                      <TableCell align="left">{row.amount} kg</TableCell>
                    
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={9} />
                </TableRow>
              )}




              {empty_row()}
            
              <TableRow role="checkbox" tabIndex={-1}> 
                <TableCell padding="checkbox"></TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{fontWeight: 600}}>
                  PAGE TOTAL
                </TableCell>
        

                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left" sx={{fontWeight: 600}}>{getTotal()} kg</TableCell>
              </TableRow>


          
              <TableRow role="checkbox" tabIndex={-1}> 
                <TableCell padding="checkbox"></TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{fontWeight: 600,}}>
                  <span>GRAD TOTAL</span> {(props.dtf)? <span>FROM: <span className="red">{new Date(props.startDate).toDateString()}</span> TO: <span className="red">{new Date(props.endDate).toDateString()}</span></span>:<span className="red">FOR {props.transfers.length} PAGES</span>}
                </TableCell>
           

                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left" sx={{fontWeight: 600, color: 'red'}}>{props.total.amount} kg</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={props.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
  );
}