import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import main  from '../resources/code/utils'




export default function UpdatePassword(props) {
   
    const [ password, setPassword ]  = useState('')
    const [ new_password, setNewPassword ] = useState('')
    const [ repeat_new_password, setRepeatNewPassword ] = useState('')
    const [ error, setError ] = useState(false)
    const [ start, setStart ] = useState(false)
    
    function updateImage(){
        
        const form = new FormData()
        const f = {
            UID: props.userID,
            password:password,
            new_password:new_password
        }

        form.append('form', JSON.stringify(f))
      
    
        fetch(main.server + '/change_password', {
            method: 'POST',
            body: form
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                props.setAlert({
                    message:'password updated',
                    type:'success'
                })
                setTimeout(() => window.location.assign(`/account/${props.userID}`),5000)
            }else{
                props.setAlert({
                    message: result.message,
                    type:'error'
                })
               
            }
            
        })
    }
    
    function set_new_password(event){
        setNewPassword(event.target.value)
    }
    function set_old_password(event){
        setPassword(event.target.value)
    }

    function set_repeat_password(event){
        if(new_password.length > 0){
            const val = event.target.value
            setRepeatNewPassword(val)
            if(val !== new_password){
                setError(true)
            }else{
                setError(false)
            }
        }
    }
    function closeUpdater(){
        setPassword('')
        setNewPassword('')
        setError(false)
        setRepeatNewPassword('')
        props.close()
    }

    useEffect(()=>{
        if(!start){
            setPassword('')
            setStart(true)
        }
    })


    return (
        
        <Dialog
            open={props.state}
            onClose={closeUpdater}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                Update Password
            </DialogTitle>
            <DialogContent>
                <div id="update_password_main">
                    <div className="pad20"><TextField type="password"  value={password} onChange={set_old_password} sx={{width: 250}} className="text_field" id="standard-basic" label="Old password" variant="standard" /></div>
                    <div className="pad20"><TextField type="password"  value={new_password} onChange={set_new_password} sx={{width: 250}} className="text_field" id="standard-basic" label="New password" variant="standard" /></div>
                    <div className="pad20"><TextField type="password"  value={repeat_new_password} onChange={set_repeat_password} error={error} helperText={error? 'Passwords don\'t match': '' }  sx={{width: 250}} className="text_field" id="standard-basic" label="Re-enter new password" variant="standard" /></div>
                </div>        
            </DialogContent>
            <DialogActions>
                <Button onClick={closeUpdater} >
                    cancel
                </Button>
                <Button onClick={updateImage} >
                    update password
                </Button>
            </DialogActions>
        </Dialog>
    )

}