import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { _DateTime, formatCash } from '../resources/code/utils'
import main from '../resources/code/utils'

import StoveSalesTable from '../tables/stove_sales_table'

export default function ViewStoveSale(props) { 

    const [start, setStart] = useState(false)


    function closeViewer(){

      setStart(false)
      props.close(false)
    }


    useEffect(() => {
      if(!start){
        /*
        fetch(main.server + '/sales_information').then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
              setData(res.data)
            }
            
        })*/
      
        setStart(true)
      }
     
    })
    


    return (
        
        <Dialog
          open={props.state}
          onClose={closeViewer}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="md"
        >
        <DialogTitle id="alert-dialog-title">
            Stove Sale Info
        </DialogTitle>
        <DialogContent>
       
          <div>
            <div>
              
              <div className="info_value_pair padv10">
                <div>Customer: </div>
                <div>{props.data.client}</div>
              </div>
            
              <div className="info_value_pair padv20">
                <div>Amount owed: </div>
                <div>{formatCash(props.data.price)}</div>
              </div>
              <div className="info_value_pair">
                <div>Amount paid: </div>
                <div>{formatCash(props.data.amount_paid)}</div>
              </div>

        
            </div>

            <div className="padv20">
              <StoveSalesTable
                 startDate={props.stove_payment_startDate} 
                 endDate={props.stove_payment_endDate}  
                 //dtf={stove_payment_dtf} 
                 page={0} 
                 //setPage={setStovePaymentPage} 
                 totals={props.stove_sale_payments_total} 
                 sales={props.stove_sales} 
                 //fetch_more={fetchMoreStovePaymentRecords} 
                 //filter={FilterStovePaymentRecords} 
                 //view_record={ViewStovePayment}
                 download={props.downloadPelletsalesRecords}
              />
            </div>
            

          


          </div>
          
        
        </DialogContent>
        <DialogActions>
            <Button onClick={closeViewer}>
              Close
            </Button>
            
        </DialogActions>
    </Dialog>

  );
}