
import { useEffect, useState, useRef } from 'react'
import TextField from '@mui/material/TextField';
import '../styles/utils.css'
import { useLoaderData } from "react-router-dom";
import UploadIcon from '@mui/icons-material/Upload';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import main, { get_file_size } from '../resources/code/utils'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import StoreIcon from '../resources/systemIcons/shop_placeholder.png'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'



export function loader({params}){
    console.log(params)

    return fetch(main.server + `/view_shop_info?shop=${params.id}`).then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  

export default function ModifyShopInfo(){

    const [data ] = useState(useLoaderData())

    const [start, setStart] = useState(false)

    const [shop_name, setShopName] = useState('')

    const [ filled, setFilled] = useState(false)
    const [phone_number1, setPhoneNumber1] = useState('')
    const [phone_number2, setPhoneNumber2] = useState('')
    const [description, setDescription ] = useState('')
    const [geo_coords, setGeoCoords] = useState('')
    const [ street, setStreet ] = useState('')
    const [ region, setRegion ] = useState('')
    const [ storeImage, setStoreImage ] = useState( false )
    const [ StoreImageFile, setStoreImageFile ] = useState(false)
    const [ image_name, setImageName ] = useState('image name')
    const [ image_size, setImageSize ] = useState('0 B')
    const [cp, setCp] = useState('')
    const [ searchRes, setSearchRes ] = useState([])
    const [ contact_person , setContactPerson] = useState(['',''])

    const [ setSnack, setAlert, username, ] = useOutletContext()

    const [ basic, setBasic ] = useState({regions:[], id_types: []})

    const phone_number = 'phone_number'
    const text = 'text'
    const number = 'number'

    const profile_image_input = useRef()

 
    const handleRegionChange =  (event: SelectChangeEvent) => {
        setRegion( event.target.value )
    }

    
    function Update(elm, updater, type){
        const value = elm.target.value
        
        if(type === phone_number){
            if(Number(value) || value === '' || value === '0'){
                updater(value)
            }    
        }if(type === number){
            if(Number(value) || value === '' || value === '0'){
                updater(Number(value))
            }    
        }
        else if (type === text){
            updater(value)
        }
    }

    
    
    

    
    function modifyShopInfo(){

     
        const form = new FormData()
        
        const f = {
            UID:data.UID,
            created_by: username,
        }

        if(shop_name !== data.name){
            f['name'] = shop_name
        }
        if(phone_number1 !== data.phone_number1){
            f['phone_number1'] = phone_number1
            
        }
        if(phone_number2 !== data.phone_number2){
            f['phone_number2'] = phone_number2
        }
        if(description !== data.description){
            f['description'] = description
            
        }
        if(geo_coords !== data.geo_coords){
            f['geo_coords'] = geo_coords
            
        }
        if(street !== data.street){
            f['street'] = street
            
        }
        if(region !== data.region){
            f['region']=region 
        }
        if(contact_person[1]){
            f['contact_person'] = contact_person[1]
        }
        
        form.append('form', JSON.stringify(f))
        if(StoreImageFile){
            form.append('workshop_image', StoreImageFile)
        }

        fetch(main.server + '/modify_shop_info', {
            method: 'POST',
            body: form
        }).then(response => response.json()).then(result => {
            console.log(result)
            if(result.request_status){
                setAlert({
                    message:'shop info modified',
                    type:'success'
                })
                setTimeout(() => window.location.assign('/shops'),5000)
            }
            
        })
        
        
    }
    

    function fetchMain(){
        console.log('fetch main')
        fetch(main.server + '/basic').then(resp => resp.json()).then(result =>{
            setBasic(result.data)
        })
    }

    function openImageFile(){
        console.log(profile_image_input)
        profile_image_input.current.click()
    }
    function HandleImageUpload(elm){
        const file = elm.target.files[0]
        setImageSize(get_file_size(Number(file.size)))
        setStoreImageFile(file)
        setImageName(file.name)
        const reader = new FileReader()
        reader.onload = function() {
            setStoreImage(reader.result);
        }
        reader.readAsDataURL(file)
    }


    function searchClients(event){
        
        console.log(event.target.value)
        
        if(event.target.value === ''){
            setSearchRes([])
            return
        }

        fetch(main.server + `/clients?client=${event.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setSearchRes(res.data.client_list)
                setContactPerson(['',''])
            }
        })
            
        
    }

    function selectCustomer(event){
        const uid = event.target.dataset.uid
        const name = event.target.innerHTML
        setContactPerson([name, uid])
        setSearchRes([])
    }

    useEffect(() => {
        if(!start){
            fetchMain()
            setSnack({
                message: 'page loaded',
            })

            setStart(true)
        }
        if(!filled){
            setShopName(data.name)
            setPhoneNumber1(data.phone_number1)
            setPhoneNumber2(data.phone_number2)
            setDescription(data.description)
            setGeoCoords(data.geo_coords)
            setCp(data.contact_person)
            setStreet(data.street)
            setRegion(data.region)
            if(data.shop_image){
                setImageName(data.shop_image)
                setStoreImage(main.server + `/media/${data.shop_image}/`)
            }
          
            //const [ StoreImageFile, setStoreImageFile ] = useState(false)
            
            setFilled(true)
        }
    })
    
    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>New shop form</div>
                </div>
                <div className="form_main form_centered">
                   
                    <div className="ModStoreImage">
                        <div className="form_section_title">store image</div>
                        <div className="form_section_main">
                            <div className="modifyStoreImage">
                                <div className="clientIdImageContainer">
                                    <img src={storeImage ? storeImage: StoreIcon} alt="store image"/>
                                </div>
                                <div className="profileImageName wid100">{image_name}</div>
                                <div className="profileImageSize">{image_size}</div>
                            
                            </div>
                            <div className="btn outlined" onClick={openImageFile}>
                                <div>
                                    <UploadIcon />
                                </div>
                                <div>
                                    upload store image
                                </div>
                            </div>
                         
                            <input ref={profile_image_input} onChange={HandleImageUpload} className="hidden_file_input" type="file"/>
                            
                        </div>
                    </div>
                    <div className="form_section">
                        
                        <div className="form_section_main">
                            <input ref={profile_image_input} onChange={HandleImageUpload} className="hidden_file_input" type="file"/>
                            <div><TextField value={shop_name} onChange={(elm) => Update(elm, setShopName, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Shop name" variant="standard" /></div>
                            <div><TextField value={phone_number1} onChange={(elm) => Update(elm, setPhoneNumber1, phone_number)} sx={{width: 250}} className="text_field" id="standard-basic" label="Phone number 1" variant="standard" /></div>
                            <div><TextField value={phone_number2} onChange={(elm) => Update(elm, setPhoneNumber2, phone_number)} sx={{width: 250}} className="text_field" id="standard-basic" label="Phone number 2" variant="standard" /></div>
                            <div>
                                <div className="padv10">
                                    contact person: {cp}
                                </div>
                                <div id="searchBFilter">
                                    <div className="searchInFilter">
                                        <InputBase
                                            variant="standard"
                                            sx={{ ml: 1, flex: 1, fontSize:'.9em' }}
                                            placeholder="Contact person"
                                            inputProps={{ 'aria-label': 'search for client' }}
                                            onChange={searchClients}
                                        />
                                        <IconButton type="button" sx={{ padding: '10px 10px ', borderRadius: 0 }} aria-label="search" >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </div>

                                <div className="filterSearchRes">
                                    {searchRes.length > 0  ?
                                        <Paper elevation={1} className="searchRBody">
                                            {searchRes.map((elm,ind) => (
                                                <div className="sResult" data-uid={elm.UID} onClick={selectCustomer} key={ind}>
                                                    {elm.name}
                                                </div>
                                            ))}
                                        
                                        </Paper>
                                    :''}
                                    {contact_person[0] ?
                                        <div className="selectedSearchItem">
                                            
                                            <div >selected customer: </div>
                                            <div className='padv10'>{ contact_person[0] }</div>
                                        </div>
                                    :''}
                                
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="form_section">
                        <div className="form_section_main">
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={region}
                                    label="ID type"
                                    onChange={handleRegionChange}
                                    variant="standard"
                                >   
                                    {basic.regions.map((elm, ind) => (
                                        <MenuItem value={elm.name} key={ind}>{elm.name}</MenuItem>
                                    ))}
                                    
                                
                                </Select>
                            </FormControl>
                            
                          
                            <div><TextField value={street} onChange={(elm) => Update(elm, setStreet, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Street" variant="standard" /></div>
                            <div><TextField value={geo_coords} onChange={(elm) => Update(elm, setGeoCoords, text)} sx={{width: 250}} className="text_field" id="standard-basic" label="Geo coordinates" variant="standard" /></div>
                            
                            <div>
                                <TextField
                                    sx={{width: 250}}
                                    className="text_field"
                                    id="standard-multiline-static"
                                    label="shop description"
                                    multiline
                                    rows={4}
                                
                                    value={description}
                                    variant="standard"
                                    onChange={(elm) => Update(elm, setDescription, text)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="submit_form">
                
                    <div className="btn outlined" onClick={modifyShopInfo}>
                        <div>
                            Modify shop info
                        </div>
                    </div>
                    
                </div>
            </div>
        </Fade>
    )
}