import { useState, useEffect } from "react"
import { useLoaderData } from "react-router-dom";

import main from '../resources/code/utils.js'
import '../styles/container.css'
import '../styles/dashboard.css'
import '../styles/utils.css'
import {formatCash} from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip,  } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import EditBalances from '../elements/edit_pricing'


ChartJS.register(ArcElement, Tooltip );


export function loader(){
    return fetch(main.server + '/dash').then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            return res.data
        }
        
    })
      
}   

export default function Dashboard(props){
 
    const [data ] = useState(useLoaderData())
    const [setSnack, , , , _actions_, setNotAllowed] = useOutletContext()
    const [ start, setStart ] = useState(false)
    

    const [ system_user_count ] = useState(data.counts['System users'])
    const [ client_count ] = useState(data.counts['Clients'])
    const [ check_actions, setCheckActions ]  = useState(false)
    const [ stove_debts_count ] = useState(data.stove_data.unfinished_stove_sales)
    const [ pellet_debts_count ] = useState(data.pellet_sales.pellet_debts)


    const clients_v_users = {
      
        
        labels:['system users', 'clients'],
        datasets: [{
          label: 'clients v users',
          data: [system_user_count, client_count],
          backgroundColor: [
            '#99DBF5',
            '#146C94',
          ],
          hoverOffset: 4,
          
        }],
        legend:{
            display: false
        }
       
    }
    const stove_debts = {
      
        
        labels:['stove debts'],
        datasets: [{
          label: 'stove debts',
          data: [stove_debts_count],
          backgroundColor: [
            '#E70D40',
          
          ],
          hoverOffset: 4,
          
        }],
        legend:{
            display: false
        }
       
    }


    const pellet_debts = {
      
        
        labels:['pellet debts'],
        datasets: [{
          label: 'pellet debts',
          data: [pellet_debts_count],
          backgroundColor: [
            '#E70D40',
          
          ],
          hoverOffset: 4,
          
        }],
        legend:{
            display: false
        }
       
    }


    const system_users_data = {
      
        
        labels: ['System users'],
        datasets: [{
          label: 'System users',
          data: [system_user_count],
          backgroundColor: [
            '#99DBF5',
          ],
          hoverOffset: 4,
          
        }],
        legend:{
            display: false
        }
       
    };


    const clients_dougnut_data = {
        labels: ['Clients'],
        datasets: [{
          label: 'Clients',
          data: [client_count],
          backgroundColor: [
            '#146C94',
          ],
          hoverOffset: 4,
          
        }],
        legend:{
            display: false
        },
        options:{
            borderWidth:3,
        }
       
    };

    const options = {
        borderWidth: 1,
    
        cutout: 70,
    }

    const [ edit_pricing,setEditPricing ]  = useState(false)

    function EditPricing(){
        setEditPricing(true)
    }


    useEffect(() =>{
        if(!start){
            setSnack({
                message: 'page loaded',

            })
            setStart(true)
        }

        if(_actions_.length > 0 && !check_actions){

            if(_actions_.indexOf('view_dashboard') !== -1){
                setNotAllowed(false)
            }else{
                setNotAllowed(true)
            }
            setCheckActions(true)
        }
       
    })
    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>Dashboard</div>
                </div>
                
                <div id="dash_main">
                    <div  className="container">
                        <div className="container_heading">accounting</div>
                        <div className="container_main">


                            <div className="info_value_pair">
                                <div>Bank account balance: </div> <div>{formatCash(data.accounting.account_balance)}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Stove Sales balance: </div> <div>{formatCash(data.accounting.stove_sales_balance)}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Pellet Sales balance: </div> <div>{formatCash(data.accounting.pellet_sales_balance)}</div>
                            </div>
                           
                        </div>
                    </div>


                    <div  className="container">
                        <div className="container_heading">general info</div>
                        <div className="container_main">
                            { Object.keys(data.counts).map((key, ind) => (
                                <div className="info_value_pair" key={ind}>
                                    <div>{key}: </div> <div>{data.counts[key]}</div>
                                </div>
                            ))}
                        </div>
                    </div>


                    

                    <div  className="container">
                        <div className="container_heading">Pricing & commision</div>
                        <div className="container_main">
                            { Object.keys(data.prices).map((key, ind) => (
                                <div className="info_value_pair" key={ind}>
                                    <div>{key.replace('_', ' ')}: </div> <div>{formatCash(data.prices[key])}</div>
                                </div>
                            ))}


                            
                        </div>
                        <div>
                            { (_actions_.indexOf('edit pricing and commission') !== -1) ? 
                                <div className="pad10">
                                    <div className="btn" onClick={EditPricing}>
                                        <div>Edit pricing and commision</div>
                                    </div>
                                </div>
                            :''}
                    
                        </div>
                    </div>
                    
                   
                    <div  className="container">
                        <div className="container_heading">stove sales data</div>
                        <div className="container_main">
                            <div className="info_value_pair">
                                <div>Total stove sales: </div> <div>{data.stove_data.stove_sales}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Unifinished stove payments: </div> <div>{data.stove_data.unfinished_stove_sales}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Finished stove payments: </div> <div>{data.stove_data.finished_stove_payments}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Unifinished stove balance: </div> <div>{formatCash(data.stove_data.unfinished_stove_payments)}</div>
                            </div>
                        
                        </div>
                    </div>
                

                    <div  className="containerPiechart">
                        <div className="container_heading">users vs clients</div>

                        <div className="doughnut_container">
                            <div className="doughnutCount">{Number(system_user_count)} / {Number(client_count)}</div>
                            <Doughnut 
                                data={clients_v_users}
                                options={options}
                            />
                        </div>
                        <div></div>
                    </div>


                    <div  className="containerPiechart">
                        <div className="container_heading">stove debts</div>

                        <div className="doughnut_container">
                            <div className="doughnutCount">{stove_debts_count}</div>
                            <Doughnut 
                                data={stove_debts}
                                options={options}
                            />
                        </div>
                        <div></div>
                    </div>

                    <div  className="containerPiechart">
                        <div className="container_heading">pellet debts</div>

                        <div className="doughnut_container">
                            <div className="doughnutCount">{pellet_debts_count}</div>
                            <Doughnut 
                                data={pellet_debts}
                                options={options}
                            />
                        </div>
                        <div></div>
                    </div>

                   

                    <div  className="containerPiechart">
                        <div className="container_heading">users</div>
                    
                        <div className="doughnut_container">
                            <div className="doughnutCount">{system_user_count}</div>
                            <Doughnut 
                                data={system_users_data}
                                options={options}
                            />
                        </div>
                        <div></div>
                    </div>
                    <div  className="containerPiechart">
                        <div className="container_heading">clients</div>
                        
                        <div className="doughnut_container">
                            <div className="doughnutCount">{client_count}</div>
                            <Doughnut 
                                data={clients_dougnut_data}
                                options={options}
                            />
                        </div>
                    </div>
                    <div  className="container_placeholder">
                        
                    </div>
                    <div  className="container_placeholder">
                        
                    </div>
                </div>
             
       
                


                <EditBalances 
                    state={edit_pricing}
                    setDialogState={setEditPricing}
                    data={data.prices}
                />
            </div>
        </Fade>
    )
}