import './nav_panel.css'
import {

    Link,
    useLocation

} from "react-router-dom";

import DashIcon from '../resources/systemIcons/dashboard.png'
import UsersIcon from '../resources/systemIcons/user.png'
import CLientsIcon from '../resources/systemIcons/customer.png'
import StovesIcon from '../resources/systemIcons/gas-stove.png'
import HeaterIcon from '../resources/systemIcons/heater.png'
import PelletIcon from '../resources/systemIcons/cement.png'
import RecordsIcon from '../resources/systemIcons/table.png'
import SettingsIcon from '../resources/systemIcons/settings.png'
import StoreIcon from '../resources/systemIcons/location.png'
import AccountBalanceIcon from '../resources/icons/account_balance_24px_outlined.svg';
import WarehouseIcons from '../resources/systemIcons/warehouse.png'
import PelletProduction from '../resources/systemIcons/factory.png'

import { useEffect, useState } from 'react';

export default function Nav_panel(props){
    const location = useLocation()
    const [ path, setPath ] = useState(false)


    useEffect(() => {

        const path = location.pathname
        const split = path.split('/')
        const root = split[1]
        
        if(!root){
            setPath('dashboard')
        }else{
            setPath(root)
        }
    
    
    })
    function viewPage(page){
        let ans = false
        if(props.actions.indexOf(page) > -1){
            ans = true
        }
        return ans
    }

    function getStyle(name){
        if(path === name ){
            return 'nav_panel_link cur_location'
        }else{
            return 'nav_panel_link'
        }
    }
    function touch(){
        if(props.down_md && props.state){
            props.closeMenu()
        }
    }
   

   
    if(props.state){return(
        
        <div id="nav_panel" onClick={touch}>
            <div id="sys_name_navpanel">
                <div>Dorcas</div>
                <div>clean cooking energy project system</div>
            </div>

            
            { viewPage('view_dashboard') ?
            
                <Link className="clear_decor" to="/">
                    <div className={getStyle('dashboard')}>
                        <div>
                            <img src={DashIcon} alt="nav panel icon"/>
                        </div>
                        <div>dashboard</div>
                    </div>
                </Link>
            :''}
            { viewPage('view_accounting') ?
            
                <Link className="clear_decor" to="accounting">
                    <div className={ getStyle('accounting') }>
                        <div>
                            <img src={AccountBalanceIcon} alt="nav panel icon"/>
                        </div>
                        Accounting
                    </div>
                </Link>
            :''}
            { viewPage('view_users') ?
                <Link className="clear_decor" to="/users">
                    <div className={ getStyle('users') }>
                        <div>
                            <img src={UsersIcon} alt="nav panel icon"/>
                        </div>
                        users
                    </div>
                </Link>
            :''}
            { viewPage('view_clients') ?
                <Link className="clear_decor" to="/clients">
                    <div className={ getStyle('clients') }>
                        <div>
                            <img src={CLientsIcon} alt="nav panel icon"/>
                        </div>
                        clients
                    </div>
                </Link>
            :''}
            { viewPage('view_workshops') ?
                <Link className="clear_decor" to="/workshops">
                    <div className={ getStyle('workshops') }>
                        <div>
                            <img src={WarehouseIcons} alt="nav panel icon"/>
                        </div>
                        workshops
                    </div>
                </Link>
            :''}
            { viewPage('view_shops') ?
            
                <Link className="clear_decor" to="/shops">
                    <div className={ getStyle('shops') }>
                        <div>
                            <img src={StoreIcon} alt="nav panel icon"/>
                        </div>
                        Centers
                    </div>
                </Link>
            :''}
           
           { viewPage('sell_stoves') ?
                <Link className="clear_decor" to="/sell_stove">
                    <div className={ getStyle('sell_stove') }>
                        <div>
                            <img src={StovesIcon} alt="nav panel icon"/>
                        </div>
                        sell stoves
                    </div>
                </Link>
            :''}

            { viewPage('sell_heater') ?
                <Link className="clear_decor" to="/sell_heater">
                    <div className={ getStyle('sell_heater') }>
                        <div>
                            <img src={HeaterIcon} alt="nav panel icon"/>
                        </div>
                        sell heater
                    </div>
                </Link>
            :''}
           
            
            { viewPage('view_pay_for_stove_page') ?
                <Link className="clear_decor" to="/pay_for_stove/client_name=&clientID=">
                    <div className={ getStyle('pay_for_stove') }>
                        <div>
                            <img src={StovesIcon} alt="nav panel icon"/>
                        </div>
                        pay for stoves
                    </div>
                </Link>
            :""}
            
            { viewPage('record_pellet_sales') ?
            
                <Link className="clear_decor" to="record_pellet_sales/shop=&clientID=&client_name=">
                    <div className={ getStyle('record_pellet_sales') }>
                        <div>
                            <img src={PelletIcon} alt="nav panel icon"/>
                        </div>
                        Record pellet sales
                    </div>
                </Link>
            :''}
            { viewPage('record_pellet_production') ?
                
                <Link className="clear_decor" to="record_pellet_production/workshop=&workshopID=">
                    <div className={ getStyle('record_pellet_production') }>
                        <div>
                            <img src={PelletProduction} alt="nav panel icon"/>
                        </div>
                        Record pellet production
                    </div>
                </Link>
            :''}
           

            

           
            { false ? <Link className="clear_decor" to="sell_pellets">
                <div className={ getStyle('dashboard') }>
                    <div>
                        <img src={PelletIcon} alt="nav panel icon"/>
                    </div>
                    sell pellets
                </div>
            </Link>:''}
            { viewPage('view_records') ?
                <Link className="clear_decor" to="records">
                    <div className={ getStyle('records') }>
                        <div>
                            <img src={RecordsIcon} alt="nav panel icon"/>
                        </div>
                        records
                    </div>  
                </Link>
            :''}
            { viewPage('settings') ?
                <Link className="clear_decor" to="settings">
                    <div className={ getStyle('settings') }>
                        <div>
                            <img src={SettingsIcon} alt="nav panel icon"/>
                        </div>
                        settings
                    </div>
                </Link>
            : ''}
          

        </div>
        
    )} else{
        return ''
    }
}