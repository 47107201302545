import { useState, useEffect } from "react"
import { useLoaderData, Link } from "react-router-dom"
import main from '../resources/code/utils.js'
import '../styles/utils.css'
import { styled, alpha } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom"
import '../styles/stores.css'
import InputBase from '@mui/material/InputBase'
import shopPlaceHolder from '../resources/systemIcons/location.png'
import AddIcon from '../resources/icons/add-black-18dp.svg'

export function loader(){
    return fetch(main.server + '/shops').then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            return res.data
        }
    })
      
} 

export default function Shops(){
    const [data, setData ] = useState(useLoaderData())
    const [setSnack, , , , _actions_ , setNotAllowed ] = useOutletContext()
    const [searchInput, setSearchInput] = useState('')
    const [ start, setStart ]  = useState(false)
    const [ check_actions, setCheckActions ]  = useState(false)

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.5),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.75),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }));
    
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));
    
    
    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            
        },
    }));

    function searchForStore(elm){
        setSearchInput(elm.target.value)
        fetch(main.server + `/shops?q=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setData(res.data)
            }
        })
    }
    function viewShop(event){
        console.log(event.target)
        window.location.assign(`/shops/view_shop/${event.target.dataset.uid}`)
    }  

    useEffect(() => {
        if(!start){
            
            setSnack({
                message: 'page loaded',
            })
                
            setStart(true)
        }
        if(_actions_.length > 0 && !check_actions){

            if(_actions_.indexOf('view_shops') !== -1){
                setNotAllowed(false)
            }else{
                setNotAllowed(true)
            }
            setCheckActions(true)
        }
    })


   
    return(
        <Fade in={true}>
            <div className="page">
                
                <div className="system_title">
                    <div>Centers</div>
                </div>
      

                <div className="pageTopNav">
             

                    <div className="searchBarSmall">
                        <div><input type="text"  value={searchInput} onChange={searchForStore} /></div>
                        <div><SearchIcon/></div>
                    </div>
                    <div>
                        {_actions_.indexOf('create_new_workshops') !== -1 ?
                            <Link className="clear_decor" to="/shops/new_shop">
                                <div className="btn outlined responsiveBtn">
                                    <div>
                                        <img src={AddIcon} alt="icon"/>
                                    </div>
                                    <div>
                                        Add new center
                                    </div>
                                </div>
                            </Link>
                        :''}
                    </div>
                </div>
                <div id="shopsMain" >   
             
                    {data.shops.map((elm, ind) => (
                        <div className="storeElm" key={ind} data-uid={elm.UID} onClick={viewShop}>
                            <div className="storeElmImgContainer">
                                <img src={ elm.shop_image ? main.server + `/media/${elm.shop_image}` : shopPlaceHolder} alte="store image"/>
                            </div>
                            <div className="storeElmMain">
                                <div className="storeElmName bold600">
                                    <div className="padr5">{ind + 1}. </div> <div>{elm.name}</div>
                                </div>
                                <div className="info_value_pair_inline">
                                    <div>geo coords:</div>
                                    <div>{elm.geo_coords}</div>
                                </div>
                                <div className="info_value_pair_inline">
                                    <div>phone number 1:</div>
                                    <div>{elm.phone_number1}</div>
                                </div>
                            </div>

                        </div>
                    ))}
                
                </div>
            </div>
        </Fade>
    )
}