import { useState, useEffect } from "react"
import { useLoaderData, Link } from "react-router-dom";
import main, {_DateTime} from '../resources/code/utils.js'
import '../styles/utils.css'
import '../styles/users.css'
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ProfileImage from '../resources/icons/profile.png'
import AddIcon from '../resources/icons/add-black-18dp.svg'

export function loader(){
    return fetch(main.server + '/users').then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
          
            return res.data
        }
        
    })
      
}  


export default function Users(){
    const [data, setData ] = useState(useLoaderData())
    const [ start, setStart ] = useState(false)
    const [setSnack, , , , _actions_, setNotAllowed] = useOutletContext()
    const [user_stats, setUserStats] = useState({account_types:[], total_users: 0, counts:{}, latest_users:[]})
    const [searchInput, setSearchInput] = useState('')

    const [tab, setTab] = useState(1)

    const [ check_actions, setCheckActions ]  = useState(false)


    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.5),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.75),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }));
    
    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));
      
    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
           
        },
    }));

    function viewUser(elm){
        window.location.assign(`/users/view_user/${elm.target.dataset.uid}`)
    }
    function handleTabChange(event, new_value){
        
        if(new_value === 2){
            fetch(main.server + '/user_stats').then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                  
                    setUserStats(res.data)
                }
                
            })
        }
        setTab(new_value)
    }
    
    function searchForUser(elm){
        setSearchInput(elm.target.value)
        fetch(main.server + `/users?user=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setData(res.data)
            }
        })
    }

    useEffect(() => {
        if(!start){
            
            setSnack({
                message: 'page loaded',
            })
                
            setStart(true)
        }
        if(_actions_.length > 0 && !check_actions){

            if(_actions_.indexOf('view_users') !== -1){
                setNotAllowed(false)
            }else{
                setNotAllowed(true)
            }
            setCheckActions(true)
        }
    })

    return(
        <Fade in={true}>
            <div className="page">

                <div className="system_title">
                    <div>System users</div>
                    
                </div>
                <div className="padv10">
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab value={1} label="Users"  />
                        <Tab value={2} label="Statistics"  />
                    
                    </Tabs>
                </div>
                <div class="centerPage">
                    {(tab === 1)?
                    <div className="user_main">
                    
                        <div id="user_main_nav" >
                           

                            <div className="searchBarSmall">
                                <div><input type="text"  value={searchInput} onChange={searchForUser} /></div>
                                <div><SearchIcon/></div>
                            </div>
                            <div>
                                { _actions_.indexOf('create_new_users') !== -1 ? 
                                    <Link className="clear_decor" to="/users/new_user">
                                        <div className="btn outlined responsiveBtn">
                                            <div>
                                                <img src={AddIcon} alt="icon"/>
                                            </div>
                                            <div>
                                                Add new user
                                            </div>
                                        </div>
                                    </Link>
                                :'' }
                            </div>
                        </div>
                        <div className="list_page_main">
                            {data.user_list.map((elm, ind) => (
                                <div key={ind} className="person_list_item" data-uid={elm.UID} onClick={viewUser}>

                                    <div className="userlist_item_avatar">
                                        {elm.profile_image ? 
                                            <img src={ main.server + '/media/'+ elm.profile_image} alt="user image"/>
                                        : <img src={ProfileImage} alt="user image"/> }
                                    </div>
                                    <div className="bold600 padv20">{elm.user}</div>
                                    <div className="">{elm.account_type}</div>
                                  
                                </div>
                            ))}
                        </div>

                    </div>

                    :
                    <div id="user_stats">
                        <div>
                            <div  className="container">
                                <div className="container_heading">statistics</div>
                                <div className="container_main">
                                    
                                    <div className="info_value_pair">
                                        <div>Total users: </div> <div>{user_stats.total_users}</div>
                                    </div>
                                    {Object.keys(user_stats.counts).map((key, ind) => (
                                        <div key={ind} className="info_value_pair">
                                            <div>{key}: </div> <div>{user_stats.counts[key]}</div>
                                        </div>
                                    ))}
                                
                                </div>
                            </div>
                            <div  className="container">
                                <div className="container_heading">Account types</div>
                                <div className="container_main">
                                    
                                    {user_stats.account_types.map((type, ind) => (
                                        <div key={ind} className="listItem">
                                            <div>{type.name}</div>
                                        </div>
                                    ))}
                                
                                </div>
                            </div>
                            
                        </div>
                        
                        <div>
                            <div  className="container">
                                <div className="container_heading">latest user accounts</div>
                                <div className="container_main">
                                    
                                    {user_stats.latest_users.map((record, ind) => (
                                        <div className="user_list_item" key={ind}>
                                            <div className="user_list_item_image">
                                                {record.profile_image ? <img src={'http://127.0.0.1:8000/media/' + record.profile_image} alt="profile image"/>
                                                : <img src={ProfileImage} alt="user image"/> }
                                            </div>
                                            <div >
                                                <div className="info_value_pair_inline">
                                                    <div>{record.user}</div>
                                                </div>
                                                <div className="info_value_pair_inline">
                                                    <div>date & time created:</div><div>{_DateTime(record.date_created)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                
                                </div>
                            </div>
                        </div>
                    
                    </div> }

                </div>
            </div>
        </Fade>
    )
}