
import { useEffect, useState, useRef } from 'react'
import TextField from '@mui/material/TextField';
import { useLoaderData } from "react-router-dom";

import '../styles/utils.css'

import main, { format_cash, formatCash, Refresh, Update, number } from '../resources/code/utils'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import Fade from '@mui/material/Fade'
import ProfileImage from '../resources/icons/profile.png'

import { useOutletContext } from "react-router-dom";

import EnterSerialNumbers  from '../elements/enter_serial_numbers';
import Paper from '@mui/material/Paper'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export function loader(){
    return fetch(main.server + '/sales_information').then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  
export default function SellStove(){
    const [data, setData ] = useState(useLoaderData())
   
    const [ serial_numbers, setSerialNumbers ] = useState([])
    const [start, setStart] = useState(false)
    const [customer, setCustomer] = useState('')
    const [customer_list, setCustomerList] = useState([])
    const [number_of_stoves, setNumberOfStoves] = useState(1)
    const [payment_plan, setPaymentPlan] = useState('')
    const [ customer_uid, setCustomerUID ] = useState('')
    const [enter_serial_numbers, set_enter_serial_numbers] = useState(false)
    const [setSnack, setAlert, username, user_uid, _actions_ , setNotAllowed ] = useOutletContext()
    const [ total, setTotal ] = useState(data.stove_price)
    const [debts, setDebts] = useState([])
    const [ total_debt, setTotalDebt ] = useState(0)
    const [ sale_date, setSaleDate ] = useState(new Date()) 


    const clientSearchRef = useRef(null)
    const [ check_actions, setCheckActions ]  = useState(false)

    
 

    const handlePaymentPlan = (event: SelectChangeEvent) => {
        setPaymentPlan( event.target.value )
        setTotal(number_of_stoves * data.stove_price)
    }

    
    
  

    function serchForClients(elm){
        setCustomer(elm.target.value)
  
        fetch(main.server + `/clients?client=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setCustomerList(res.data.client_list)
            }
        })
    }
    function handleQuantity(event){
        const value = event.target.value
        if(value.search(',') !== -1){
            let clean = value.replaceAll(',','')
            if(Number(clean) || clean === '0' || clean === ''){
                
                setNumberOfStoves(Number(clean))
                setTotal(Number(data.stove_price) * Number(clean))
              
            }
        }
        else{
            if(Number(value) || value === '0' || value === ''){
                setNumberOfStoves(Number(value))
                setTotal(Number(data.stove_price) * Number(value))
            } 
        } 
    }

    const required_stovSale_inputs = [
    
        { input: number_of_stoves },
        { input: payment_plan },
        { input: customer_uid },
    
      
    ]
    
    function validate_stovSale_Form(){
        let incomplete = false
        for(const input of required_stovSale_inputs){
            if(!input.input){
                incomplete = true
            }
        }
        if(incomplete){
            setSnack({
                message:'incomplete form',
                type:'error'
            })
        }
        return incomplete
    }
    function ProceedToSerialNumbers(){
        if(!validate_stovSale_Form()){
            set_enter_serial_numbers(true)
        }
    }
    function sellStoves(){
        if(!validate_stovSale_Form() && serial_numbers.length > 0){
            const form = new FormData()

            const f = {
                quantity: number_of_stoves,
                payment_plan: payment_plan,
                client: customer_uid,
                sold_by: username,
                serial_numbers:serial_numbers,
                amount: total,
                date_sold: sale_date.toDateString(),

            }
            

            form.append('form', JSON.stringify(f))


            fetch(main.server + '/process_stove_sale',
                {
                    method: "POST",
                    body:form,
                }
            ).then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                    setAlert({ message:'sale proccessed', type:'success'})
                    Refresh()
                }else{
                    setAlert({ message:res.message, type:'error'})
                }
            })
        }
    
    }
    function selectClient(name, uid){
        setCustomer(name)
        setCustomerUID(uid)
   
        setCustomerList([])
        fetch(main.server + `/get_client_debts?client=${uid}`).then(resp => resp.json()).then(result =>{
            setDebts(result.data.debts)
            let total_debt = 0
            for(const debt of result.data.debts){
                total_debt += Number(debt.stove_price) - Number(debt.amount_paid)
            }
            setTotalDebt(total_debt)
        })
    }
    function handleClicks(event){
       
        if(!event.nativeEvent.srcElement.classList.contains('searchSys')){
            setCustomerList([])
        }
        
    }

    useEffect(() => {
        if(!start){
            setSnack({
                message: 'page loaded',
            })
            setStart(true)
        }
       
        if(_actions_.length > 0 && !check_actions){

            if(_actions_.indexOf('sell_stoves') !== -1){
                setNotAllowed(false)
            }else{
                setNotAllowed(true)
            }
            setCheckActions(true)
        }
        
    })
    return(
        <Fade in={true}>
            <div className="page" onClick={handleClicks}>
                <div className="system_title">
                    <div>Sell Stoves</div>
                </div>

                <div className="form_page_main ">
                
                    

                    <div className="flex_column_center ">
                        { debts.length > 0 ? 
                            <div id="debtWarning">
                                <div>
                                    <div className="displayText1 padv10">Warning !</div>
                                    <div>this client has an unfinished debt debts</div>
                                </div>
                                { debts.map((elm, index) => (
                                    <div key={index} className="stove_debt">
                                        <div className="info_value_pair">
                                            <div>stove serial number: </div>
                                            <div>{elm.stoveID}</div>
                                        </div>
                                    
                                        <div className="info_value_pair">
                                            <div>price: </div>
                                            <div>{formatCash(elm.price)}</div>
                                        </div>
                                        <div className="info_value_pair">
                                            <div>amount paid: </div>
                                            <div>{formatCash(elm.amount_paid)}</div>
                                        </div>
                                    </div>
                                ))}
                            
                            </div>
                        :''}

                        <div className="form_main form_centered">
                            
                            <div className="form_section">
                            
                                <div className="form_section_main">

                                    <div>
                                        <div className="authenticatorTitle">Sold by:</div>
                                        <div>
                                            <div className="authenticator_final" >
                                                
                                                <div className="flex_row flex_align_center">
                                                    <div className="authenticator_avatar">
                                                        <img src={ProfileImage} alt="user image"/>
                                                    </div>
                                                    <div className="padh10">{username}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date of sale"
                                                value={sale_date}
                                                variant="standard"
                                                onChange={(newstartDate) => {
                                                    setSaleDate(newstartDate)
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <div><TextField value={formatCash(data.stove_price)} sx={{width: 250}} className="text_field" id="standard-basic" label="Stove price" variant="standard" /></div>

                                    
                                </div>
                            </div>
                            
                            <div className="form_section">
                                <div className="form_section_main">
                         
                                    <div className="">
                                        <TextField value={customer} onChange={serchForClients} sx={{width: 250}} className="text_field" id="standard-basic" label="Customer" variant="standard" />
                                    


                                        <div className="filterSearchRes">
                                            {customer_list.length > 0 ?
                                                <Paper elevation={1} ref={clientSearchRef}  className="searchRBody searchSys" id="search_res_clients">
                                                    {customer_list.map((elm,ind) => (
                                                        <div className="generalSearchResult searchSys" data-uid={elm.UID} onClick={() => selectClient(elm.name, elm.UID)} key={ind}>
                                                            {elm.name}
                                                        </div>
                                                    ))}
                                                
                                                </Paper>
                                            :''}
        
                                        </div>
                                        
                                    </div>

                                    <div>
                                        <FormControl >
                                            <InputLabel id="demo-simple-select-label">Payment plan</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={payment_plan}
                                                label="Payment plan"
                                                onChange={handlePaymentPlan}
                                                variant="standard"
                                                sx={{width: 250}}
                                            >   
                                                {data.payment_plans.map((elm, ind) => (
                                                    <MenuItem value={elm.name} key={ind} >{elm.name}</MenuItem>
                                                ))}
                                                
                                            
                                            </Select>
                                        </FormControl>
                                    </div>
                                    
                                </div>

                            </div>

                            <div className="form_section">
                                <div className="form_section_main">
                                
                                    <div><TextField value={format_cash(number_of_stoves)} onChange={handleQuantity} className="text_field" id="standard-basic" label="Number of stoves" variant="standard" /></div>
                                    <div><TextField disabled={payment_plan === 'Full Payment'} value={format_cash(total)} onChange={(elm) => Update(elm, setTotal, number)} sx={{width: 250}} className="text_field" id="standard-basic" label="payment received" variant="standard" /></div>
                                </div>

                            </div>  
                    
                        </div>
                    
                    </div>
                </div>
                <div className="uni_procced_btn">
                    <div className="btn_filled" onClick={ProceedToSerialNumbers}>
                        <div>
                            process sale
                        </div>
                    </div>
                </div>
               
                <EnterSerialNumbers 
                    number={number_of_stoves}
                    state={enter_serial_numbers}
                    set_state={set_enter_serial_numbers} 
                    serial_numbers={serial_numbers}
                    setSerialNumbers={setSerialNumbers}
                    setSnack={setSnack}
                    sellStoves={sellStoves}
                />
               
            </div>
        </Fade>
    )
}